

export const AUTH_ERROR_CODE = 401;
export const SUCCESS = 200;
export const BAD_REQUEST = 400;
export const FATAL_SERVER_ERROR = 500;
export const PARSING_ERROR = 'PARSING_ERROR';


export enum API_TYPES {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}
