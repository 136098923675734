import { useState } from "react"
import PageAddDriver from "../add-driver/add-driver"
import PageAddCompany from "../add-company/add-company"
import { userTypes } from "../../../utils/constants"

const PageAddUser = () => {
  const chips = [
    { name: "Company", value:userTypes.COMPANY }
  ]
  const [radioValue, setRadioValue] = useState(chips[0].value)

  return <PageAddCompany userType={radioValue} setUserType={setRadioValue} cardHeader={""} />
}

export default PageAddUser
