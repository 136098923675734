import { Col, Container, Row } from "react-bootstrap"

const Footer = ({
  onNext,
  onPrev,
  pageCount,
  currentPage,
  setPage,
  className
}: {
  onNext: Function,
  onPrev: Function,
  pageCount: number,
  currentPage: number,
  setPage: Function,
  className?: string
}) => {
  return (
    <Container className={`my-3 table-footer ${className}`}>
      <Row className="justify-content-end">
        <Col md={"auto"}>
          <span onClick={() => setPage(1)}>{"<<"}</span>
          <span onClick={() => onPrev()}>{"<"}</span>
          {currentPage - 2 > 0 && (
            <span onClick={() => setPage(currentPage - 2)}>
              {currentPage - 2}
            </span>
          )}
          {currentPage - 1 > 0 && (
            <span onClick={() => setPage(currentPage - 1)}>
              {currentPage - 1}
            </span>
          )}
          <span onClick={() => setPage(currentPage)} className="active">
            {currentPage}
          </span>
          {currentPage + 1 <= pageCount && (
            <span onClick={() => setPage(currentPage + 1)}>
              {currentPage + 1}
            </span>
          )}
          {currentPage + 2 <= pageCount && (
            <span onClick={() => setPage(currentPage + 2)}>
              {currentPage + 2}
            </span>
          )}
          <span onClick={() => onNext()}>{">"}</span>
          <span onClick={() => setPage(pageCount)}>{">>"}</span>
        </Col>
      </Row>
    </Container>
  )
}

export default Footer
