import { ReactNode } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import './cardWithHeader.scss'

const CardWithHeader = ({
  title,
  rightText = '',
  children,
  backgroundColorName = 'blue',
  wrapperClassName = ''
}: {
  title: ReactNode
  rightText?: ReactNode
  children: ReactNode
  backgroundColorName?: string
  wrapperClassName?: string
}) => {
  return (
    <Container className={`form-section-container ${wrapperClassName}`}>
      <Row>
        <Col
          className={`form-section-header d-flex justify-content-between bg-${backgroundColorName}`}
        >
          <span>{title}</span>
          {!!rightText && <span>{rightText}</span>}
        </Col>
      </Row>
      <Row>
        <Col className="form-section-content pb-2">{children}</Col>
      </Row>
    </Container>
  )
}

export default CardWithHeader
