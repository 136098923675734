import { Col, Container, Image, Modal, Row } from 'react-bootstrap'

import { IJobDocumentPreview } from './types'
import './documentPreview.scss'
import UserInfoItem from '../../userInfoItem/user-info-item'
import PrimaryButton from '../../primaryButton/PrimaryButton'
import { useEffect, useState } from 'react'
import { DateFormat, changeDateFormat } from '../../../utils/DateUtils'
import { saveAs } from 'file-saver'

const JobDocumentPreviewModal = (props: IJobDocumentPreview) => {
  const { show, handleClose, document, image } = props

  const [images, setImage] = useState('')

  useEffect(() => {
    setImage(image)
  }, [image])

  const handleImageNavigation = (image: string) => {
    setImage(image)
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="document-preview modal-lg"
    >
      <Modal.Header closeButton className="document-preview-header">
        <Col className='fs-24'>Job Delivery Invoice</Col>
      </Modal.Header>
      <Modal.Body className="document-body">
        <Container fluid>
          <Row>
            <div className="d-flex align-items-center p-0 justify-content-center">
              <div className="image">
                <Image src={images} thumbnail fluid />
              </div>
            </div>
          </Row>
          <Row>
            <Col className="p-0">
              <UserInfoItem
                title="Uploaded File"
                value={images.substring(
                  images.lastIndexOf('/') + 1,
                  images.indexOf('?')
                )}
              />
            </Col>
            <Col>
              <UserInfoItem
                title="Uploaded Date"
                value={changeDateFormat(
                  document?.updated_at ?? '',
                  DateFormat.YYYYMMMDDHHMMSS,
                  DateFormat.DDMMMYYYY1
                )}
              />
            </Col>
            <Col className="d-flex Xalign-items-center p-0 justify-content-end">
              <PrimaryButton
                title="Download"
                className="mt-5"
                onClick={() => {
                  saveAs(images, 'image.jpg')
                }}
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  )
}

export default JobDocumentPreviewModal
