import { Form } from "react-bootstrap";
import { FormikProvider, useFormik } from "formik";
import CardWithHeader from "../../../../../components/cardWithHeader/CardWithHeader";
import FormControl from "../../../../../components/formControl/FormControl";
import './employment-history.scss'
import { IEmploymentHistory } from "../../../../../api/types/Driver";
import { IDrivingEmploymentHistory } from "./types";

const EmploymentHistory = (props: IDrivingEmploymentHistory) => {

    const { employmentHistory, title } = props;

    const initialValues: IEmploymentHistory = {
        name: employmentHistory?.name,
        address: employmentHistory?.address,
        phone: employmentHistory?.phone,
        position_held: employmentHistory?.position_held,
        date_from: employmentHistory?.date_from,
        date_to: employmentHistory?.date_to,
        explain_gaps: employmentHistory?.explain_gaps,
        salary: employmentHistory?.salary,
        reason_for_leaving: employmentHistory?.reason_for_leaving,
        federal_motor_carrier_safety_regulation: employmentHistory?.federal_motor_carrier_safety_regulation,
        safety_sensitive_function: employmentHistory?.safety_sensitive_function,
    }

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (values: IEmploymentHistory) => { }
    })

    return (
        <div className="driver-employment-history">
            <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit}>
                    <CardWithHeader title={title || ''}>
                        <div>
                            <FormControl
                                placeholder="Name"
                                controlId="validateName"
                                onChange={e => formik.setFieldValue('name', e.target.value)}
                                errorMessage={formik.errors.name}
                                value={formik.values.name}
                                isInvalid={!!formik.errors.name}
                                showLabel={true}
                                readOnly
                            />
                            <FormControl
                                placeholder="Address"
                                controlId="validateAddress"
                                onChange={e => formik.setFieldValue('address', e.target.value)}
                                errorMessage={formik.errors.address}
                                value={formik.values.address}
                                isInvalid={!!formik.errors.address}
                                showLabel={true}
                                readOnly
                            />
                            <FormControl
                                placeholder="Phone"
                                controlId="validatePhone"
                                onChange={e => formik.setFieldValue('phone', e.target.value)}
                                errorMessage={formik.errors.phone}
                                value={formik.values.phone}
                                isInvalid={!!formik.errors.phone}
                                showLabel={true}
                                readOnly
                            />
                            <FormControl
                                placeholder="Position Held"
                                controlId="validateposition"
                                onChange={e => formik.setFieldValue('position_held', e.target.value)}
                                errorMessage={formik.errors.position_held}
                                value={formik.values.position_held}
                                isInvalid={!!formik.errors.position_held}
                                showLabel={true}
                                readOnly
                            />
                            <FormControl
                                type="date"
                                placeholder="Date From"
                                controlId="validateDateFrom"
                                onChange={e => formik.setFieldValue('date_from', e.target.value)}
                                errorMessage={formik.errors.date_from}
                                value={formik.values.date_from}
                                isInvalid={!!formik.errors.date_from}
                                showLabel={true}
                                readOnly
                            />
                            <FormControl
                                type="date"
                                placeholder="Date to"
                                controlId="validateDateTo"
                                onChange={e => formik.setFieldValue('date_to', e.target.value)}
                                errorMessage={formik.errors.date_to}
                                value={formik.values.date_to}
                                isInvalid={!!formik.errors.date_to}
                                showLabel={true}
                                readOnly
                            />
                            <FormControl
                                placeholder="Salary"
                                controlId="validateSalary"
                                onChange={e => formik.setFieldValue('salary', e.target.value)}
                                errorMessage={formik.errors.salary}
                                value={formik.values.salary}
                                isInvalid={!!formik.errors.salary}
                                showLabel={true}
                                readOnly
                            />
                            <FormControl
                                placeholder="Reason for Leaving"
                                controlId="validateReasonForLeaving"
                                onChange={e => formik.setFieldValue('reason_for_leaving', e.target.value)}
                                errorMessage={formik.errors.reason_for_leaving}
                                value={formik.values.reason_for_leaving}
                                isInvalid={!!formik.errors.reason_for_leaving}
                                showLabel={true}
                                readOnly
                            />
                            <FormControl
                                placeholder="Explain gaps in employment if any"
                                controlId="validateGapsInEmployment"
                                onChange={e => formik.setFieldValue('explain_gaps', e.target.value)}
                                errorMessage={formik.errors.explain_gaps}
                                value={formik.values.explain_gaps}
                                isInvalid={!!formik.errors.explain_gaps}
                                showLabel={true}
                                readOnly
                            />
                            <div className="question">While employed here, were you subject to the Federal Motor Carrier Safety Regulations?</div>
                            <div className="answer">{employmentHistory?.federal_motor_carrier_safety_regulation ? 'Yes' : 'No'}</div>
                            <div className="question">Was the job designated as a safety-sensitive function in any Department of Transportation-regulated mode subject to alcohol and controlled substances testing as required by 49 CFR, part 40?</div>
                            <div className="answer">{employmentHistory?.safety_sensitive_function ? 'Yes' : 'No'}</div>
                        </div>
                    </CardWithHeader>
                </Form>
            </FormikProvider>
        </div>
    )
}

export default EmploymentHistory