import { useEffect, useRef, useState } from "react"
import { ReactComponent as FilterIcon } from "../../assets/images/svg/filter.svg"
import { Filter_Obj, IFilter } from "./types"
import "./formFilter.scss"
import { Form } from "react-bootstrap"
import PrimaryButton from "../primaryButton/PrimaryButton"

const Filter = ({ options, filter = [], setFilter }: IFilter) => {
  const [showFilterMenu, setShowFilterMenu] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState(filter)
  
  const filterRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let handler = (e: any) => {
      if (null !== filterRef.current) {
        if (!filterRef.current?.contains(e.target)) {
          setShowFilterMenu(false)
        }
      }
    }
    document.addEventListener("mousedown", handler)

    return () => {
      document.removeEventListener("mousedown", handler)
    }
  })

  const updateFilters = (option: Filter_Obj) => {
    let selectedIndex = selectedFilters.findIndex(v => v.id === option.id)
    if (selectedIndex >= 0) {
      let oldFilter = [...selectedFilters]
      oldFilter.splice(selectedIndex, 1)
      setSelectedFilters(oldFilter)
    } else {
      setSelectedFilters([...selectedFilters, option])
    }
  }

  const isSelected = (option: Filter_Obj) => {
    let selected = selectedFilters.find(v => v.id === option.id)
    if (selected) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className="position-relative d-inline">
      <FilterIcon
        onClick={() => setShowFilterMenu(prevValue => !prevValue)}
        role="button"
      />
      {showFilterMenu && (
        <div className="position-absolute filter-container p-3" ref={filterRef}>
          {options.map((option, i) => (
            <Form.Check
              type={"checkbox"}
              id={option.id}
              label={option.value}
              defaultChecked={isSelected(option)}
              isValid={true}
              className="form-check"
              onChange={() => updateFilters(option)}
              key={i}
            />
          ))}

          <PrimaryButton
            title="Apply"
            onClick={() => setFilter(selectedFilters)}
          />
        </div>
      )}
    </div>
  )
}

export default Filter
