import React, { useEffect, useState } from 'react'
import { useLazyDumpLoadsQuery } from '../../api/network/analyticsApiService'
import { UserResponse } from '../../api/types/Driver'
import { APPCONFIG } from '../../app-config'
import CardWithHeader from '../../components/cardWithHeader/CardWithHeader'
import PieChartWithLegend from '../../components/pieChartWithLegend/pieChartWithLegend'
import AnSecCompDriverSelector from './components/driverSelector'

export interface IAnSecDumpLoadsProps {
  allDriversList: UserResponse[]
  title?: string
}

const AnSecDumpLoads = (props: IAnSecDumpLoadsProps) => {
  const { allDriversList, title = 'Dump Loads' } = props
  const [selectedDriverId, setSelectedDriverId] = useState<number>()

  const [
    dumpLoadsAPITrigger,
    {
      data: dataDumpLoads,
      isLoading: isLoadingDumpLoads,
      isError: isErrorDumpLoads
    }
  ] = useLazyDumpLoadsQuery()

  useEffect(() => {
    console.log('Selected driver ID', selectedDriverId)
    dumpLoadsAPITrigger({ driverId: selectedDriverId })
  }, [selectedDriverId])

  return (
    <CardWithHeader title={title} wrapperClassName={`ansec-dump-loads`}>
      <AnSecCompDriverSelector
        allDriversList={allDriversList}
        onChange={event => {
          console.log(event.target.value)
          setSelectedDriverId(parseInt(event.target.value))
        }}
        selectedDriver={selectedDriverId}
      />
      {!!dataDumpLoads && !isLoadingDumpLoads && (
        <>
          {dataDumpLoads?.data?.delivered === 0 &&
          dataDumpLoads?.data?.dumped_after_pick === 0 &&
          dataDumpLoads?.data?.dumped_before_pick === 0 ? (
            <p className='mt-5 text-center'>No data to display</p>
          ) : (
            <PieChartWithLegend
              data={[
                {
                  name: 'Delivered',
                  value: dataDumpLoads?.data?.delivered ?? 0
                },
                {
                  name: 'Dumped (Before Pick-Up)',
                  value: dataDumpLoads?.data?.dumped_before_pick ?? 0,
                  fill: APPCONFIG.COLORS.brightRed
                },
                {
                  name: 'Dumped (After Pick-Up)',
                  value: dataDumpLoads?.data?.dumped_after_pick ?? 0,
                  fill: APPCONFIG.COLORS.error
                }
              ].map((item, index) => {
                return {
                  ...item,
                  fill: item?.fill ?? APPCONFIG.COLORS.pieChart[index]
                }
              })}
              interactiveLabel={false}
              maxHeight={250}
              showLegend={true}
              totalCountLabel={`Active Job`}
              totalCountValue={dataDumpLoads?.data?.active_job ?? 0}
            />
          )}
        </>
      )}
    </CardWithHeader>
  )
}
export default AnSecDumpLoads
