import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import DetailHeader from './header/header'
import './driver-detail.scss'
import {
  useDriverDetailQuery,
  useDriverProfileDetailsQuery,
  useDriverProfileUpdateStatusMutation,
  useFetchDriverTypeQuery,
  useFetchVehicleTypeQuery,
  useSuspendUserMutation,
  useUpdateUserMutation
} from '../../../api/network/userApiService'
import Loader from '../../../components/loader/Loader'
import { userStatus, userTypes } from '../../../utils/constants'
import { useParams } from 'react-router-dom'
import {
  AddUserPayload,
  IAccidentRecords,
  IDNameResponse,
  SuspendUserPayload,
  UserStatusUpdatePayload
} from '../../../api/types/Driver'
import EditIcon from '../../../assets/images/svg/EditIcon'
import PageOtherDetails from './other-details/otherDetails'
import SuspensionReasonModal from '../../../components/modal/suspendReasonModal/SuspandReasonModal'
import { FormikProvider, useFormik } from 'formik'
import FormControl from '../../../components/formControl/FormControl'
import * as Yup from 'yup'
import FormSelect from '../../../components/formSelect/FormSelect'
import { toast } from 'react-toastify'
import { addDriverSchema } from '../../../utils/Validations'
import CheckIcon from '../../../assets/images/svg/CheckIcon'
import { fetchUserType } from '../../../utils/storage'
import ApplicantDetails from './applicant-details/applicant-details'
import ResidentialInformation from './residential-information/residential-information'
import LicenseInformation from './license-information/license-information'
import DrivingExperience from './driving-experience/driving-experience'
import AccidentRecords from './accident-records/accident-records'
import TrafficConvictions from './traffic-convictions/traffic-convictions'
import EmploymentHistories from './employment-histories/employment-histories'
import EducationList from './education-list/education-list'
import PersonalInfo from './personal-info/personal-info'
import DocumentRejectReasonModal from '../../../components/modal/documentRejectReasonModal/DocumentRejectReasonModal'
import SectionHeading from './section-heading/section-heading'

const PageDriverDetail = () => {
  const { userId }: { userId?: string } = useParams()
  const { data: driverDetail, isLoading } = useDriverDetailQuery(userId || '1')
  const { data: driverProfileDetail, isLoading: isDriverProfileDetailLoading, refetch } = 
  useDriverProfileDetailsQuery(userId || '1',{
    skip: !driverDetail || !!driverDetail?.specific_driver_details?.company_details 
  })
  const { data: driverTypes } = useFetchDriverTypeQuery()
  const { data: vehicleTypes } = useFetchVehicleTypeQuery()
  const [suspendAPITriger, { isLoading: suspendUserLoading }] =
    useSuspendUserMutation()
  const [statusUpdateAPITriger, { isLoading: rejectAcceptUserLoading }] =
    useDriverProfileUpdateStatusMutation()
  console.log('driverDetail', driverDetail)
  const handleClose = () => setShow(false)
  const [show, setShow] = useState(false)

  const [isSuspended, setSuspended] = useState(false)
  const [isReadOnly, setReadOnly] = useState(true)

  const [showRejectReasonModal, setShowRejectReasonModal] = useState(false);

  useEffect(() => {
    setSuspended(
      driverDetail?.specific_driver_details?.user_profile?.status ===
      userStatus.SUSPENDED
    )
  }, [driverDetail?.specific_driver_details?.user_profile?.status])

  const handleOnChange = () => {
    if (isSuspended) {
      suspendUser('')
    } else {
      setShow(true)
    }
  }

  const initialValues: AddUserPayload = {
    name: driverDetail?.specific_driver_details.name ?? '',
    email: driverDetail?.specific_driver_details.email ?? '',
    confirmEmail: driverDetail?.specific_driver_details.email ?? '',
    phone_number:
      driverDetail?.specific_driver_details.user_profile?.phone_number ?? '',
    // truck_driver_type:
    //   driverDetail?.specific_driver_details?.driver_details?.truck_driver_type,
    license_number:
      driverDetail?.specific_driver_details?.driver_details?.license_number,
    valid_till:
      driverDetail?.specific_driver_details?.driver_details?.valid_till,
    // vehicle_type:
    //   driverDetail?.specific_driver_details?.driver_details?.vehicle_type,
    // license_plate:
    //   driverDetail?.specific_driver_details?.driver_details?.license_plate,
    street_address:
      driverDetail?.specific_driver_details?.driver_details?.street_address,
    city: driverDetail?.specific_driver_details?.driver_details?.city,
    zip_code: driverDetail?.specific_driver_details?.driver_details?.zip_code,
    country: driverDetail?.specific_driver_details?.driver_details?.country,
    state: driverDetail?.specific_driver_details?.driver_details?.state,
    suspension_reason:
      driverDetail?.specific_driver_details?.user_profile?.suspension_reason,
    user_type: Number(userTypes.DRIVER),
    id: userId
  }

  // console.log(driverDetail?.specific_driver_details?.driver_details?.vehicle_type)
  const [updateUserApiTrigger, { isLoading: updateApiLoading }] =
    useUpdateUserMutation()

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: addDriverSchema,
    onSubmit: (values: AddUserPayload) => {
      if (isReadOnly) {
        setReadOnly(!isReadOnly)
      } else {
        updateUserApiTrigger(values)
          .unwrap()
          .then(response => {
            toast.success(response.message)
            // formik.resetForm()
            setReadOnly(!isReadOnly)
          })
          .catch(e => { })
      }
    }
  })

  const suspendUser = (reason: string) => {
    console.log('suspendUser', reason.length)

    if (reason.length === 0 && !isSuspended) {
      toast.error('Please select the reason for suspension!')
      return
    } else {
      setShow(false)
      setSuspended(!isSuspended)

      const suspendPayload: SuspendUserPayload = {
        id: Number(userId),
        suspension_reason: reason
      }
      suspendAPITriger(suspendPayload)
        .unwrap()
        .then(_response => {
          reason = ''
        })
        .catch(_e => {
          reason = ''
        })
    }
  }

  const handleUpdateUserStatus = (reason: string | undefined, status: number) => {
    const payload: UserStatusUpdatePayload = {
      id: userId || "1",
      profile_approval_status: status,
      reason: reason
    }
    statusUpdateAPITriger(payload)
      .unwrap()
      .then(_response => {
        refetch();
        const message: string = status === 2 ? 'Driver account rejected.' : 'Driver account approved.';
        toast.success(message)
      })
      .catch(_e => { })
    setShowRejectReasonModal(false);
  }

  const driverType = driverTypes?.
    find(driver =>
      driver?.id === driverProfileDetail?.applicant_details?.user_profile?.user_type
    )?.name || '';

  return (
    <Container fluid>
      {(isLoading || suspendUserLoading || updateApiLoading || isDriverProfileDetailLoading ||
        rejectAcceptUserLoading) && <Loader />}
      <Row>
        <Col className="p-0">
          <DetailHeader title="Driver Details" />
        </Col>
      </Row>

      {!driverDetail?.specific_driver_details?.company_details && <Row className='align-items-start'>
        <Col className='mb-3' sm={8}>
          <PersonalInfo
            name={
              (driverProfileDetail?.applicant_details?.user_profile?.first_name || "") +
              " " +
              (driverProfileDetail?.applicant_details?.user_profile?.last_name || "")
            }
            companyName={driverDetail?.specific_driver_details?.company_details?.company_name || ''}
            email={driverProfileDetail?.applicant_details?.user_profile?.email}
            phone={driverProfileDetail?.applicant_details?.user_profile?.phone_number}
            profilePhoto={driverProfileDetail?.applicant_details?.user_profile?.profile_photo || ''}
          />
        </Col>
        {driverDetail?.specific_driver_details?.user_profile?.status !==
          1 && (
            <Col className="driver-suspend justify-content-end">
              <div className='me-2'>Suspend</div>
              <Form.Check
                type="switch"
                id="custom-switch"
                checked={isSuspended}
                onChange={handleOnChange}
              />
            </Col>
          )}
        {driverDetail?.specific_driver_details?.user_profile?.status === 1 && <Col>
          <div className='d-flex justify-content-end'>
            <Button
              className="me-2"
              variant="danger"
              onClick={() => {
                setShowRejectReasonModal(true);
              }}
              disabled={
                driverProfileDetail?.applicant_details?.profile_approval_status !== 1
              }
            >
              Reject
            </Button>
            <Button
              variant="success"
              disabled={
                driverDetail?.documents?.length === 0 ||
                !!driverDetail?.documents?.find(document => document?.status !== 1) ||
                driverProfileDetail?.applicant_details?.profile_approval_status !== 1
              }
              onClick={() => {
                handleUpdateUserStatus(undefined, 2)
              }}
            >
              Accept
            </Button>
          </div>
        </Col>}

      </Row>}

      <Row>
        {driverDetail?.specific_driver_details?.company_details && <Col lg={3} md={6} className="card entity-detail-card">
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <div className="d-flex justify-content-between align-item-center">
                <div className="driver-detail_account d-flex">
                  <div
                    className="avatar me-2"
                    style={{
                      backgroundImage: `url(${driverDetail?.specific_driver_details?.user_profile?.profile_photo})`
                    }}
                  ></div>
                  <div className="account d-flex flex-column justify-content-center">
                    <div className="full-name">
                      {driverDetail?.specific_driver_details?.name}
                    </div>
                    <div className="company">
                      {
                        driverDetail?.specific_driver_details?.company_details
                          ?.company_name
                      }
                    </div>
                  </div>
                </div>

                <Button className="d-flex button" variant="link" type="submit">
                  {isReadOnly ? <EditIcon /> : <CheckIcon />}
                </Button>
              </div>
              {/* <FormSelect
                placeholder="Truck Driver Type"
                controlId="validateTruckDriverType"
                onChange={e =>
                  formik.setFieldValue('truck_driver_type', e.target.value)
                }
                errorMessage={formik.errors.truck_driver_type}
                value={formik.values.truck_driver_type}
                isInvalid={!!formik.errors.truck_driver_type}
                disabled={isReadOnly}
                showLabel={true}
              >
                {driverTypes?.map((ele: IDNameResponse) => {
                  return <option value={ele.id}>{ele.name}</option>
                })}
              </FormSelect> */}
              <FormControl
                placeholder="License Number"
                controlId="validateLicenseNumber"
                onChange={e =>
                  formik.setFieldValue('license_number', e.target.value)
                }
                errorMessage={formik.errors.license_number}
                value={formik.values.license_number}
                isInvalid={!!formik.errors.license_number}
                disabled={isReadOnly}
                showLabel={true}
              />
              <FormControl
                placeholder="Valid Till"
                controlId="validateValidTill"
                onChange={e =>
                  formik.setFieldValue('valid_till', e.target.value)
                }
                errorMessage={formik.errors.valid_till}
                value={formik.values.valid_till}
                isInvalid={!!formik.errors.valid_till}
                disabled={isReadOnly}
                showLabel={true}
                min={new Date().toISOString().split('T')[0]}
                type="date"
              />
              {/* <FormSelect
                placeholder="Vehicle Type"
                controlId="validateVehicleType"
                onChange={e =>
                  formik.setFieldValue('vehicle_type', e.target.value)
                }
                errorMessage={formik.errors.vehicle_type}
                value={formik.values.vehicle_type}
                isInvalid={!!formik.errors.vehicle_type}
                disabled={isReadOnly}
                showLabel={true}
              >
                {vehicleTypes?.map((ele: IDNameResponse) => {
                  return <option value={ele.id}>{ele.name}</option>
                })}
              </FormSelect> */}
              {/* <UserInfoItem
                title="Vehicle Type"
                value={
                  vehicleTypes?.filter(
                    (ele: IDNameResponse) =>
                      ele.id ===
                      driverDetail?.specific_driver_details?.driver_details
                        .vehicle_type
                  )?.[0]?.name
                }
              /> */}
              {/* <FormControl
                placeholder="License Plate"
                controlId="validateLicensePlate"
                onChange={e =>
                  formik.setFieldValue('license_plate', e.target.value)
                }
                errorMessage={formik.errors.license_plate}
                value={formik.values.license_plate}
                isInvalid={!!formik.errors.license_plate}
                disabled={isReadOnly}
                showLabel={true}
              /> */}
              <FormControl
                placeholder="Address"
                controlId="validateStritaddress"
                onChange={e =>
                  formik.setFieldValue('street_address', e.target.value)
                }
                errorMessage={formik.errors.street_address}
                value={formik.values.street_address}
                isInvalid={!!formik.errors.street_address}
                disabled={isReadOnly}
                showLabel={true}
              />
              <FormControl
                placeholder="City"
                controlId="validateCity"
                onChange={e => formik.setFieldValue('city', e.target.value)}
                errorMessage={formik.errors.city}
                value={formik.values.city}
                isInvalid={!!formik.errors.city}
                disabled={isReadOnly}
              />
              <FormControl
                placeholder="State"
                controlId="validatestate"
                onChange={e => formik.setFieldValue('state', e.target.value)}
                errorMessage={formik.errors.state}
                value={formik.values.state}
                isInvalid={!!formik.errors.state}
                disabled={isReadOnly}
              />
              <FormControl
                placeholder="Zip Code"
                controlId="validateZipCode"
                onChange={e => formik.setFieldValue('zip_code', e.target.value)}
                errorMessage={formik.errors.zip_code}
                value={formik.values.zip_code}
                isInvalid={!!formik.errors.zip_code}
                disabled={isReadOnly}
              />
              <FormControl
                placeholder="Country"
                controlId="validateCountry"
                onChange={e => formik.setFieldValue('country', e.target.value)}
                errorMessage={formik.errors.country}
                value={formik.values.country}
                isInvalid={!!formik.errors.country}
                disabled={isReadOnly}
              />

              {driverDetail?.specific_driver_details?.user_profile?.status ==
                4 && (
                  <FormControl
                    placeholder="Suspension Reason"
                    controlId="validateSuspensionReason"
                    onChange={e =>
                      formik.setFieldValue('suspense', e.target.value)
                    }
                    errorMessage={formik.errors.country}
                    value={formik.values.suspension_reason}
                    isInvalid={!!formik.errors.country}
                    disabled={isReadOnly}
                  />
                )}

              {driverDetail?.specific_driver_details?.user_profile?.status !==
                1 && (
                  <Col className="driver-suspend">
                    <div>Suspend</div>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      checked={isSuspended}
                      onChange={handleOnChange}
                    />
                  </Col>
                )}
            </Form>
          </FormikProvider>
        </Col>
        }
        {!driverDetail?.specific_driver_details?.company_details && driverProfileDetail?.applicant_details && <Col lg={4} md={6}>
          <ApplicantDetails applicantDetails={{
            date_available_for_work: driverProfileDetail?.applicant_details?.date_available_for_work || '',
            date_of_birth: driverProfileDetail?.applicant_details?.date_of_birth || '',
            legal_right: driverProfileDetail?.applicant_details?.legal_to_work_in_US || false,
            license_number: driverProfileDetail?.applicant_details?.social_security_number || '',
            truck_driver_type: driverType
          }} />
        </Col>}
        {((!isLoading &&
          fetchUserType() === userTypes.ADMIN &&
          !!!driverDetail?.specific_driver_details?.company_details) ||
          (fetchUserType() === userTypes.COMPANY &&
            !!driverDetail?.specific_driver_details?.company_details)) && (
            <Col>
              <PageOtherDetails driverDetail={driverDetail} />
              {/* <PageTransports /> */}
            </Col>
          )}
      </Row>
      {!driverDetail?.specific_driver_details?.company_details && <>
        {driverProfileDetail?.residential_information && <ResidentialInformation
          residentialInformation={driverProfileDetail?.residential_information}
        />}
        {driverProfileDetail?.license_information && <LicenseInformation
          licenseInformation={driverProfileDetail?.license_information?.[0]}
        />}
        {driverProfileDetail?.driving_experience?.length > 0 && <DrivingExperience
          drivingExperience={driverProfileDetail?.driving_experience}
        />}
        {driverProfileDetail?.past_accidents?.[0]?.past_accident_records?.length > 0 && <Row>
          <Col sm={12}>
            <SectionHeading title="Accident Record">
              <Row>
                {
                  driverProfileDetail?.past_accidents?.[0]?.past_accident_records?.map((record: IAccidentRecords, index: number) => {
                    return <AccidentRecords
                      key={index}
                      recordNumber={index+1}
                      accidentRecords={{
                        date: record?.date || '',
                        nature_of_accident: record?.nature_of_accident || '',
                        number_of_fatalities: record?.number_of_fatalities || 0,
                        number_of_injuries: record?.number_of_injuries || 0,
                      }} />
                  })
                }
              </Row>
            </SectionHeading>
          </Col>
        </Row>}
        {driverProfileDetail?.traffic_conviction_history?.length > 0 && <Row>
          <Col sm={12}>
            <SectionHeading title="Traffic Convictions and Forfeitures." smallHeading={false}>
              <Row>
                {
                  driverProfileDetail?.traffic_conviction_history?.map((record: any, index: number) => {
                    return <TrafficConvictions
                      key={index}
                      recordNumber={index+1}
                      trafficConvictions={{
                        date: record?.date || '',
                        violation: record?.violation || '',
                        state_of_violation: record?.state || '',
                        penalty: record?.penalty || '',
                      }} />

                  })
                }
              </Row>
            </SectionHeading>
          </Col>
        </Row>}
        {
          driverProfileDetail?.employment_history?.length > 0 && <EmploymentHistories
            employmentHistory={driverProfileDetail?.employment_history}
          />
        }
        {
          driverProfileDetail?.education?.length > 0 && <EducationList
            education={driverProfileDetail?.education}
          />
        }
        <SuspensionReasonModal
          handleClose={handleClose}
          show={show}
          leftOnPress={suspendUser}
        />
        <DocumentRejectReasonModal
          handleClose={() => {
            setShowRejectReasonModal(false)
          }}
          show={showRejectReasonModal}
          onConfirm={(reason: string) => {
            handleUpdateUserStatus(reason, 2)
          }} />
      </>}
    </Container>
  )
}

export default PageDriverDetail
