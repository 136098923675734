import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import { AuthAPIs } from "../network/authApiService"
import commonSlice from "./commonSlice"
import { UserAPIS } from "../network/userApiService"
import { FeedbackAPIS } from "../network/feedbackApiService"
import {NotificationAPIS} from '../network/notificationsApiService'
import { JobsAPIS } from "../network/jobsApiService"
import { SettingAPIS } from "../network/settingApiService"
import { RadarAPIS } from "../network/radarApiService"
import { AnalyticsAPIS } from "../network/analyticsApiService"
import { TruckAPIS } from "../network/truckApiServices"


// ...

export const store = configureStore({
  reducer: {
    [AuthAPIs.reducerPath]: AuthAPIs.reducer,
    [UserAPIS.reducerPath]: UserAPIS.reducer,
    [TruckAPIS.reducerPath]: TruckAPIS.reducer,
    [FeedbackAPIS.reducerPath]: FeedbackAPIS.reducer,
    [NotificationAPIS.reducerPath] : NotificationAPIS.reducer,
    [JobsAPIS.reducerPath]: JobsAPIS.reducer,
    [SettingAPIS.reducerPath]: SettingAPIS.reducer,
    [RadarAPIS.reducerPath]: RadarAPIS.reducer,
    [AnalyticsAPIS.reducerPath]: AnalyticsAPIS.reducer,
    commonReducer: commonSlice
  },

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      AuthAPIs.middleware,
      UserAPIS.middleware,
      TruckAPIS.middleware,
      FeedbackAPIS.middleware,
      NotificationAPIS.middleware,
      JobsAPIS.middleware,
      SettingAPIS.middleware,
      RadarAPIS.middleware,
      AnalyticsAPIS.middleware
    )
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
