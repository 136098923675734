import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useLazyReassignedJobQuery } from '../../api/network/analyticsApiService'
import CardWithHeader from '../../components/cardWithHeader/CardWithHeader'
import FormSearch from '../../components/formSearch/formSearch'
import Table from '../../components/table/table'
import { DEBOUNCE_TIME } from '../../utils/constants'
import { minutesToHoursFormat } from '../../utils/DateUtils'
import useDebounce from '../../utils/hooks/useDedounce'
import { RenderUserStatus } from '../jobs/viewJobs/jobs-table'

export interface IAnSecJobReassignProps {
  title?: string
  startDate: any
  endDate: any
}

const AnSecJobReassign = (props: IAnSecJobReassignProps) => {
  const { title = 'Dumped vs Re-Assigned', startDate, endDate } = props

  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const debouncedValue = useDebounce(search, DEBOUNCE_TIME)

  const [
    reassignedJobAPITrigger,
    { data: dataReassignedJob, isLoading, isError }
  ] = useLazyReassignedJobQuery()
  const { next, previous, results, total_pages } = dataReassignedJob || {}

  useEffect(() => {
    {(startDate===null||endDate===null)?
    reassignedJobAPITrigger({ search: debouncedValue, page }):
    reassignedJobAPITrigger({ search: debouncedValue, page, from : startDate.toISOString().split("T")[0], to : endDate.toISOString().split("T")[0] })}
  }, [page, reassignedJobAPITrigger, debouncedValue, startDate, endDate])

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }

  const tableColumns = [
    {
      Header: 'Job ID',
      accessor: (cell: any) => <span className="text-break">{cell.job_id}</span>
    },
    {
      Header: 'Container Number',
      accessor: (cell: any) => (
        <span className="text-break">{cell.container_number}</span>
      )
    },
    {
      Header: 'Status',
      accessor: (cell: any) => <RenderUserStatus status={cell?.status} />
    },
    {
      Header: 'Driver Name',
      accessor: (cell: any) => <span>{cell.driver_name}</span>
    },
    {
      Header: 'Time Taken',
      accessor: (cell: any) => (
        <div className="text-break fc-blue fw-semibold text-right">
          {!!cell.time_taken || cell.time_taken === 0
            ? minutesToHoursFormat(cell.time_taken)
            : '--:--'}
          {' hrs'}
        </div>
      )
    }
  ]

  return (
    <CardWithHeader title={title} wrapperClassName={`ansec-job-reassign`}>
      <p className="mt-4 mb-0 m-0 fw-bold">Results are displayed are per the date range selected.</p>
      <Row className="py-3 align-items-center">
        <Col>
          <FormSearch
            search={search}
            setSearch={setSearch}
            formControlWrapperClassName={`m-0`}
            containerClassName={`m-0`}
          />
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col>
          <Table
            data={results || []}
            columns={tableColumns}
            page={page}
            setPage={setPage}
            onNext={onNext}
            onPrev={onPrev}
            pageCount={total_pages || 0}
            isLoading={isLoading}
            wrapperClassName={`table-wrap`}
          />
        </Col>
      </Row>
    </CardWithHeader>
  )
}
export default AnSecJobReassign
