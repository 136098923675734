import { Key, useState } from 'react'
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Row,
  ToggleButton
} from 'react-bootstrap'

import './company-detail.scss'
import DetailHeader from '../driver-detail/header/header'
import UserInfoItem from '../../../components/userInfoItem/user-info-item'
import {
  useCompanyDetailQuery,
  useUpdateUserMutation
} from '../../../api/network/userApiService'
import Loader from '../../../components/loader/Loader'
import { ReactComponent as VisibilityIcon } from '../../../assets/images/svg/visibility.svg'
import { Form, useNavigate, useParams } from 'react-router-dom'
import {
  COMPANY_TYPE,
  USER_STATUS,
  companyType,
  companyTypes,
  userTypes
} from '../../../utils/constants'
import { ROUTE_NAMES } from '../../../utils/route/routerNames'
import Table from '../../../components/table/table'
import JobTable from '../../jobs/viewJobs/jobs-table'
import EditIcon from '../../../assets/images/svg/EditIcon'
import { FormikProvider, useFormik } from 'formik'
import { addCompanySchema } from '../../../utils/Validations'
import { AddUserPayload, IDNameResponse } from '../../../api/types/Driver'
import { toast } from 'react-toastify'
import FormSelect from '../../../components/formSelect/FormSelect'
import FormControl from '../../../components/formControl/FormControl'
import CheckIcon from '../../../assets/images/svg/CheckIcon'

const PageCompanyDetail = () => {
  const { userId }: { userId?: string } = useParams()
  const navigate = useNavigate()
  const { data, isLoading } = useCompanyDetailQuery(userId || '1')
  const [isReadOnly, setReadOnly] = useState(true)
  console.log('data', data)
  const initialValues: AddUserPayload = {
    id: userId,
    email: data?.company?.user_profile?.email ?? '',
    confirmEmail: data?.company?.user_profile?.email ?? '',
    phone_number: data?.company?.user_profile?.phone_number ?? '',
    company_name: data?.company?.company_name ?? '',
    contact_person_name: data?.company?.contact_person_name ?? '',
    user_type: Number(userTypes.COMPANY),
    company_type: data?.company?.company_type + ''
  }

  const company = companyType.filter((ele: IDNameResponse, i: Key) => {
    return ele.id == data?.company?.company_type && ele.name
  })

  console.log('company', company[0]?.name)

  const [updateUserApiTrigger, { isLoading: updateApiLoading }] =
    useUpdateUserMutation()

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: addCompanySchema,
    onSubmit: (values: AddUserPayload) => {
      if (isReadOnly) {
        setReadOnly(!isReadOnly)
      } else {
        updateUserApiTrigger(values)
          .unwrap()
          .then(response => {
            toast.success(response.message)
            // formik.resetForm()
            setReadOnly(!isReadOnly)
          })
          .catch(e => {})
      }
    }
  })

  const tabs = [
    { name: 'Drivers', value: '1' },
    { name: 'Jobs', value: '2' }
  ]
  const [selectedTab, setSelectedTab] = useState(tabs[0].value)

  const renderUserStatus = (status: number) => (
    <span className={status === 2 ? 'fc-green' : 'fc-red'}>
      {USER_STATUS[status]}
    </span>
  )
  const columns = [
    {
      Header: 'Name',
      accessor: 'name'
    },
    {
      Header: 'Email',
      accessor: 'email'
    },
    {
      Header: 'Phone No.',
      accessor: 'user_profile.phone_number'
    },
    {
      Header: 'Status',
      accessor: (cell: any) => renderUserStatus(cell?.user_profile?.status)
    },
    {
      Header: '',
      id: 'action',
      accessor: (cell: any) => (
        <VisibilityIcon
          role="button"
          onClick={() =>
            navigate(`${ROUTE_NAMES.dashboardChildren.users}/driver/${cell.id}`)
          }
        />
      )
    }
  ]

  return (
    <Container fluid>
      {(isLoading || updateApiLoading) && <Loader />}
      <Row>
        <Col className="p-0">
          <DetailHeader title="Company Details" />
        </Col>
      </Row>
      <Row>
        <Col className="card entity-detail-card">
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <div className="driver-detail_account d-flex">
                  <div className="avatar me-2"></div>
                  <div className="account d-flex align-items-center justify-content-between w-100">
                    <div className="full-name">
                      {data?.company?.company_name}
                    </div>
                    {/* <div className="company"> {data?.company?.company_name}</div> */}
                    <Button
                      className="d-flex button"
                      variant="link"
                      type="submit"
                    >
                      {isReadOnly ? <EditIcon /> : <CheckIcon />}
                    </Button>
                  </div>
                </div>
              </Row>
              <Row>
                <Col>
                  {/* <UserInfoItem
                    title="Company Type"
                    value={COMPANY_TYPE[data?.company?.company_type ?? 1]}
                  /> */}
                  {isReadOnly ? (
                    <UserInfoItem
                      title="Company Type"
                      value={company[0]?.name}
                    />
                  ) : (
                    <FormSelect
                      placeholder="Company Type"
                      controlId="validateCompanyType"
                      onChange={e =>
                        formik.setFieldValue('company_type', e.target.value)
                      }
                      errorMessage={formik.errors.company_type}
                      value={formik.values.company_type}
                      isInvalid={!!formik.errors.company_type}
                      disabled={isReadOnly}
                      showLabel={true}
                    >
                      {companyType?.map((ele: IDNameResponse, i: Key) => {
                        return (
                          <option value={ele.id} key={i}>
                            {ele.name}
                          </option>
                        )
                      })}
                    </FormSelect>
                  )}
                </Col>
                <Col>
                  <FormControl
                    label="Contact Person"
                    controlId="validateCompanyPersonName"
                    onChange={e =>
                      formik.setFieldValue(
                        'contact_person_name',
                        e.target.value
                      )
                    }
                    errorMessage={formik.errors.contact_person_name}
                    value={formik.values.contact_person_name}
                    isInvalid={!!formik.errors.contact_person_name}
                    disabled={isReadOnly}
                    showLabel={true}
                  />
                </Col>
                <Col>
                  <FormControl
                    type="email"
                    label="Email"
                    controlId="validateEmail"
                    onChange={e =>
                      formik.setFieldValue('email', e.target.value)
                    }
                    errorMessage={formik.errors.email}
                    value={formik.values.email}
                    isInvalid={!!formik.errors.email}
                    disabled={isReadOnly}
                    showLabel={true}
                  />
                </Col>
                <Col>
                  <FormControl
                    type="number"
                    label="Phone Number"
                    controlId="validatePhone"
                    onChange={e =>
                      formik.setFieldValue('phone_number', e.target.value)
                    }
                    errorMessage={formik.errors.phone_number}
                    value={formik.values.phone_number}
                    isInvalid={!!formik.errors.phone_number}
                    disabled={isReadOnly}
                    showLabel={true}
                  />
                </Col>
              </Row>
            </Form>
          </FormikProvider>
        </Col>
      </Row>

      <Row>
        <div>
          <ButtonGroup className="mb-2 w-25">
            {tabs.map((radio, i) => (
              <ToggleButton
                key={i}
                id={`radio-${i}`}
                type="checkbox"
                variant="outline-primary"
                value={radio.value}
                checked={selectedTab === radio.value}
                onChange={e => setSelectedTab(e.currentTarget.value)}
                className={`tab ${
                  selectedTab === radio.value ? 'tabSelected' : 'tabUnSelected'
                }`}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </div>
      </Row>
      <Row>
        <Col>
          {selectedTab === tabs[0].value ? (
            <Table
              data={data?.drivers || []}
              columns={columns}
              hidePagination={true}
            />
          ) : (
            <JobTable jobs={data?.jobs || []} />
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default PageCompanyDetail
