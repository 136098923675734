export const ROUTE_NAMES = {
  landingPage: '/',
  privacyPolicy: '/privacy-policy',
  authChildren: {
    login: '/login',
    forgotPassword: '/forgotPassword',
    resetPassword: '/resetPassword/:email/:token',
    setPassword: '/setPassword'
  },

  dashboard: '/dashboard',
  dashboardChildren: {
    users: '/dashboard/users',
    addUsers: '/dashboard/add',
    driverDetail: '/dashboard/users/driver/:userId',
    companyDetail: '/dashboard/users/company/:userId',
    feedback: '/dashboard/feedback',
    analytics: '/dashboard/analytics',
    jobs: '/dashboard/jobs',
    jobDetail: '/dashboard/jobs/:jobId',
    addJobs: '/dashboard/job/add',
    editJob: '/dashboard/job/edit/:jobId',
    settings: '/dashboard/settings',
    truckList: '/dashboard/truckList',
    addTruckDetails: '/dashboard/truck/add',
    trucks: '/dashboard/truck',
    truckDetails: '/dashboard/truck/:truckId',
    editTruck: '/dashboard/truck/edit/:truckId'
  }
}
