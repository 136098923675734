import { UserResponse } from '../../../api/types/Driver'
import FormSelect from '../../../components/formSelect/FormSelect'

interface ICompanySelectorProps {
  companiesList: UserResponse[]
  onChange?: React.ChangeEventHandler<HTMLSelectElement>
  selectedCompany?: number
}

const AnSecCompCompanySelector = (props: ICompanySelectorProps) => {
  const { companiesList, onChange, selectedCompany } = props

  return (
    <FormSelect
      placeholder="Company"
      placeholderOptionText={'All Companies'}
      value={`${selectedCompany}`}
      onChange={onChange}
      wrapperClassName="m-0"
    >
      {!!companiesList && companiesList.length > 0 ? (
        companiesList.map((companyItem: UserResponse, i: number) => (
          <option value={companyItem?.company_details?.id} key={i}>
            {companyItem?.company_details?.company_name ||
              `ID #${companyItem?.company_details?.id}`}
          </option>
        ))
      ) : (
        <option value={''} disabled>
          No companies available
        </option>
      )}
    </FormSelect>
  )
}
export default AnSecCompCompanySelector
