import { Form } from "react-bootstrap";
import { FormikProvider, useFormik } from "formik";
import CardWithHeader from "../../../../../components/cardWithHeader/CardWithHeader";
import FormControl from "../../../../../components/formControl/FormControl";
import './license.scss';
import { ILicense } from "../../../../../api/types/Driver";
import { ILicenseInformation } from "./types";
import BottomTooltip from "../../../../../components/BottomTooltip/BottomTooltip";


const License = (props: ILicenseInformation) => {

  const { licenseInfo, title } = props;

  const endorsements = Array.isArray(licenseInfo?.endorsements)  ? licenseInfo?.endorsements?.join(", ") : '';

  const initialValues: ILicense = {
    state: licenseInfo?.state,
    license_number: licenseInfo?.license_number,
    class: licenseInfo?.class,
    endorsements: endorsements,
    expiry_date: licenseInfo?.expiry_date,
    held_other_license: licenseInfo?.held_other_license
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values: ILicense) => { }
  })

  return (
    <div className="driver-license-info">
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <CardWithHeader title={title || ''}>
            <div>
              <FormControl
                placeholder="State"
                controlId="validateState"
                onChange={e => formik.setFieldValue('state', e.target.value)}
                errorMessage={formik.errors.state}
                value={formik.values.state}
                isInvalid={!!formik.errors.state}
                showLabel={true}
                readOnly
              />
              <FormControl
                placeholder="License Number"
                controlId="validateLicenseNumber"
                onChange={e => formik.setFieldValue('license_number', e.target.value)}
                errorMessage={formik.errors.license_number}
                value={formik.values.license_number}
                isInvalid={!!formik.errors.license_number}
                showLabel={true}
                readOnly
              />
              <FormControl
                placeholder="Type/Class"
                controlId="validateClass"
                onChange={e => formik.setFieldValue('class', e.target.value)}
                errorMessage={formik.errors.class}
                value={formik.values.class}
                isInvalid={!!formik.errors.class}
                showLabel={true}
                readOnly
              />
              <FormControl
                placeholder="Endorsements"
                controlId="validateEndorsements"
                onChange={e => formik.setFieldValue('endorsements', e.target.value)}
                errorMessage={formik.errors.endorsements}
                value={formik.values.endorsements}
                isInvalid={!!formik.errors.endorsements}
                showLabel={true}
                readOnly
              />
              <FormControl
                type="date"
                placeholder="Expiry Date"
                controlId="validateDate"
                onChange={e => formik.setFieldValue('expiry_date', e.target.value)}
                errorMessage={formik.errors.expiry_date}
                value={formik.values.expiry_date}
                isInvalid={!!formik.errors.expiry_date}
                showLabel={true}
                readOnly
              />
              <div className="question">May I inquire if you have previously held any other licenses?</div>
              <div className="answer">{licenseInfo?.held_other_license ? 'Yes' : 'No'}</div>
            </div>
          </CardWithHeader>
        </Form>
      </FormikProvider>
    </div>
  )
}

export default License;