export const RADAR_API_KEY =
  window.location.href.indexOf('staging') > -1 ||
  window.location.href.indexOf('localhost') > -1
    ? process.env.REACT_APP_RADAR_PUBLISHABLE_CLIENT_APIKEY
    : process.env.REACT_APP_RADAR_PUBLISHABLE_CLIENT_APIKEY_PROD

export interface IDisplayPickDropLocationOptions {
  includeFlag?: Boolean
}

export class CRadarLocationOption {
  countryFlag?: string
  placeLabel?: string
  formattedAddress?: string
  latitude?: number
  longitude?: number
}

export const getPickDropLocationObject = (
  locValue: string
): CRadarLocationOption => {
  const jsonLoc =
    locValue?.substring(0, 1) === '[' ? JSON.parse(locValue)[0] : null
  return jsonLoc
}

export const displayPickDropLocation = (
  locValue: string,
  options: IDisplayPickDropLocationOptions = {
    includeFlag: true
  },
  locObject?: CRadarLocationOption
): string => {
  const jsonLoc = locObject || getPickDropLocationObject(locValue)
  return jsonLoc
    ? [
        options?.includeFlag ? jsonLoc.countryFlag : '',
        jsonLoc?.formattedAddress ||
          (jsonLoc?.placeLabel
            ? `${jsonLoc?.placeLabel} [${jsonLoc?.latitude},${jsonLoc?.longitude}]`
            : '')
      ]
        .filter(item => !!item)
        .join(' ')
    : locValue
}
