import React, { useEffect } from 'react'
import { useLazyUserDetailsQuery } from '../../api/network/analyticsApiService'
import { APPCONFIG } from '../../app-config'
import CardWithHeader from '../../components/cardWithHeader/CardWithHeader'
import PieChartWithLegend from '../../components/pieChartWithLegend/pieChartWithLegend'

export interface IAnSecUserDetailsProps {
  title?: string
}

const AnSecUserDetails = (props: IAnSecUserDetailsProps) => {
  const { title = 'Driver Statistics' } = props

  const [
    userDetailsAPITrigger,
    {
      data: dataUserDetails,
      isLoading: isLoadingUserDetails,
      isError: isErrorUserDetails
    }
  ] = useLazyUserDetailsQuery()

  useEffect(() => {
    userDetailsAPITrigger(null)
  }, [])

  return (
    <CardWithHeader title={title} wrapperClassName={`ansec-user-details`}>
      {!!dataUserDetails && !isLoadingUserDetails && (
        <PieChartWithLegend
          data={[
            {
              name: 'Active Drivers',
              value: dataUserDetails?.data?.active_driver ?? 0
            },
            {
              name: 'New Drivers',
              value: dataUserDetails?.data?.new_driver ?? 0
            },
            {
              name: 'Suspended',
              value: dataUserDetails?.data?.suspended_driver ?? 0
            }
          ].map((item, index) => {
            return { ...item, fill: APPCONFIG.COLORS.pieChart[index] }
          })}
          interactiveLabel={false}
          maxHeight={250}
          showLegend={true}
          totalCountLabel={`Total Drivers`}
        />
      )}
    </CardWithHeader>
  )
}
export default AnSecUserDetails
