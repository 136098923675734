import React, { SVGProps } from 'react'

function EndLocationIcon(props: SVGProps<SVGSVGElement>) {
  const { color = '#F80000' } = props

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={color}
        d="M8.00033 7.99998C8.73366 7.99998 9.33366 7.39998 9.33366 6.66665C9.33366 5.93331 8.73366 5.33331 8.00033 5.33331C7.26699 5.33331 6.66699 5.93331 6.66699 6.66665C6.66699 7.39998 7.26699 7.99998 8.00033 7.99998ZM8.00033 1.33331C10.8003 1.33331 13.3337 3.47998 13.3337 6.79998C13.3337 8.91998 11.7003 11.4133 8.44033 14.2866C8.18699 14.5066 7.80699 14.5066 7.55366 14.2866C4.30033 11.4133 2.66699 8.91998 2.66699 6.79998C2.66699 3.47998 5.20033 1.33331 8.00033 1.33331Z"
      />
    </svg>
  )
}

export default EndLocationIcon
