import React from "react"
import BackIcon from "../../../../assets/images/svg/BackIcon"
import "./header.scss"
import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"

interface Props {
  title: string;
  className?: string;
}

const DetailHeader = (props: Props) => {
  const { title, className } = props
  const navigate = useNavigate()
  return (
    <div className={`detail_header d-flex ${className}`}>
      <Button
        className="d-flex button align-items-center justify-content-center"
        variant="link"
        onClick={() => {
          navigate(-1)
        }}
      >
        <BackIcon />
      </Button>
      <h1 className="title mb-0">{title}</h1>
    </div>
  )
}
export default DetailHeader
