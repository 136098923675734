import { Col, Container, Row } from 'react-bootstrap'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'
import DashHeader from './header'

import './dashboard.scss'
import DashSidebar from './sidebar'
import { fetchUserData } from '../../utils/storage'
import { ROUTE_NAMES } from '../../utils/route/routerNames'
import { UserAPIS, useLogoutMutation } from '../../api/network/userApiService'
import { localStorageKeys } from '../../utils/constants'
import { useState } from 'react'
import Loader from '../../components/loader/Loader'
import { useDispatch } from 'react-redux'
import AppStyledModal from '../../components/modal/appStyledModal/appStyledModal'
import { sideEffectLogOut } from '../../utils/helper'

const DashboardLayout = () => {
  const [logoutAPITrriger, { isLoading }] = useLogoutMutation()
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  const dispatch = useDispatch()

  const userData = fetchUserData()
  if (!userData?.token) {
    return <Navigate to={ROUTE_NAMES.landingPage} />
  }

  const handleClose = () => setShow(false)

  const handleLogout = () => {
    handleClose()
    dispatch(UserAPIS.util.resetApiState())
    logoutAPITrriger()
      .unwrap()
      .then(_response => {
        sideEffectLogOut(false)
        navigate(ROUTE_NAMES.landingPage)
      })
      .catch(_e => {})
  }
  return (
    <Container fluid className="layout-dashboard d-flex flex-column">
      {isLoading && <Loader />}
      <Row className="row-dash-header">
        <Col className="col-dash-header">
          <DashHeader />
        </Col>
      </Row>
      <Row className="row-dash-main flex-grow-1">
        <Col
          md={3}
          xs={4}
          lg={2}
          className="col-dash-sidebar d-flex flex-column"
        >
          <DashSidebar
            handleLogout={() => {
              setShow(true)
            }}
          />
        </Col>
        <Col className="col-dash-main">
          <Outlet />
        </Col>
      </Row>
      <AppStyledModal
        closeButton={false}
        handleClose={handleClose}
        show={show}
        title="Log Out"
        leftOnPress={handleClose}
        leftButtonText={`Cancel`}
        rightOnPress={handleLogout}
        rightButtonText={`Log Out`}
      >
        <p className="fs-20 my-3">Are you sure you want to log out?</p>
      </AppStyledModal>
    </Container>
  )
}

export default DashboardLayout
