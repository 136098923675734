import React from "react"

const PageActionsBar = ({
  wrapperClassName = ``,
  ContentLeft = () => <></>,
  ContentRight = () => <></>,
  ...props
}) => {
  return (
    <div
      className={`d-flex justify-content-between align-items-center page-actions-bar ${wrapperClassName}`}
    >
      <ContentLeft />
      <div className="flex-grow-1"></div>
      {props.children || <ContentRight/>} 
    </div>
  )
}
export default PageActionsBar
