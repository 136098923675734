import { useEffect, useState } from 'react'
import Table from '../../../components/table/table'
import { useLazyAllDriversQuery } from '../../../api/network/userApiService'
import {
  DEBOUNCE_TIME,
  userStatus,
  USER_STATUS
} from '../../../utils/constants'
import { ReactComponent as VisibilityIcon } from '../../../assets/images/svg/visibility.svg'
import { useNavigate } from 'react-router-dom'
import { ROUTE_NAMES } from '../../../utils/route/routerNames'
import useDebounce from '../../../utils/hooks/useDedounce'
import { FilterValue } from 'react-table'
import Filter from '../../../components/formFilter/formFilter'
import { Filter_Obj } from '../../../components/formFilter/types'
import { getStatusFilterKeys } from '../../../utils/helper'

const PageDriverList = ({ search }: { search: string }) => {
  const [page, setPage] = useState(1)
  const [statusFilter, setStatusFilter] = useState<Filter_Obj[]>([])
  const navigate = useNavigate()
  const debouncedValue = useDebounce(search, DEBOUNCE_TIME)
  const [allDriverTrigger, { data, isLoading }] = useLazyAllDriversQuery()

  useEffect(() => {
    setPage(1)
    if (statusFilter.length) {
      allDriverTrigger({
        search: debouncedValue,
        page: 1,
        status: getStatusFilterKeys(statusFilter, 'status')
      })
    } else {
      allDriverTrigger({ search: debouncedValue, page: 1 })
    }
  }, [debouncedValue, statusFilter, allDriverTrigger])

  useEffect(() => {
    allDriverTrigger({ search: debouncedValue, page })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, allDriverTrigger])

  //const { isLoading, data } = useAllDriversQuery({search, page })

  const { next, previous, results, total_pages } = data || {}

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }
  const renderUserStatus = (status: number) => (
    <span className={status === 2 ? 'fc-green' : 'fc-red'}>
      {USER_STATUS[status]}
    </span>
  )
  function SelectColumnFilter({
    column: { filterValue, setFilter }
  }: FilterValue) {
    return (
      <Filter
        options={[
          { id: `${userStatus.ACTIVE}`, value: 'Active' },
          { id: `${userStatus.SUSPENDED}`, value: 'Suspended' }
        ]}
        filter={filterValue}
        setFilter={setFilter}
      />
    )
  }

  const getFullName = (fName: string, mName: string | null, lName: string) => {
    return mName ? fName + " " + mName + " " +  lName : fName + " " + lName
  }
  const columns = [
    {
      Header: 'Name',
      accessor: (cell: any) => {
        const user_profile = cell?.driver_profile?.user_profile;
        const fullName = getFullName(user_profile?.first_name, user_profile?.middle_name, user_profile?.last_name);
        return cell.name || fullName;
      }
    },
    {
      Header: 'Email',
      accessor: 'email'
    },
    {
      Header: 'Phone No.',
      accessor: 'user_profile.phone_number'
    },
    {
      Header: 'Status',
      accessor: (cell: any) => renderUserStatus(cell?.user_profile?.status),
      defaultCanFilter: true,
      Filter: SelectColumnFilter
    },
    {
      Header: 'Company',
      accessor: (cell: any) => (
        <span className="fc-blue">{cell?.company_details?.company_name}</span>
      )
    },
    {
      Header: '',
      id: 'action',
      accessor: (cell: any) => (
        <VisibilityIcon
          role="button"
          onClick={() =>
            navigate(`${ROUTE_NAMES.dashboardChildren.users}/driver/${cell.id}`)
          }
        />
      )
    }
  ]

  return (
    <Table
      data={results || []}
      columns={columns}
      page={page}
      setPage={setPage}
      onNext={onNext}
      onPrev={onPrev}
      pageCount={total_pages || 0}
      isLoading={isLoading}
      filter={statusFilter}
      setFilter={setStatusFilter}
    />
  )
}

export default PageDriverList
