import { useState } from 'react'

import { Col, Container, Row } from 'react-bootstrap'
import FormSearch from '../../../components/formSearch/formSearch'

import TruckList from './truck-list'

const TruckData = () => {
  const [search, setSearch] = useState('')

  return (
    <Container fluid className="containerWidth">
      <Row>
        <Col md={9}>
          <h1 className="page-title">View Trucks</h1>
        </Col>

        <Col>
          <FormSearch search={search} setSearch={setSearch} />
        </Col>
      </Row>
      <Row>
        <TruckList search={search} />
      </Row>
    </Container>
  )
}

export default TruckData
