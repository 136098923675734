import { Col, Container, Form, ListGroup, Row } from 'react-bootstrap'
import FeedbackItem from './feedbackItem/feedbackItem'
import './feedbacks.scss'
import FormControl from '../../../components/formControl/FormControl'
import PrimaryButton from '../../../components/primaryButton/PrimaryButton'
import {
  useFetchFeedbacksQuery,
  useSendFeedbackReplyMutation
} from '../../../api/network/feedbackApiService'
import { FeedbackData, ReplyPayload } from '../../../api/types/Feedbacks'
import { useEffect, useRef, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { feedbackReplySchema } from '../../../utils/Validations'
import Loader from '../../../components/loader/Loader'
import Footer from '../../../components/table/footer'
import { DateFormat, changeDateFormat } from '../../../utils/DateUtils'
import { toast } from 'react-toastify'
import { USER_TYPES } from '../../../utils/constants'

const PageFeedbacks = () => {
  const [page, setPage] = useState(1)
  const { data: feedbacks, isLoading } = useFetchFeedbacksQuery({ page })
  const [feedbackReplyApiTrigger, { isLoading: replyLoading }] =
    useSendFeedbackReplyMutation()

  const [item, setItem] = useState(feedbacks?.results?.[0])
  const formRef = useRef<FormikProps<ReplyPayload>>(null)

  const { next, previous, results, total_pages = 1 } = feedbacks || {}

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }

  const initialValue: ReplyPayload = {
    email: item?.user_email ?? '',
    text: ''
  }
  useEffect(() => {
    setItem(results?.[0])
  }, [results])
  const handleSubmit = (values: ReplyPayload) => {
    feedbackReplyApiTrigger(values)
      .unwrap()
      .then(response => {
        formRef?.current?.resetForm()
        toast.success('Reply sent successfully')
      })
      .catch(e => {})
  }
  return (
    <>
      {(isLoading || replyLoading) && <Loader />}

      <Container fluid className="feedback-container containerWidth">
        <Row className="h-100">
          <Col lg={5} md={6} className="feedback-list-container">
            <h1 className="title">Feedback</h1>
            <ListGroup className="feedback-list">
              {results?.map((ele: FeedbackData) => {
                return (
                  <FeedbackItem
                    item={ele}
                    setter={setItem}
                    selectedItem={item}
                  />
                )
              })}
            </ListGroup>
            {(results?.length ?? 0) > 0 && (
              <Footer
                onPrev={onPrev}
                onNext={onNext}
                pageCount={total_pages}
                currentPage={page}
                setPage={setPage}
                className="mb-0"
              />
            )}
          </Col>
          {item && (
            <Col lg={7} md={6} className="feedback-detail-container h-100 ps-4">
              <div className="d-flex justify-content-between">
                <div className="account">
                  <div className="full-name">
                    {item?.user_name}
                    <span className="user-role ms-2">
                      {USER_TYPES[Number(item?.user_type)]}
                    </span>
                  </div>
                  <div className="email">{item?.user_email}</div>
                </div>
                <div className="date">
                  {changeDateFormat(
                    item?.created_at,
                    DateFormat.YYYYMMMDDHHMMSSA,
                    DateFormat.DDMMMYYYY
                  )}
                </div>
              </div>

              <div className="feedback">{item.text}</div>
              <Row className="input-container">
                <Formik
                  initialValues={initialValue}
                  onSubmit={handleSubmit}
                  validationSchema={feedbackReplySchema}
                  innerRef={formRef}
                >
                  {({
                    errors,
                    handleChange,
                    handleSubmit,
                    touched,
                    values
                  }) => {
                    return (
                      <Col>
                        <Form noValidate onSubmit={handleSubmit}>
                          <FormControl
                            placeholder="Please type in your response here..."
                            controlId="feedbackReply"
                            className="form-control"
                            as="textarea"
                            errorMessage={errors.text}
                            value={values.text}
                            onChange={handleChange('text')}
                            isInvalid={!!errors.text}
                          />
                          <div className="d-flex justify-content-end">
                            <PrimaryButton title="Reply" type="submit" className='mt-4' />
                          </div>
                        </Form>
                      </Col>
                    )
                  }}
                </Formik>
              </Row>
            </Col>
          )}
        </Row>
      </Container>
    </>
  )
}

export default PageFeedbacks
