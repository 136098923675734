import React from "react"
import "./license-information.scss"
import { Col, Row } from "react-bootstrap";
import SectionHeading from "../section-heading/section-heading";
import License from "./license/license";
import { IDriverLicenseInformation } from "./types";

const LicenseInformation = (props: IDriverLicenseInformation) => {

  const { licenseInformation } = props;

  return (<>
    <Row>
      <Col sm={12}>
        <SectionHeading title="License Information">
          <Row>
            <Col lg={4} md={6}>
              <License
                title='Active License'
                licenseInfo={{
                  state: licenseInformation?.state || '',
                  license_number: licenseInformation?.license_number || '',
                  class: licenseInformation?.type_class || '',
                  endorsements: licenseInformation?.endorsement_display || '',
                  expiry_date: licenseInformation?.expiry_date || '',
                  held_other_license: licenseInformation?.previous_held_license || false,
                }}
              />
            </Col>
            {
              licenseInformation?.previous_held_license_details?.map((licenseInfo, index) => {
                return (
                  <Col lg={4} md={6} key={index}>
                    <License
                      title='Previously Held License'
                      licenseInfo={{
                        state: licenseInfo?.state || '',
                        license_number: licenseInfo?.license_number || '',
                        class: licenseInfo?.type_class || '',
                        endorsements: licenseInfo?.endorsement_display || '',
                        expiry_date: licenseInfo?.expiry_date || '',
                        held_other_license: false,
                      }}
                    />
                  </Col>
                )
              })
            }
          </Row>
        </SectionHeading>
      </Col>
    </Row>
  </>
  )
}

export default LicenseInformation
