import { useEffect, useState } from 'react'
import { ButtonGroup, Col, Container, Row, ToggleButton } from 'react-bootstrap'
import PageActionsBar from '../../../layout/dashboardLayout/pageActionsBar'
import PageDriverList from '../driver-list/driver-list'
import PageCompanyList from '../company-list/company-list'
import { fetchUserType } from '../../../utils/storage'
import { userTypes } from '../../../utils/constants'
import FormSearch from '../../../components/formSearch/formSearch'
import { useLocation } from 'react-router-dom'
import DetailHeader from '../../jobs/jobDetail/header/header'

const PageUsersList = () => {
  const { hash } = useLocation()
  const chips = [
    { name: 'Driver', value: 'driver' },
    { name: 'Company', value: 'company' }
  ]
  const [radioValue, setRadioValue] = useState(chips[0].value)
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (hash) {
      chips.forEach((chip, i) => {
        if (hash === `#${chip.value}`) {
          setRadioValue(chip.value)
        }
      })
    }
  }, [])

  useEffect(() => {
    setSearch('')
  }, [radioValue])

  return (
    <Container fluid className="containerWidth">
      <Row>
        <Col>
          <Row>
            <Col>
              <DetailHeader
                title={
                  fetchUserType() === userTypes.COMPANY
                    ? 'View Drivers'
                    : 'View Users'
                }
                className={
                  fetchUserType() === userTypes.COMPANY ? 'mb-0' : 'mb-2'
                }
                {...(fetchUserType() === userTypes.COMPANY
                  ? {
                      CtaComponent: (
                        <FormSearch
                          search={search}
                          setSearch={setSearch}
                          containerClassName={`m-0`}
                          formControlWrapperClassName={`m-0`}
                          className={`m-0`}
                        />
                      )
                    }
                  : {})}
              />
            </Col>
            {/* <Col>
              <h1 className="page-title">View User</h1>
            </Col>
            {fetchUserType() === userTypes.COMPANY && (
              <Col>
                <FormSearch
                  search={search}
                  setSearch={setSearch}
                />
              </Col>
            )} */}
          </Row>

          {fetchUserType() === userTypes.ADMIN && (
            <PageActionsBar
              wrapperClassName={`mt-0`}
              ContentLeft={() => (
                <>
                  <ButtonGroup>
                    {chips.map((radio, i) => (
                      <ToggleButton
                        key={i}
                        id={`radio-${i}`}
                        type="checkbox"
                        variant="outline-primary"
                        value={radio.value}
                        checked={radioValue === radio.value}
                        onChange={e => {
                          window.location.hash = radio.value
                          setRadioValue(e.currentTarget.value)
                        }}
                        className={`tab ${
                          radioValue === radio.value
                            ? 'tabSelected'
                            : 'tabUnSelected'
                        }`}
                      >
                        {radio.name}
                      </ToggleButton>
                    ))}
                  </ButtonGroup>
                </>
              )}
            >
              <div>
                <FormSearch
                  search={search}
                  setSearch={setSearch}
                  containerClassName={`m-0`}
                  formControlWrapperClassName={`m-0`}
                  className={`m-0`}
                />
              </div>
            </PageActionsBar>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {radioValue === chips?.[0]?.value ? (
            <PageDriverList search={search} />
          ) : (
            <PageCompanyList search={search} />
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default PageUsersList
