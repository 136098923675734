import React, { useEffect, useState } from "react";
import { ISettings } from "../../settings/types";
import Accordion from 'react-bootstrap/Accordion';
import './documents.scss';

const UploadDocument= (props: ISettings) =>{
  const [docTypes, setDocTypes]= useState(['Operating Authority', 'FMSCA Safety Rating', 'W9 Form', 'Business License', 'Other Documents as needed']);
    const { setLoading } = props
    // useEffect(() => {
    //   setLoading(isLoading)
    // }, [isLoading, setLoading])
    return(
        <div className="upload-doc-wrapper row m-2">
          <div className="upload-doc-header">Documents</div>
{/* <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Certificate of Insurance</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1">
        <Accordion.Header>Operating Authority</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </Accordion.Body>
      </Accordion.Item>

      </Accordion> */}
      {/* <div className="document-title-wrapper">
        <div className="document-title">Certificate of Insurance</div>
        </div>
        <div className="doc-sub-wrapper">
        <div className="doc-sub-title-wrapper">
        <div className="document-subtitle">Liability insurance</div>
        <div className="document-view">View</div>
        </div>

        <div className="doc-sub-title-wrapper">
        <div className="document-subtitle">Cargo insurance</div>
        <div className="document-view">View</div>
        </div>

        <div className="doc-sub-title-wrapper">
        <div className="document-subtitle">Worker's compensation Insurance</div>
        <div className="document-view">View</div>
        </div>

        </div> */}
        <Accordion defaultActiveKey="1">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Certificate of Insurance</Accordion.Header>
        <Accordion.Body>
          <div className="doc-sub-wrapper">
            <div className="doc-sub-title-wrapper">
            <div className="document-subtitle">Liability insurance</div>
            <div className="document-view">View</div>
            </div>
    
            <div className="doc-sub-title-wrapper">
            <div className="document-subtitle">Cargo insurance</div>
            <div className="document-view">View</div>
            </div>
    
            <div className="doc-sub-title-wrapper">
            <div className="document-subtitle">Worker's compensation Insurance</div>
            <div className="document-view">View</div>
            </div>
    
            </div>
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
      {
        docTypes?.map((item, index)=>
        <div className="document-title-wrapper">
        <div className="document-title">{item}</div>
        <div className="document-view">View</div>
        </div>
        )
      }
        </div>
    )
}

export default UploadDocument;