import { Container, Form } from "react-bootstrap";
import { IDriverAddress } from "./types";
import { FormikProvider, useFormik } from "formik";
import CardWithHeader from "../../../../../components/cardWithHeader/CardWithHeader";
import FormControl from "../../../../../components/formControl/FormControl";
import './address.scss';
import { IAddress } from "../../../../../api/types/Driver";

const Address = (props: IDriverAddress) => {

  const { address, title } = props;

  const initialValues: IAddress = {
    street: address?.street,
    state: address?.state,
    city: address?.city,
    zip_code: address?.zip_code,
    no_of_years: address?.no_of_years
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values: IAddress) => { }
  })

  return (
    <Container fluid className="driver-residential-info">
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <CardWithHeader title={title || ''}>
            <div>
              <FormControl
                placeholder="Street"
                controlId="validateStreet"
                onChange={e => formik.setFieldValue('street', e.target.value)}
                errorMessage={formik.errors.street}
                value={formik.values.street}
                isInvalid={!!formik.errors.street}
                showLabel={true}
                readOnly
              />
              <FormControl
                placeholder="State"
                controlId="validateState"
                onChange={e => formik.setFieldValue('state', e.target.value)}
                errorMessage={formik.errors.state}
                value={formik.values.state}
                isInvalid={!!formik.errors.state}
                showLabel={true}
                readOnly
              />
              <FormControl
                placeholder="City"
                controlId="validateCity"
                onChange={e => formik.setFieldValue('city', e.target.value)}
                errorMessage={formik.errors.city}
                value={formik.values.city}
                isInvalid={!!formik.errors.city}
                showLabel={true}
                readOnly
              />
              <FormControl
                placeholder="Zip code"
                controlId="validateZipcode"
                onChange={e => formik.setFieldValue('zip_code', e.target.value)}
                errorMessage={formik.errors.zip_code}
                value={formik.values.zip_code}
                isInvalid={!!formik.errors.zip_code}
                showLabel={true}
                readOnly
              />
              <FormControl
                placeholder="Number of Years"
                controlId="validateYears"
                onChange={e => formik.setFieldValue('no_of_years', e.target.value)}
                errorMessage={formik.errors.no_of_years}
                value={formik.values.no_of_years}
                isInvalid={!!formik.errors.no_of_years}
                showLabel={true}
                readOnly
              />
            </div>
          </CardWithHeader>
        </Form>
      </FormikProvider>
    </Container>
  )
}

export default Address;