import moment from 'moment'

// Date formats used in application
export enum DateFormat {
  YYYYMMMDDHHMMSSA = 'YYYY-MM-DDTHH:mm:ssZ',
  DDMMMYYYY = 'DD MMM, YYYY',
  yyyyMMdd = 'yyyy-MM-dd',
  DDMMMYYYY1 = 'DD MMM YYYY',
  DDMMMYYYYHHMM = 'DD MMM YYYY HH:mm a',
  YYYYMMMDDHHMMSS = 'YYYY-MM-DD HH:mm:ss'
}

/**
 * Method to get date in string.
 * @param date is type of Date
 * @param format is type of DateFormat.
 * @returns date string
 */
export const getDateInFormat = (
  date: Date = moment().toDate(),
  format: DateFormat
) => {
  return moment(date).format(format)
}

export function changeDateFormat(
  date: string,
  fromFormat: DateFormat,
  toFormat: DateFormat
) {
  return moment(date, fromFormat).local().format(toFormat)
}

export function getDate(date: string, fromFormat: DateFormat) {
  return moment(date, fromFormat).toDate()
}

export const minutesToHoursFormat = (minutes: number) =>
  moment.utc().startOf('day').add(minutes, 'minutes').format('HH:mm')
