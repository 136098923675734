import React, { SVGProps } from 'react'

function AddTruckIcon(props: SVGProps<SVGSVGElement>) {
  const { color = '#0154CC' } = props

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.5 8H17V6C17 4.9 16.1 4 15 4H3C1.9 4 1 4.9 1 6V15C1 16.1 1.9 17 3 17C3 18.66 4.34 20 6 20C7.66 20 9 18.66 9 17H15C15 18.66 16.34 20 18 20C19.66 20 21 18.66 21 17H22C22.55 17 23 16.55 23 16V12.67C23 12.24 22.86 11.82 22.6 11.47L20.3 8.4C20.11 8.15 19.81 8 19.5 8ZM6 18C5.45 18 5 17.55 5 17C5 16.45 5.45 16 6 16C6.55 16 7 16.45 7 17C7 17.55 6.55 18 6 18ZM19.5 9.5L21.46 12H17V9.5H19.5ZM18 18C17.45 18 17 17.55 17 17C17 16.45 17.45 16 18 16C18.55 16 19 16.45 19 17C19 17.55 18.55 18 18 18Z"
        fill="white"
      />
      <path
        d="M10 7V9H12C12.55 9 13 9.45 13 10C13 10.55 12.55 11 12 11H10V13C10 13.55 9.55 14 9 14C8.45 14 8 13.55 8 13V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H8V7C8 6.45 8.45 6 9 6C9.55 6 10 6.45 10 7Z"
        fill={color}
      />
    </svg>
  )
}

export default AddTruckIcon
