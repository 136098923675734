import { Button, Col, Row } from 'react-bootstrap'
import CardWithHeader from '../../../components/cardWithHeader/CardWithHeader'
import {
  useCompanyDetailQuery,
  useUploadCompanyImageMutation
} from '../../../api/network/userApiService'
import { COMPANY_TYPE } from '../../../utils/constants'
import PersonIconWithCircle from '../../../assets/images/svg/PersonIconWithCircle'
import UserInfoItem from '../../../components/userInfoItem/user-info-item'
import { localStorageKeys } from '../../../utils/constants'
import { ISettings } from '../types'
import React, { useEffect, useState } from 'react'
import { getBinaryFromFile } from '../../../utils/helper'
import { fetchFileUpload } from '../../../api/network/fetchFileUpload'
import {
  API_USER_URL_SUFFIX,
  BASE_URL,
  COMPANIES
} from '../../../api/network/ServerUrl'
import { UserResponse } from '../../../api/types/Driver'

const PageProfileDetail = (props: ISettings) => {
  const { setLoading } = props
  const user =
    localStorage.getItem(localStorageKeys.user) ||
    sessionStorage.getItem(localStorageKeys.user)
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null)

  const userId = JSON.parse(user ?? '{}').user.id
  const { data: companyDetail } = useCompanyDetailQuery(userId)

  const [updateCompanyImageApiTrigger, { isLoading }] =
    useUploadCompanyImageMutation()

  interface ISelectedFile {
    imagePreview: string
    imageAsFile: File
  }
  const [selectedFile, setSelectedFile] = useState<ISelectedFile>()

  const uploadCompanyImage = (image: any) => {
    if (!image) return
  }

  const carryOutCompanyImageUpload = () => {
    const payloadFormData = new FormData()
    console.log('carryOutCompanyImageUpload IMAGE', userId, selectedFile?.imageAsFile)
    if (selectedFile?.imageAsFile)
      payloadFormData.append(
        `user_profile.profile_photo`,
        selectedFile?.imageAsFile
      )
    fetchFileUpload({
      formData: payloadFormData,
      method: 'PATCH',
      url: `${BASE_URL}${API_USER_URL_SUFFIX}${COMPANIES}${userId}/`,
      successCallback: (data: UserResponse) => {
        console.log('[successCallback]', data)
        if(data){
let storedData = sessionStorage.getItem('accountInfo');
if(storedData!==null){
  const userData = JSON.parse(storedData);
  userData.user_profile.profile_photo = data?.user_profile
  ?.profile_photo;
  const updatedData = JSON.stringify(userData);
  sessionStorage.setItem('accountInfo', updatedData); 
}
        }
        setAvatarUrl(data?.user_profile?.profile_photo)
      }
    })
  }

  useEffect(() => {
    if(!!selectedFile) {
      carryOutCompanyImageUpload()
    }
  }, [selectedFile])

  const onUploadCompanyImageSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault()
    carryOutCompanyImageUpload()
  }

  const onCompanyImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFiles = event.target.files as FileList
    if (selectedFiles) {
      const uploadedFile = selectedFiles[0] as File
      console.log('uploadedFile', uploadedFile)
      // const uploadedFileBinary = await getBinaryFromFile(uploadedFile)
      // console.log('uploadedFileBinary', uploadedFileBinary)
      setSelectedFile({
        imagePreview: URL.createObjectURL(uploadedFile),
        imageAsFile: uploadedFile
      })
      // uploadCompanyImage(uploadedFile)
    }
  }

  return (
    <Row>
      <Col md={6}>
        <CardWithHeader title={'Company Details'}>
          <Row className="mt-2">
            <Col md={4} className="d-flex justify-content-center">
              {/* <PersonIconWithCircle /> */}
              <div
                className="avatar me-2"
                style={{
                  backgroundImage: `url(${
                    avatarUrl ??
                    companyDetail?.company?.user_profile?.profile_photo
                  })`
                }}
              ></div>
            </Col>
            <Col md={8} className='d-flex align-items-center'>
              <h4 className="fs-16 fw-semibold fc-blue m-0">
                {companyDetail?.company?.company_name}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <form onSubmit={onUploadCompanyImageSubmit}>
                <span className='file-upload-wrap mt-2'>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={onCompanyImageChange}
                  />
                </span>
                <button type="submit" className='d-none'>Submit</button>
              </form>
            </Col>
          </Row>
          <UserInfoItem
            title="Company Type"
            value={COMPANY_TYPE[companyDetail?.company?.company_type || 0]}
          />
          <UserInfoItem
            title="Contact Person"
            value={companyDetail?.company?.contact_person_name}
          />
          <UserInfoItem
            title="Email"
            value={companyDetail?.company?.user_profile?.email}
          />
          <UserInfoItem
            title="Phone Number"
            value={companyDetail?.company?.user_profile?.phone_number}
          />
        </CardWithHeader>
      </Col>
      <Col md={6}>
        <CardWithHeader title={'Address Details'}>
          <UserInfoItem title='Street Address' value={companyDetail?.company?.street_address} />
          <UserInfoItem title='City' value={companyDetail?.company?.city} />
          <UserInfoItem title='ZipCode' value={companyDetail?.company?.zip_code} />
          <UserInfoItem title='State' value={companyDetail?.company?.state} />
          <UserInfoItem title='Country' value={companyDetail?.company?.country} />
        </CardWithHeader>
      </Col>
    </Row>
  )
}

export default PageProfileDetail
