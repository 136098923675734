import { jobDetail } from '../../../api/types/Jobs'
import JobDocumentsTable, { IJobDocument } from './job-documents-table'

const JobDetailTrackingDocuments = ({
  jobDetail
}: {
  jobDetail: jobDetail
}) => {
  const document: IJobDocument = {
    proof_document: jobDetail?.job_details?.proof_document as string,
    updated_at: jobDetail?.job_details?.status_time?.proof_document
  }
  const documents: IJobDocument[] = [document]
  return (
    <>
      <h2 className="d-flex align-items-center justify-content-between mb-4 mt-5">
        <span>Delivery Documents</span>
      </h2>
      <JobDocumentsTable
        documents={documents}
        jobId={jobDetail?.job_details?.id}
        jobStatus={jobDetail?.job_details?.status}
      />
    </>
  )
}
export default JobDetailTrackingDocuments
