import React from "react"
import { Button } from "react-bootstrap"
import DashSidebarMenu from "../../utils/menus/menuSidebar"

interface Props {
  handleLogout: () => void;
}

const DashSidebar = (props : Props) => {
  return (
    <>
      <DashSidebarMenu />
      <div className="flex-grow-1"></div>
      <Button className="btn-logout my-4" onClick={props.handleLogout}>
        Sign out
      </Button>
    </>
  )
}

export default DashSidebar
