import { useEffect, useState } from 'react'
import { useLazyEmptyJobRescheduledQuery } from '../../api/network/analyticsApiService'
import Table from '../../components/table/table'
import {
  changeDateFormat,
  DateFormat,
  minutesToHoursFormat
} from '../../utils/DateUtils'
import { displayPickDropLocation } from '../../utils/RadarUtils'

export interface IAnalyticsEmptyJobRescheduledProps {
  companyId: number
  startDate: any
  endDate: any
  setAvgDuration?: any
}

const AnSecEmptyRescheduledJob = (
  props: IAnalyticsEmptyJobRescheduledProps
) => {
  const { companyId, startDate, endDate, setAvgDuration } = props
  const [page, setPage] = useState(1)

  const [
    emptyJobRescheduledAPITrigger,
    { data: dataEmptyJobRescheduled, isLoading, isError }
  ] = useLazyEmptyJobRescheduledQuery()
  const { next, previous, results, total_pages } = dataEmptyJobRescheduled || {}

  useEffect(() => {
    if(!!dataEmptyJobRescheduled && !isLoading) {
      setAvgDuration?.(dataEmptyJobRescheduled?.results?.average_duration)
    }
  }, [dataEmptyJobRescheduled, isLoading])

  useEffect(() => {
    {(startDate===null||endDate===null)?
    emptyJobRescheduledAPITrigger({ companyId, page }):
    emptyJobRescheduledAPITrigger({ companyId, page, from : startDate.toISOString().split("T")[0], to : endDate.toISOString().split("T")[0] })}
  }, [page, emptyJobRescheduledAPITrigger, companyId, startDate, endDate])

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }

  const tableColumns = [
    {
      Header: 'Job ID',
      accessor: (cell: any) => (
        <span className="text-break">{cell?.job_id}</span>
      )
    },
    {
      Header: 'Container Number',
      accessor: (cell: any) => (
        <span className="text-break">{cell?.container_number}</span>
      )
    },
    {
      Header: 'Customer',
      accessor: (cell: any) => (
        <span className="text-break">{cell?.company_name}</span>
      )
    },
    {
      Header: 'Delivery (Date/Time)',
      accessor: (cell: any) => (
        <span>
          {changeDateFormat(
            cell?.delivery_time,
            DateFormat.YYYYMMMDDHHMMSS,
            DateFormat.DDMMMYYYYHHMM
          )}
        </span>
      )
    },
    {
      Header: 'Location',
      accessor: (cell: any) => (
        <span className="text-break">
          {!!cell?.location_text
            ? displayPickDropLocation(cell?.location_text)
            : '-'}
        </span>
      )
    },
    {
      Header: 'Return Job Id',
      accessor: (cell: any) => (
        <span className="text-break">{cell?.return_job?.job_id}</span>
      )
    },
    {
      Header: 'Return Job Pick (Date/Time)',
      accessor: (cell: any) => (
        <span>
          {cell?.return_job?.return_job_pick
            ? changeDateFormat(
                cell?.return_job?.return_job_pick,
                DateFormat.YYYYMMMDDHHMMSS,
                DateFormat.DDMMMYYYYHHMM
              )
            : '-'}
        </span>
      )
    },
    {
      Header: 'Duration',
      accessor: (cell: any) => (
        <div className="text-break fc-blue fw-semibold text-right">
          {!!cell?.return_job?.duration || cell?.return_job?.duration === 0
            ? minutesToHoursFormat(cell?.return_job?.duration)
            : '--:--'}
          {' hrs'}
        </div>
      )
    }
  ]

  return (
    <Table
      data={results?.rescheduled_data || []}
      columns={tableColumns}
      page={page}
      setPage={setPage}
      onNext={onNext}
      onPrev={onPrev}
      pageCount={total_pages || 0}
      isLoading={isLoading}
      wrapperClassName={`table-wrap`}
    />
  )
}

export default AnSecEmptyRescheduledJob
