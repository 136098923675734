import { jobDetail } from '../../../api/types/Jobs'
import CardWithHeader from '../../../components/cardWithHeader/CardWithHeader'
import FormControl from '../../../components/formControl/FormControl'
import { formatAmount } from '../../../utils/NumberUtils'

const JobDetailFinancial = ({ jobDetail }: { jobDetail: jobDetail }) => {
  return (
    <CardWithHeader title={`Financial Details`}>
      <FormControl
        placeholder="Proposed Rate"
        value={formatAmount(
          parseFloat(
            jobDetail?.job_details?.financial_details?.proposed_rate as string
          )
        )}
        readOnly={true}
        showLabel={true}
      />
      <FormControl
        placeholder="Customer Priority"
        value={formatAmount(
          parseFloat(
            jobDetail?.job_details?.financial_details
              ?.customer_priority as string
          )
        )}
        readOnly={true}
        showLabel={true}
      />
      <div className="sub-total d-flex align-items-center justify-content-between mb-3 mt-4">
        <span className="flex-1">Sub Total</span>
        <span className="field-sub-total text-right not-w-50">
          {formatAmount(
            parseFloat(
              jobDetail?.job_details?.financial_details?.total_rate as string
            )
          )}
        </span>
      </div>
    </CardWithHeader>
  )
}
export default JobDetailFinancial
