import { Button, Modal } from 'react-bootstrap'
import { ISuspendReasons } from './types'
import FormSelect from '../../formSelect/FormSelect'
import { suspensionReasons } from '../../../utils/constants'
import { useState } from 'react'

const SuspensionReasonModal = (props: ISuspendReasons) => {
  const { show, handleClose, leftOnPress } = props
  const [reason, setReason] = useState('')

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Suspend User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormSelect
          placeholder="Select Suspend Reason"
          controlId="validateReason"
          onChange={e => setReason(e.target.value)}
        >
          {suspensionReasons?.map((ele: string) => {
            return <option value={ele}>{ele}</option>
          })}
        </FormSelect>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            leftOnPress(reason)
          }}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SuspensionReasonModal
