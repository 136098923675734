import { Navigate, Outlet } from "react-router-dom"
import { ROUTE_NAMES } from "../../utils/route/routerNames"
import { fetchUserData } from "../../utils/storage"

const LoginLayout = () => {
  const userData = fetchUserData()
  if (userData.token) {
    return <Navigate to={ROUTE_NAMES.dashboardChildren.users} />
  }
  return <Outlet />
}

export default LoginLayout
