import { Container, Form } from "react-bootstrap";
import { IDriverApplicantDetails } from "./types";
import { FormikProvider, useFormik } from "formik";
import CardWithHeader from "../../../../components/cardWithHeader/CardWithHeader";
import FormControl from "../../../../components/formControl/FormControl";
import { IApplicantDetails } from "../../../../api/types/Driver";
import './applicant-details.scss';


const ApplicantDetails = (props: IDriverApplicantDetails) => {

  const { applicantDetails } = props;

  const initialValues: IApplicantDetails = {
    date_of_birth: applicantDetails?.date_of_birth,
    date_available_for_work: applicantDetails?.date_available_for_work,
    legal_right: applicantDetails?.legal_right,
    license_number: applicantDetails?.license_number,
    truck_driver_type: applicantDetails?.truck_driver_type
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values: IApplicantDetails) => { }
  })

  return (
    <Container fluid className="driver-applicant-details">
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <CardWithHeader title={'Applicant Details'}>
            <div>
              <FormControl
                type="date"
                placeholder="Date of Birth"
                controlId="validateDOB"
                onChange={e => formik.setFieldValue('date_of_birth', e.target.value)}
                errorMessage={formik.errors.date_of_birth}
                value={formik.values.date_of_birth}
                isInvalid={!!formik.errors.date_of_birth}
                showLabel={true}
                readOnly
              />
              <FormControl
                placeholder="Social Security Number"
                controlId="validateSSN"
                onChange={e => formik.setFieldValue('license_number', e.target.value)}
                errorMessage={formik.errors.license_number}
                value={formik.values.license_number}
                isInvalid={!!formik.errors.license_number}
                showLabel={true}
                readOnly
              />
              <FormControl
                placeholder="Driver Type"
                controlId="validatedriverType"
                onChange={e => formik.setFieldValue('truck_driver_type', e.target.value)}
                errorMessage={formik.errors.truck_driver_type}
                value={formik.values.truck_driver_type}
                isInvalid={!!formik.errors.truck_driver_type}
                showLabel={true}
                readOnly
              />
              <FormControl
                type="date"
                placeholder="Date Available for Work"
                controlId="validateDateAvailableForWork"
                onChange={e => formik.setFieldValue('date_available_for_work', e.target.value)}
                errorMessage={formik.errors.date_available_for_work}
                value={formik.values.date_available_for_work}
                isInvalid={!!formik.errors.date_available_for_work}
                showLabel={true}
                readOnly
              />
              <div className="question">Do you have legal right to work in the United States?</div>
              <div className="answer">{applicantDetails?.legal_right ? 'Yes' : 'No'}</div>
            </div>
          </CardWithHeader>
        </Form>
      </FormikProvider>
    </Container>
  )
}

export default ApplicantDetails;