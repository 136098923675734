import React from 'react'
import {
  BarChart,
  Bar,
  CartesianGrid,
  Cell,
  Label,
  XAxis,
  YAxis
} from 'recharts'

type Datum = {
  name: string
  value: number
  fill?: string
}
type Props = {
  data: Datum[]
  height?: number
  width?: number
  yAxisLabel?: string
}

export default function AppBarChart(props: Props) {
  const { data, height = 200, width = 300, yAxisLabel } = props
  return (
    <div className="d-flex justify-content-center overflow-hidden">
      <BarChart
        width={width}
        height={height}
        data={data}
        margin={{
          top: 20,
          right: 0,
          left: 0,
          bottom: 0
        }}
        maxBarSize={30}
      >
        <CartesianGrid strokeDasharray="1 1" vertical={<></>} />
        <XAxis dataKey="name" fontSize={12} />
        <YAxis fontSize={12}>
          {!!yAxisLabel && (
            <Label
              value={yAxisLabel}
              position="inside"
              angle={-90}
              style={{ fontSize: 10, textAnchor: 'middle' }}
            />
          )}
        </YAxis>
        <Bar dataKey="value" fill="#8884d8" label={{ fontSize: 12, fontWeight: 600, position: 'top' }}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.fill} />
          ))}
        </Bar>
      </BarChart>
    </div>
  )
}
