import { useEffect, useState } from 'react'
import Table from '../../../components/table/table'
import { DateFormat, changeDateFormat } from '../../../utils/DateUtils'
import { Button, Image } from 'react-bootstrap'
import JobDocumentPreviewModal from '../../../components/modal/documentPreview/JobDocumentPreviewModal'
import { JOB_STATUS_NAMES } from '../../../utils/constants'
import {
  useJobDocAcceptMutation,
  useJobDocRejectMutation
} from '../../../api/network/jobsApiService'

export interface IJobDocument {
  proof_document: string
  updated_at?: string
}

export interface IJobDocumentsTableProps {
  documents: IJobDocument[]
  jobId: number
  jobStatus: number
}

const JOB_ACTION = {
  ACCEPT: 1,
  REJECT: 2
}

const JobDocumentsTable = (props: IJobDocumentsTableProps) => {
  const { documents, jobId, jobStatus } = props

  const [
    jobDocAcceptAPITrigger,
    { data: dataJobDocAccept, isLoading: isLoadingJobDocAccept }
  ] = useJobDocAcceptMutation()
  const [
    jobDocRejectAPITrigger,
    { data: dataJobDocReject, isLoading: isLoadingJobDocReject }
  ] = useJobDocRejectMutation()

  const handleClose = () => setShow(false)
  const [show, setShow] = useState(false)
  const [document, setDocument] = useState<IJobDocument>()
  const [image, setImage] = useState<string>('')

  const columns = [
    {
      Header: 'Document Type',
      accessor: (cell: any) => (
        <span className="document-type">Delivery Invoice</span>
      )
    },
    {
      Header: 'Uploaded File',
      accessor: (cell: any) => (
        <div className="d-flex flex-column files">
          <span className="filename">
            {cell.proof_document.substring(
              cell.proof_document.lastIndexOf('/') + 1,
              cell.proof_document.indexOf('?')
            )}
          </span>
        </div>
      )
    },
    {
      Header: 'Date of Upload',
      accessor: (cell: any) => (
        <span>
          {!!cell?.updated_at ? changeDateFormat(
            cell?.updated_at,
            DateFormat.YYYYMMMDDHHMMSS,
            DateFormat.DDMMMYYYY1
          ) : '-'}
        </span>
      )
    },
    {
      Header: 'Thumbnail',
      accessor: (cell: any) => (
        <div className="d-flex">
          <Image
            width={50}
            height={25}
            src={cell.proof_document}
            thumbnail
            onClick={() => {
              documentPreview(cell, cell.proof_document)
            }}
          />
        </div>
      )
    },
    {
      Header: 'Action',
      accessor: (cell: any) =>
        jobStatus === JOB_STATUS_NAMES.DOCUMENT_UPLOAD ? (
          <div className="d-flex">
            <Button
              className="me-2 flex-grow-1"
              variant="danger"
              onClick={() => {
                documentAction(cell, JOB_ACTION.REJECT)
              }}
            >
              Reject
            </Button>
            <Button
              className='flex-grow-1'
              variant="success"
              onClick={() => {
                documentAction(cell, JOB_ACTION.ACCEPT)
              }}
            >
              Accept
            </Button>
          </div>
        ) : (
          <span className="fc-blue">
            {jobStatus === JOB_STATUS_NAMES.DONE ? 'Approved' : ''}
            {jobStatus === JOB_STATUS_NAMES.DOCUMENT_REJECTED ? 'Rejected' : ''}
          </span>
        )
    }
  ]

  const documentAction = (document: IJobDocument, status: number) => {
    console.log('documentAction', document, status)

    switch(status) {
      case JOB_ACTION.ACCEPT:
        jobDocAcceptAPITrigger(jobId)
      break;
      case JOB_ACTION.REJECT:
      default:
        jobDocRejectAPITrigger(jobId)
      break;
    }
  }

  useEffect(() => {
    if(!!dataJobDocAccept && !isLoadingJobDocAccept) {
      console.log('accepted')
    }
    if(!!dataJobDocReject && !isLoadingJobDocReject) {
      console.log('rejected')
    }
  }, [dataJobDocAccept, isLoadingJobDocAccept, dataJobDocReject, isLoadingJobDocReject])

  const documentPreview = (document: IJobDocument, image: string) => {
    setDocument(document)
    setImage(image)
    setShow(true)
  }

  return (
    <>
      <Table data={documents} columns={columns} hidePagination={true} />
      <JobDocumentPreviewModal
        show={show}
        handleClose={handleClose}
        document={document}
        image={image}
      />
    </>
  )
}

export default JobDocumentsTable
