// Api URLs
export const BASE_URL = "https://api.radar.io/"

export const BASE_URL_SUFFIX = "v1/"

export const LOCATION_SEARCH = "search/autocomplete"

export const DISTANCE = "route/distance"

export const REVERSE_GEOCODE = "geocode/reverse?coordinates=[lat],[lng]"
