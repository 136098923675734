import { Button, Modal } from 'react-bootstrap'
import { ISuspendReasons } from './types'
import FormSelect from '../../formSelect/FormSelect'
import { suspensionReasons } from '../../../utils/constants'
import { useState } from 'react'
import "./documentRejectReason.scss";
import FormControl from '../../formControl/FormControl'
import AppStyledModal from '../appStyledModal/appStyledModal'

const DocumentRejectReasonModal = (props: ISuspendReasons) => {
  const { show, handleClose, onConfirm } = props
  const [reason, setReason] = useState('')

  return (
    <AppStyledModal
      closeButton={false}
      handleClose={handleClose}
      show={show}
      title="Reason for Rejection"
      leftOnPress={handleClose}
      leftButtonText={`Cancel`}
      rightOnPress={()=> onConfirm(reason)}
      rightButtonText={`Confirm`}
      rightButtonDisabled={reason.length === 0}
    >
      <div className='document-reject-subheading'>
        Please provide a reason for rejection the same will be share with the applicant.
      </div>
      <FormControl
        placeholder="Please specify reason here..."
        label='Reason for Rejection'
        showLabel={true}
        controlId="rejectionReason"
        className="form-control"
        as="textarea"
        onChange={e => setReason(e.target.value)}
        style={{ height: '120px' }}
      />

    </AppStyledModal>
  )
}

export default DocumentRejectReasonModal
