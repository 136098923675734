import { Col, ListGroup, Row } from 'react-bootstrap'
import './feedbackItem.scss'
import { FeedbackData } from '../../../../api/types/Feedbacks'
import { DateFormat, changeDateFormat } from '../../../../utils/DateUtils'
import { USER_TYPES } from '../../../../utils/constants'

interface Props {
  item: FeedbackData
  setter: (item: FeedbackData) => void
  selectedItem?: FeedbackData
}

const FeedbackItem = (props: Props) => {
  const { item, setter, selectedItem } = props
  const { user_email, user_name, created_at, user_type, profile_photo } = props.item
  return (
    <ListGroup.Item
      action
      onClick={() => setter(item)}
      className="feedback-item mt-3"
      active={selectedItem?.id === item.id}
    >
      <Row className="align-items-center d-flex flex-nowrap">
        <Col className="feedback-item_account d-flex">
          <div className="avatar me-2" style={{
            backgroundImage: `url(${profile_photo})`
          }}></div>
          <div className="account d-flex flex-column justify-content-center text-break">
            <div className="full-name">
              {user_name}
              <span className="user-role ms-2">
                {USER_TYPES[Number(user_type)]}
              </span>
            </div>
            <div className="email">{user_email}</div>
          </div>
        </Col>
        <Col className="p-0 date flex text-break">
          {changeDateFormat(
            created_at,
            DateFormat.YYYYMMMDDHHMMSSA,
            DateFormat.DDMMMYYYY
          )}
        </Col>
      </Row>
    </ListGroup.Item>
  )
}

export default FeedbackItem
