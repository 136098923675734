import React from 'react'
import { useLocation } from 'react-router-dom'
import { ROUTE_NAMES } from '../route/routerNames'

import './menuSidebar.scss'
import { fetchUserType } from '../storage'
import { userTypes } from '../constants'
import PersonAddIcon from '../../assets/images/svg/PersonAddIcon'
import PersonIcon from '../../assets/images/svg/PersonIcon'
import JobIcon from '../../assets/images/svg/JobIcon'
import FeedbackIcon from '../../assets/images/svg/FeedbackIcon'
import AnalyticsIcon from '../../assets/images/svg/AnalyticsIcon'
import AddJobIcon from '../../assets/images/svg/AddJobsIcon'
import SettingIcon from '../../assets/images/svg/SettingIcon'
import TruckIcon from '../../assets/images/svg/TruckIcon'
import AddTruckIcon from '../../assets/images/svg/AddTruck'

const DashSidebarMenu = () => {
  const location = useLocation()

  const navItemsAdmin = [
    {
      route: ROUTE_NAMES.dashboardChildren.addUsers,
      title: 'Add User',
      icon: <PersonAddIcon />
    },
    {
      route: ROUTE_NAMES.dashboardChildren.users,
      title: 'View Users',
      icon: <PersonIcon />
    },
    // {
    //   route: ROUTE_NAMES.dashboardChildren.addTruckDetails,
    //   title: 'Add Trucks',
    //   icon: <AddTruckIcon />
    // },
    {
      route: ROUTE_NAMES.dashboardChildren.truckList,
      title: 'View Trucks',
      icon: <TruckIcon />
    },
    {
      route: ROUTE_NAMES.dashboardChildren.jobs,
      title: 'View Jobs',
      icon: <JobIcon />
    },
    {
      route: ROUTE_NAMES.dashboardChildren.feedback,
      title: 'Feedback',
      icon: <FeedbackIcon />
    },
    {
      route: ROUTE_NAMES.dashboardChildren.analytics,
      title: 'Analytics',
      icon: <AnalyticsIcon />
    }
  ]

  const navItemsCompany = [
    {
      route: ROUTE_NAMES.dashboardChildren.addUsers,
      title: 'Add Drivers',
      icon: <PersonAddIcon />
    },
    {
      route: ROUTE_NAMES.dashboardChildren.users,
      title: 'View Drivers',
      icon: <PersonIcon />
    },
    {
      route: ROUTE_NAMES.dashboardChildren.addTruckDetails,
      title: 'Add Trucks',
      icon: <AddTruckIcon />
    },
    {
      route: ROUTE_NAMES.dashboardChildren.truckList,
      title: 'View Trucks',
      icon: <TruckIcon />
    },
    {
      route: ROUTE_NAMES.dashboardChildren.addJobs,
      title: 'Add Job',
      icon: <AddJobIcon />
    },
    {
      route: ROUTE_NAMES.dashboardChildren.jobs,
      title: 'View Jobs',
      icon: <JobIcon />
    },
    {
      route: ROUTE_NAMES.dashboardChildren.settings,
      title: 'Settings',
      icon: <SettingIcon />
    }
  ]

  const navItems =
    fetchUserType() === userTypes.ADMIN ? navItemsAdmin : navItemsCompany

  return (
    <nav className="d-flex flex-column dash-sidebar-menu">
      {navItems.map((navItem, i) => (
        <a
          href={navItem.route}
          key={i}
          style={{ marginBottom: '-5px' }}
          className={location?.pathname.includes(navItem.route) ? 'active' : ''}
        >
          <span className="me-2">{navItem.icon}</span>
          {navItem?.title}
        </a>
      ))}
    </nav>
  )
}

export default DashSidebarMenu
