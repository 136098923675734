import React from 'react'
import { Filter_Obj } from '../components/formFilter/types'
import { localStorageKeys } from './constants'
import { ROUTE_NAMES } from './route/routerNames'

export const getStatusFilterKeys = (
  statusFilter: Filter_Obj[],
  key: string
) => {
  const selectedStatusFilterObj = statusFilter.find(
    f => f.id.toLowerCase() === key
  )
  if (selectedStatusFilterObj) {
    let value = selectedStatusFilterObj.value.map((f: { id: number }) =>
      Number(f.id)
    )
    return value
  } else {
    return undefined
  }
}

export const sideEffectLogOut = (redirect = true) => {
  localStorage.removeItem(localStorageKeys.user)
  sessionStorage.removeItem(localStorageKeys.user)
  sessionStorage.removeItem(localStorageKeys.accountInfo)
  if(redirect)
    window.location.href = ROUTE_NAMES.landingPage
}

export const getBinaryFromFile = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result))
    reader.addEventListener('error', err => reject(err))
    reader.readAsBinaryString(file)
  })
