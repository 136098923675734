import { useNavigate } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'
import DetailHeader from './header/header'
// import DetailHeader from '../driver-detail/header/header'
import './job-detail.scss'
import {
  useDriverLocationQuery,
  useJobDetailQuery,
  usePutJobMutation
} from '../../../api/network/jobsApiService'
import Loader from '../../../components/loader/Loader'
import { useParams } from 'react-router-dom'
import JobDetailBasic from './job-detail-basic'
import { jobDetail } from '../../../api/types/Jobs'
import JobDetailFinancial from './job-detail-financial'
import JobDetailAcceptance from './job-detail-acceptance'
import JobDetailTracking from './job-detail-tracking'
import {
  JOB_CONFIG,
  JOB_STATUS_NAMES,
  userTypes
} from '../../../utils/constants'
import JobDetailTrackingDocuments from './job-detail-tracking-documents'
import { fetchUserType } from '../../../utils/storage'
import { useEffect, useState } from 'react'
import { ROUTE_NAMES } from '../../../utils/route/routerNames'
import { useEffectOnce } from 'usehooks-ts'
import { DriverLocationQuery } from '../../../api/types/Driver'
// import DetailHeader from '../../dashboard/driver-detail/header/header'

const PageJobDetail = () => {
  const DRIVER_LOCATION_API_CALL = 1000 * 60
  let driverLocationInterval: NodeJS.Timeout

  const navigate = useNavigate()
  const { jobId }: { jobId?: string } = useParams()
  const [isProcessing, setIsProcessing] = useState(false)

  const { data: jobDetail, isLoading } = useJobDetailQuery(jobId || '1')
  const [putJobApiTrigger, { isLoading: isLoadingPutJobApi }] =
    usePutJobMutation()

  const driverLocationPayload: DriverLocationQuery = {
    job: jobDetail?.job_details.id || 0,
    driver: jobDetail?.job_details?.driver_location?.driver || 0
  }

  const callDriverLocationAPI =
    JOB_CONFIG.showTrackingForStatuses.includes(
      jobDetail?.job_details?.status as number
    ) && driverLocationPayload?.driver

  const { data: driverLocation, refetch } = useDriverLocationQuery(
    driverLocationPayload,
    { skip: !callDriverLocationAPI }
  )

  useEffect(() => {
    if (callDriverLocationAPI) {
      driverLocationInterval = setInterval(() => {
        refetch && refetch()
      }, DRIVER_LOCATION_API_CALL)
    }
    return () => driverLocationInterval && clearInterval(driverLocationInterval)
  }, [callDriverLocationAPI])

  return (
    <Container fluid>
      {(isLoading || isLoadingPutJobApi || isProcessing) && <Loader />}
      <Row>
        <Col>
          <DetailHeader
            title="Job Details"
            redText={
              !!jobDetail?.job_details?.view_count
                ? `Total Views: ${jobDetail?.job_details?.view_count}`
                : ''
            }
            {...(jobDetail?.job_details?.status === JOB_STATUS_NAMES.OPEN &&
            fetchUserType() === userTypes.COMPANY &&
            JOB_CONFIG.editableForStatuses.indexOf(
              jobDetail?.job_details?.status ?? 1
            ) > -1
              ? {
                  ctaText: 'Edit',
                  ctaOnClick: () => {
                    navigate(
                      `${ROUTE_NAMES.dashboardChildren.editJob.replace(
                        ':jobId',
                        `${jobDetail?.job_details?.id}`
                      )}`
                    )
                  }
                }
              : {})}
          />
        </Col>
      </Row>

      {!isLoading && (
        <Row>
          <Col lg={4} md={6} className="not-card not-entity-detail-card">
            <JobDetailBasic
              jobId={`${jobId}`}
              jobDetail={jobDetail as jobDetail}
              putJobApiTrigger={putJobApiTrigger}
            />
          </Col>

          {JOB_CONFIG.showTrackingForStatuses.includes(
            jobDetail?.job_details?.status as number
          ) ? (
            <Col lg={8} md={6} className="job-detail-section__tracking">
              <JobDetailTracking
                driverLocation={driverLocation}
                jobDetail={jobDetail as jobDetail}
                setIsProcessing={setIsProcessing}
              />
              {fetchUserType() === userTypes.ADMIN &&
                !!jobDetail?.job_details?.proof_document && (
                  <JobDetailTrackingDocuments
                    jobDetail={jobDetail as jobDetail}
                  />
                )}
            </Col>
          ) : (
            <>
              <Col lg={4} md={6} className="job-detail-section__financial">
                <JobDetailFinancial jobDetail={jobDetail as jobDetail} />
              </Col>

              {!!jobDetail?.job_details?.assigned_driver && (
                <Col lg={4} md={6} className="job-detail-section__acceptance">
                  <JobDetailAcceptance jobDetail={jobDetail as jobDetail} />
                </Col>
              )}
            </>
          )}

          {/* <Col xs={6} className="problem-section">
          <JobDetailProblem jobDetail={jobDetail} />
        </Col> */}
        </Row>
      )}
    </Container>
  )
}

export default PageJobDetail
