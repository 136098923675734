import React, { useState } from 'react'
import { UserResponse } from '../../../api/types/Driver'
import { DriverLocation, FinancialDetails, jobDetail } from '../../../api/types/Jobs'
import { APPCONFIG } from '../../../app-config'
import ProblemIcon from '../../../assets/images/svg/Problem'
import RadarMap from '../../../components/radarMap/radarMap'
import { JOB_STATUS_NAMES, userTypes } from '../../../utils/constants'
import { fetchUserType } from '../../../utils/storage'
import JobDetailTrackingDriver from './job-detail-tracking-driver'
import JobDetailTrackingLocation from './job-detail-tracking-location'
import JobDetailTrackingPayout from './job-detail-tracking-payout'
import JobDetailTrackingReAssign from './job-detail-tracking-reassign'
import TomTomMap from '../../../components/tomtomMap/tomtomMap'
import JobDetailTruckAssign from './job-detail-truck-assign'

const JobDetailTracking = ({
  jobDetail,
  setIsProcessing,
  driverLocation
}: {
  jobDetail: jobDetail
  setIsProcessing: React.Dispatch<React.SetStateAction<boolean>>,
  driverLocation:DriverLocation[] | undefined
}) => {
  return (
    <>
      <h2 className="d-flex align-items-center justify-content-between mb-4">
        <span>Tracking Details</span>
        {jobDetail?.job_details?.status === JOB_STATUS_NAMES.DUMPED &&
        fetchUserType() === userTypes.ADMIN ? (
          <JobDetailTrackingReAssign
            jobDetail={jobDetail}
            setIsProcessing={setIsProcessing}
          />
        ) : (
          jobDetail?.job_details?.status === JOB_STATUS_NAMES.REQUESTED_TRUCK && fetchUserType() === userTypes.COMPANY ?
            <JobDetailTruckAssign
              jobDetail={jobDetail}
              setIsProcessing={setIsProcessing}
              />
            :
          <ProblemIcon
            color={
              jobDetail?.problem_reported?.length > 0
                ? APPCONFIG.COLORS.error
                : APPCONFIG.COLORS.diablo
            }
          />
        )}
      </h2>

      <TomTomMap
        mapContainer="radar-map-job-status"
        mapId="radar-map-job-status"
        startMarker={{
          title: 'Start Location',
          lat: parseFloat(
            jobDetail?.job_details?.pickdrop_details?.starting_location_latitude
          ),
          lng: parseFloat(
            jobDetail?.job_details?.pickdrop_details
              ?.starting_location_longitude
          ),
          color:'#1bbf00'
        }}
        endMarker={{
          title: 'End Location',
          lat: parseFloat(
            jobDetail?.job_details?.pickdrop_details?.ending_location_latitude
          ),
          lng: parseFloat(
            jobDetail?.job_details?.pickdrop_details?.ending_location_longitude
          ),
          color: '#f80000'
        }}
        {...(!!jobDetail?.job_details?.driver_location
          ? {
              marker: {
                title: 'Current Location',
                lat: parseFloat(
                 driverLocation?.[
                    driverLocation.length -1
                  ]?.latitude || 
                  jobDetail?.job_details?.driver_location?.latitude
                ),
                lng: parseFloat(
                 driverLocation?.[
                    driverLocation.length -1
                  ]?.longitude ||
                  jobDetail?.job_details?.driver_location?.longitude
                ),
                color:'#f1e05a'
              }
            }
          : {})}
        height="400px"
        lineWidth={8}
        lineColor='#0041bf'
        zoom={14}
        pointToCurrentLocationOnChange={true}
      />

      <div className="d-flex justify-content-between mt-4">
        <JobDetailTrackingDriver
          jobDriver={jobDetail?.job_details?.assigned_driver as UserResponse}
        />
        <JobDetailTrackingPayout
          jobFinancialDetails={
            jobDetail?.job_details?.financial_details as FinancialDetails
          }
        />
      </div>

      <div className="d-flex justify-content-between job-detail__tracking--location">
        <JobDetailTrackingLocation
          pickDropDetails={jobDetail?.job_details?.pickdrop_details}
          showStart={true}
        />
        <div className="bar"></div>
        <JobDetailTrackingLocation
          pickDropDetails={jobDetail?.job_details?.pickdrop_details}
          showStart={false}
        />
      </div>
    </>
  )
}
export default JobDetailTracking
