import React, { SVGProps } from "react"

const DocUpload = (props: SVGProps<SVGSVGElement>) => {
  const { color = "#FFF" } = props
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 16.5293C1.1 16.5293 0.75 16.3793 0.45 16.0793C0.15 15.7793 0 15.4293 0 15.0293V11.4543H1.5V15.0293H14.5V11.4543H16V15.0293C16 15.4293 15.85 15.7793 15.55 16.0793C15.25 16.3793 14.9 16.5293 14.5 16.5293H1.5ZM7.25 12.7043V3.4293L4.25 6.4293L3.175 5.3543L8 0.529297L12.825 5.3543L11.75 6.4293L8.75 3.4293V12.7043H7.25Z" fill="#444444"/>
    </svg>
    
  )
}

export default DocUpload
