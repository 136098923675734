const PrivacyPolicy = () => {

    return (
        <div style={{ paddingLeft: '5%', paddingRight:"5%", paddingTop: "5%"}}>
            <h1 style={{ paddingTop: '3pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'center' }}>
                <b>MOBILE APPLICATION PRIVACY POLICY</b>
            </h1>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>Effective as of November 29, 2023</p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
                This privacy policy (the “Privacy Policy”) applies to the mobile application (the “App”), owned and operated by Waterfront Transport LLC (“Waterfront,” “we,” “us,” or “our”). We have created this Privacy Policy to tell you what information the App collects, how we use that information, and who we will share that information with, if at all. This Privacy Policy does not address the privacy practices of any third parties that we do not own, control, or are affiliated with. Capitalized terms not defined in this Privacy Policy will have the meaning set forth in our Terms of Use. By visiting and/or using our App, you are agreeing to the terms of this Privacy Policy and the accompanying Terms of Use. We encourage you to read the Privacy Policy, and to use the information it contains to help you make informed decisions.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
            <ol type="1" id="l1">
                <li data-list-text="1.">
                    <h2 style={{ paddingLeft: '23pt', textIndent: '-18pt', textAlign: 'left' }}>
                        Information We Collect or Receive.
                    </h2>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    <p style={{ paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>
                        In the course of operating the App, we will collect and/or receive the following types of information. You authorize us to collect and/or receive such information.
                    </p>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    <ol type="a" id="l2">
                        <li data-list-text="(a)">
                            <p className="s1" style={{ paddingLeft: '5pt', textAlign: 'left' }}>
                                <u><b>Personal Information</b></u>
                                <span className="p">
                                    . We only receive or collect information that identifies you personally if you choose to provide such personally identifiable information to us via email or other means. When you sign up to become a user or contact us, you will be required to provide us with personal information about yourself
                                </span>
                            </p>
                            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
                                (collectively, the “Personal Information”). Such Personal Information may include your name, e-mail address, physical address, and phone number. We do not collect any Personal Information from you when you use the App unless you provide us with the Personal Information voluntarily.
                            </p>
                            <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        </li>
                        <li data-list-text="(b)">
                            <p className="s1" style={{ paddingLeft: '5pt', textAlign: 'left' }}>
                                <u><b>Third-party Log In</b></u>
                                <span className="h2">. </span>
                                <span className="p">If you sign in through Waterfront, you are authorizing us to collect, store, and use, in accordance with this Privacy Policy, any and all information that you agreed that Waterfront would provide to us through Waterfront’s Application Programming Interface (“API”). Such information may include, without limitation, your first and last name, Waterfront username, Waterfront profile picture, headline, unique identifier and access token, and email address.</span>
                            </p>
                            <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        </li>
                        <li data-list-text="(c)">
                            <p className="s1" style={{ paddingLeft: '5pt', textAlign: 'left' }}>
                                <u><b>Payment Information</b></u>
                                <span className="h2">. </span>
                                <span className="p">If you choose to make a purchase or subscribe to a feature or service ours that requires a fee, you will be required to provide us with your payment information, including, without limitation, bank account numbers, credit card or debit card numbers, account details, ACH information, and similar data (collectively, “Payment Information”). Such Payment Information will be collected and processed by our third-party payment vendors pursuant to the</span>
                            </p>
                            <p style={{ paddingTop: '3pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
                                terms and conditions of their privacy policies and terms of use, and we do not obtain access to any Payment Information in connection with such purchases or subscriptions.
                            </p>
                            <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        </li>
                        <li data-list-text="(d)">
                            <p className="s1" style={{ paddingLeft: '5pt', textAlign: 'left' }}>
                                <u><b>Geolocational Information</b></u>
                                <span className="h2">. </span>
                                <span className="p">Certain features and functionalities of the App are based on your location. In order to provide these features and functionalities while you are using your mobile device, we may, with your consent, automatically collect geolocational information from your mobile device or wireless carrier and/or certain third-party service providers (collectively,</span>
                            </p>
                            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
                                “Geolocational Information”). Collection of such Geolocational Information occurs only when the App is running on your device. You may decline to allow us to collect such Geolocational Information, in which case we will not be able to provide certain features or functionalities to you.
                            </p>
                            <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        </li>
                        <li data-list-text="(e)">
                            <p style={{ paddingLeft: '5pt', textAlign: 'left' }}><u><b>Third-Party
                                Analytics</b></u>. We and the third-party technology providers, ad exchanges, ad
                                networks, advertisers, agencies, ad exchanges, and ad servers with which we work use third-party
                                analytics services (e.g., Google Analytics) to evaluate your use of the App, compile reports on
                                activity, collect demographic data, analyze performance metrics, and collect and evaluate other
                                information relating to the App and mobile and Internet usage. These third parties use cookies
                                and other technologies to help analyze and provide us the data. You consent to the processing of
                                data about you by these analytics providers in the manner and for the purposes set out in this
                                Privacy Policy. For more information on these third parties, including how to opt out from
                                certain data collection, please visit our website at [<b>https://waterfronttransport.com</b>].
                                Please be advised that if you opt out of any such service, you may not be able to use the full
                                functionality of the App.</p>
                            <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        </li>
                        <li data-list-text="(f)">
                            <p className="s1" style={{ paddingLeft: '5pt', textAlign: 'left' }}><u><b>Other Information</b></u> In addition to the Personal Information, Payment
                                Information, and Geolocational Information, we may automatically collect or receive
                                additional information regarding you and your use of the App; your interactions with us and
                                our advertising; and information regarding your computer and mobile devices used to
                                access the App (collectively, the “Other Information”). Such Other Information may include:</p>
                            <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                            <ol type="i" id="l3">
                                <li data-list-text="(i)">
                                    <p className="s1" style={{ paddingLeft: '5pt', textAlign: 'left' }}><u><b>From You</b></u><span
                                        className="p">. Additional information about yourself that you voluntarily provide to
                                        us, such as your gender and your product and service preferences.</span></p>
                                </li>
                                <li data-list-text="(ii)">
                                    <p className="s1" style={{ paddingLeft: '5pt', textAlign: 'left' }}>
                                        <u><b>From Your Activity</b></u>
                                        <span className="h2">. </span><span className="p">We may collect or receive information
                                            regarding:</span></p>
                                    <ol type="A" id="l4">
                                        <li data-list-text="(A)">
                                            <p
                                                style={{ paddingTop: '3pt', paddingLeft: '5pt', textAlign: 'left' }}>
                                                IP address, which may consist of a static or dynamic IP address and will
                                                sometimes point to a specific identifiable computer or mobile device;</p>
                                        </li>
                                        <li data-list-text="(B)">
                                            <p style={{ paddingLeft: '5pt', textAlign: 'left' }}>browser type and
                                                language;</p>
                                        </li>
                                        <li data-list-text="(C)">
                                            <p style={{ paddingLeft: '5pt', textAlign: 'left' }}>referring and
                                                exit pages and https://waterfronttransport.com/;</p>
                                        </li>
                                        <li data-list-text="(D)">
                                            <p style={{ paddingLeft: '5pt', textAlign: 'left' }}>date and time;</p>
                                        </li>
                                        <li data-list-text="(E)">
                                            <p style={{ paddingLeft: '5pt', textAlign: 'left' }}>details regarding your activity on the App, such as search queries and other performance and usage data.</p>
                                        </li>

                                    </ol>
                                </li>
                                <li data-list-text="(iii)">
                                    <p className="s1" style={{ paddingLeft: '5pt', textAlign: 'left' }}>
                                        <u><b>About Your Mobile Device</b></u>
                                        <span className="h2">. </span>
                                        <span className="p">We may collect or receive information regarding:</span>
                                    </p>
                                    <ol type="A" id="l5">
                                        <li data-list-text="(A)">
                                            <p style={{ paddingLeft: '5pt', textAlign: 'left' }}>type of mobile device;</p>
                                        </li>
                                        <li data-list-text="(B)">
                                            <p style={{ paddingLeft: '5pt', textAlign: 'left' }}>advertising Identifier (“IDFA” or “AdID”);</p>
                                        </li>
                                        <li data-list-text="(C)">
                                            <p style={{ paddingLeft: '5pt', textAlign: 'left' }}>operating system and version (e.g., iOS, Android or Windows);</p>
                                        </li>
                                        <li data-list-text="(D)">
                                            <p style={{ paddingLeft: '5pt', textAlign: 'left' }}>carrier; and</p>
                                        </li>
                                        <li data-list-text="(E)">
                                            <p style={{ paddingLeft: '5pt', textAlign: 'left' }}>network type (WiFi, 3G, 4G, LTE).</p>
                                            <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                                        </li>
                                    </ol>

                                </li>
                                <li data-list-text="(iv)">
                                    <p className="s1" style={{ paddingLeft: '5pt', textAlign: 'left' }}>
                                        <u><b>From Cookies</b></u>
                                        <span className="p">. We may use both session cookies, which expire once you close
                                            the App, and persistent cookies, which stay on your mobile device until you delete
                                            them and other technologies to help us collect data and to enhance your experience
                                            with the App. Cookies are small text files an app can use to recognize a repeat
                                            visitor to the app. We may use cookies for various purposes, including to:</span>
                                    </p>
                                    <ol type="A" id="l6">
                                        <li data-list-text="(A)">
                                            <p style={{ paddingLeft: '5pt', textAlign: 'left' }}>type of mobile device;</p>
                                        </li>
                                        <li data-list-text="(B)">
                                            <p style={{ paddingLeft: '5pt', textAlign: 'left' }}>personalize your experience;</p>
                                        </li>
                                        <li data-list-text="(C)">
                                            <p style={{ paddingLeft: '5pt', textAlign: 'left' }}>analyze which portions of the App are visited and used most frequently; and</p>
                                        </li>
                                        <li data-list-text="(D)">
                                            <p style={{ paddingLeft: '5pt', textAlign: 'left' }}>measure and optimize advertising and promotional effectiveness.</p>
                                            <p style={{ paddingTop: '3pt', paddingLeft: '37pt', textIndent: '0pt', textAlign: 'left' }}>
                                                If you do not want us to deploy cookies in the App, you can opt out by setting your mobile device to reject cookies. You can still use the App if you choose to disable cookies, although your ability to use some of the features may be affected.
                                            </p>
                                            <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>

                                        </li>
                                    </ol>

                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li data-list-text="2.">
                    <h2 style={{ paddingLeft: '23pt', textIndent: '-18pt', lineHeight: '15pt', textAlign: 'left' }}>
                        Information Collected by or Through Third-Party Advertising Companies.
                    </h2>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    <p style={{ paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>
                        We may share Other Information about your activity on the App with third parties for ad distribution and ad optimization (defined as the tailoring, targeting (i.e., behavioral, contextual, retargeting, analyzing, managing, reporting, and optimizing of ads). These third parties may use cookies, pixel tags (also called web beacons or clear gifs), and/or other technologies to collect Other Information for such purposes. Pixel tags enable us and these third-party advertising companies to recognize a browser’s cookie when a browser visits the site on which the pixel tag is located in order to learn which advertisement brings a user to a given site. In addition, we may receive Other Information from advertisers and/or their service providers such as advertising identifiers, IP addresses, and post-conversion data.
                    </p>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                </li>
                <li data-list-text="3.">
                    <h2 style={{ paddingLeft: '23pt', textIndent: '-18pt', textAlign: 'left' }}>
                        How to Opt Out of Third-Party Interest-Based Advertising.
                    </h2>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    <p style={{ paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>
                        If you wish to opt out of third-party interest-based advertising, please visit
                        <a href="http://www.networkadvertising.org/" className="a" target="_blank">http://www.networkadvertising.org</a> and <a href="http://www.aboutads.info/choices" className="a" target="_blank">http://www.aboutads.info/choices</a> for details on how to do so. Additionally, users of mobile Devices can follow the below instructions:
                    </p>
                    <p className="s2" style={{ paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}><u>Android Users</u></p>
                    <p style={{ paddingTop: '4pt', paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>
                        For Android devices with OS 2.2 and up and Google Play Services version 4.0 and up: Open your Google Settings app &gt; Ads &gt; Enable “Opt out of interest-based advertising.
                    </p>
                    <p style={{ paddingLeft: '23pt', textIndent: '0pt', lineHeight: '200%', textAlign: 'left' }}>
                        <u>iOS Users</u>
                    </p>
                    <p style={{ paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>
                        iOS 7 or Higher: Go to your Settings &gt; Select Privacy &gt; Select Advertising &gt; Enable the “Limit Ad Tracking” setting.
                    </p>
                    <p style={{ paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>
                        iOS 6: Go to your Settings &gt; Select General &gt; Select About &gt; Select Advertising &gt; Enable the “Limit Ads Tracking” setting.
                    </p>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                </li>

                <li data-list-text="4.">
                    <h2 style={{ paddingLeft: '23pt', textIndent: '-18pt', textAlign: 'left' }}>How Information is Used and Shared.</h2>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>

                    <ol type="a" id="l7">
                        <li data-list-text="(a)">
                            <p style={{ paddingLeft: '5pt', textAlign: 'left' }}>
                                You authorize us to use the Personal Information, Payment Information, Geolocational Information, and the Other Information (collectively, the “Information”) to:
                            </p>
                            <ol type="i" id="l8">
                                <li data-list-text="(i)">
                                    <p style={{ paddingTop: '3pt', paddingLeft: '37pt', textIndent: '-25pt', textAlign: 'left' }}>provide and improve our App;</p>
                                </li>
                                <li data-list-text="(ii)">
                                    <p style={{ paddingLeft: '37pt', textIndent: '-25pt', textAlign: 'left' }}>provide our services;</p>
                                </li>
                                <li data-list-text="(iii)">
                                    <p style={{ paddingLeft: '37pt', textIndent: '-25pt', textAlign: 'left' }}>administer our promotional programs;</p>
                                </li>
                                <li data-list-text="(iv)">
                                    <p style={{ paddingLeft: '37pt', textIndent: '-25pt', textAlign: 'left' }}>solicit your feedback; and</p>
                                </li>
                                <li data-list-text="(v)">
                                    <p style={{ paddingLeft: '37pt', textIndent: '-25pt', textAlign: 'left' }}>inform you about our products and services.</p>
                                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                                </li>
                            </ol>

                        </li>
                        <li data-list-text="(b)">
                            <p style={{ paddingLeft: '11pt', textAlign: 'left' }}>
                                In order to provide our services and administer our promotional programs, we may share the Information with our third-party promotional and marketing partners, including, without limitation, businesses participating in our various programs.
                            </p>
                            <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        </li>
                        <li data-list-text="(c)">
                            <p style={{ paddingLeft: '11pt', textAlign: 'left' }}>
                                We engage third-party companies and individuals to perform functions on our behalf. Examples may include providing technical assistance, customer service, marketing assistance, and administration of promotional programs. These other companies will have access to the Information only as necessary to perform their functions and to the extent permitted by law.
                            </p>
                            <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        </li>
                        <li data-list-text="(d)">
                            <p style={{ paddingLeft: '11pt', textAlign: 'left' }}>
                                In an ongoing effort to better understand our users, the App, and our products and services, we may analyze certain Information in anonymized and aggregate form to operate, maintain, manage, and improve the App and/or such products and services. This aggregate information does not identify you personally. We may share and/or license this aggregate data to our affiliates, agents, business and promotional partners, and other third parties. We may also disclose aggregated user statistics to describe the App and these products and services to current and prospective business partners and investors and to other third parties for other lawful purposes.
                            </p>
                            <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        </li>

                        <li data-list-text="(e)">
                            <p style={{ paddingLeft: '11pt', textAlign: 'left' }}>
                                We may share some or all of your Information with any of our parent companies, subsidiaries, joint ventures, or other companies under common control with us.
                            </p>
                            <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        </li>
                        <li data-list-text="(f)">
                            <p style={{ paddingLeft: '11pt', textAlign: 'left' }}>
                                As we develop our businesses, we might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, sale of assets, dissolution, or similar event, the Information may be part of the transferred assets.
                            </p>
                            <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        </li>
                        <li data-list-text="(g)">
                            <p style={{ paddingLeft: '11pt', textAlign: 'left' }}>
                                To the extent permitted by law, we may also disclose the Information:
                            </p>
                            <ol type="i" id="l9">
                                <li data-list-text="(i)">
                                    <p style={{ paddingTop: '3pt', paddingLeft: '17pt', textAlign: 'left' }}>when required by law, court order, or other government or law enforcement authority or regulatory agency; or</p>
                                </li>
                                <li data-list-text="(ii)">
                                    <p style={{ paddingLeft: '17pt', textAlign: 'left' }}>whenever we believe that disclosing such Information is necessary or advisable, for example, to protect the rights, property, or safety of us or others, including you.</p>
                                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                                </li>
                            </ol>
                        </li>

                    </ol>
                </li>
                <li data-list-text="5.">
                    <h2 style={{ paddingLeft: '23pt', textIndent: '-18pt', textAlign: 'left' }}>Accessing and Modifying Information and Communication Preferences.</h2>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    <p style={{ paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>If you have provided us any Personal Information, you may access, remove, review, and/or make changes to the same by contacting us as set forth below. In addition, you may manage your receipt of marketing and non-transactional communications by</p>
                    <p style={{ paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>clicking on the “unsubscribe” link located on the bottom of any of our marketing emails. We will use commercially reasonable efforts to process such requests in a timely manner. You should be aware, however, that it is not always possible to completely remove or modify information in our subscription databases. You cannot opt out of receiving transactional e-mails related to the App (e.g., requests for support).</p>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    <p style={{ paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>We may also deliver notifications to your mobile device (e.g., push notifications). You can disable these notifications by deleting the relevant service or by changing the settings on your mobile device.</p>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                </li>
                <li data-list-text="6.">
                    <h2 style={{ paddingLeft: '23pt', textIndent: '-18pt', textAlign: 'left' }}>How We Protect Your Information.</h2>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    <p style={{ paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>We take commercially reasonable steps to protect the Information from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. Please understand, however, that no security system is impenetrable. We cannot guarantee the security of our databases or the databases of the third parties with which we may share such Information, nor can we guarantee that the Information you supply will not be intercepted while being transmitted over the Internet. In particular, e-mail sent to us may not be secure, and you should therefore take special care in deciding what information you send to us via e-mail.</p>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                </li>

                <li data-list-text="7.">
                    <h2 style={{ paddingLeft: '23pt', textIndent: '-18pt', textAlign: 'left' }}>Important Notices to Non-U.S. Residents.</h2>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    <p style={{ paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>The App and its servers are operated in the
                        United States. If you are located outside of the United States, please be aware that your Information,
                        including your Personal Information, may be transferred to, processed, maintained, and used on
                        computers, servers, and systems located outside of your state, province, country, or other governmental
                        jurisdiction where the privacy laws may not be as protective as those in your jurisdiction. If you are
                        located outside the United States and choose to use the</p>
                    <p style={{ paddingTop: '3pt', paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>App, you hereby irrevocably and unconditionally consent to such transfer, processing, and use in the United States and elsewhere.</p>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>

                </li>
                <li data-list-text="8.">
                    <h2 style={{ paddingLeft: '23pt', textIndent: '-18pt', textAlign: 'left' }}>App Stores; External Websites.</h2>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    <p style={{ paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>Your app store (e.g., iTunes or Google Play) may collect certain information in connection with your use of the App, such as Personal Information, Payment Information, Geolocational Information, and other usage-based data. We have no control over the collection of such information by a third-party app store, and any such collection or use will be subject to that third party’s applicable privacy policies.</p>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    <p style={{ paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>The App may contain links to third-party websites. We have no control over the privacy practices or the content of these websites. As such, we are not responsible for the content or the privacy policies of those third-party websites. You should check the applicable third-party privacy policy and terms of use when visiting any other websites.</p>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                </li>
                <li data-list-text="9.">
                    <h2 style={{ paddingLeft: '23pt', textIndent: '-18pt', textAlign: 'left' }}>Children.</h2>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    <p style={{ paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>The App is not directed to children under the age of 13. We adhere to the Children’s Online Privacy Protection Act (COPPA) and will not knowingly collect Personal Information from any child under the age of 13. We ask that minors (under the age of 13) not use the App. If a child under the age of 13 has provided us with Personal Information, a parent or guardian of that child may contact us and request that such information be deleted from our records.</p>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                </li>
                <li data-list-text="10.">
                    <h2 style={{ paddingLeft: '23pt', textIndent: '-18pt', textAlign: 'left' }}>Changes to This Privacy Policy.</h2>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    <p style={{ paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>This Privacy Policy is effective as of the date stated at the top of this Privacy Policy. We may change this Privacy Policy from time to time. Any such changes will be posted on the App. By accessing the App after we make any such changes to this Privacy Policy, you are deemed to have accepted such changes. Please be aware that, to the extent permitted by applicable law, our use of the Information is governed by the Privacy Policy in effect at the time we collect the Information. Please refer back to this Privacy Policy on a regular basis.</p>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                </li>

                <li data-list-text="11.">
                    <h2 style={{ paddingLeft: '23pt', textIndent: '-18pt', textAlign: 'left' }}>California Consumer Privacy Act.</h2>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    <p style={{ paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>Under the California Consumer Privacy Act
                        (the “CCPA”), consumers have certain enumerated rights, which include, but are not limited to, the right
                        to: (1) request that a business that collects a consumer’s personal information disclose the categories
                        and specific pieces of personal information collected; (2) request that a business delete</p>
                    <p style={{ paddingTop: '3pt', paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>any personal information
                        collected; (3) request that a business that collects personal information disclose (a) the categories of
                        personal information it has collected, (b) the categories of sources from which the personal information
                        is collected, (c) the business or commercial purpose for collecting or selling personal information, (d)
                        the categories of third parties with whom the business shares personal information, and</p>
                    <p style={{ paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>(e) the specific pieces of personal
                        information it has collected; (4) request that a business that sells the consumer’s personal
                        information, or that discloses it for a business purpose, disclose (a) the categories of personal
                        information that the business collected, (b) the categories of personal information that the business
                        sold and the categories of third parties to whom the personal information was sold, by category or
                        categories of personal information for each category of third parties to whom the personal information
                        was sold, and (c) the categories of personal information that the business disclosed for a business
                        purpose; (5) and to direct a business that sells personal information about the consumer to third
                        parties not to sell the consumer’s personal information. Waterfront does not sell Personal Information.
                    </p>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    <p className="s4" style={{ paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>To make a request under the
                        CCPA, please us contact by phone at (682) 332-5171, by e-mail at <a href="mailto:Info@WaterfrontTransport.com" className="a" target="_blank">Info@WaterfrontTransport.com</a>
                        <span style={{ color: '#000' }}>, or by direct mail at Waterfront Transport LLC, 9800 Hillwood Pkwy Suite
                            140, Fort Worth, TX 76177. In the past 12 months, Waterfront has collected and disclosed Personal
                            Information in some of the forms of the categories listed above.</span></p>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                </li>
                <li data-list-text="12.">
                    <h2 style={{ paddingLeft: '23pt', textIndent: '-18pt', textAlign: 'left' }}>
                        Disclaimers.
                    </h2>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                        <br />
                    </p>
                    <p style={{ paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>The App is provided by Waterfront “as is”
                        and with all faults. Waterfront makes no representations or warranties of any kind, either express or
                        implied with regard to the App, its contents, or its operation. To the fullest extent permitted by law,
                        Waterfront disclaims all warranties, express or implied, including, but not limited to, the implied
                        warranties of merchantability, fitness for a particular purpose, or non-infringement.</p>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    <p style={{ paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>Without limiting the generality of the
                        foregoing, Waterfront specifically states that use of the App is at your own risk. Waterfront disclaims
                        any warranty with regard to the operation of the App, the accuracy or timeliness of the App, or its
                        contents or the information or materials contained therein, any warranty that the App or its contents or
                        the information or materials contained therein will be free of errors, viruses, or other components that
                        may infect, harm, or cause damage to your computer equipment or any other property, or any warranty that
                        your use of the App or its content or the information and materials contained herein will be
                        uninterrupted.</p>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    <p style={{ paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>Without limiting the generality of the
                        foregoing, Waterfront specifically informs you that Waterfront makes no warranties, representations, or
                        guarantees as to the accuracy, truthfulness, or reliability of any posted information.</p>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                </li>
                <li data-list-text="13.">
                    <h2 style={{ paddingTop: '4pt', paddingLeft: '23pt', textIndent: '-18pt', textAlign: 'left' }}>Exclusion of Damages and Limitation of Liability.</h2>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    <p style={{ paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>Under no circumstances shall Waterfront,
                        its suppliers, vendors, or licensors be liable for any direct, special, incidental, indirect, economic,
                        or consequential damages (including, but not limited to, damages for loss of data, loss of use, or lost
                        profits) arising out of or connected with the App (including, but not limited to, its operation, its
                        contents, or the information or materials contained therein, or the use or inability to use the App, or
                        the use or inability to use any other site linked to the App, or any content contained in any such site,
                        or these terms of use, or any other policy applicable to the App), even if Waterfront, its suppliers,
                        vendors, or licensors have been notified of the possibility of any damages. Due to the fact that some
                        states do not allow the exclusion or limitation of liability for consequential or incidental damages,
                        the above limitation may not apply to you. In such states, the respective liability of Waterfront, its
                        suppliers, vendors, and licensors shall be limited to the greatest extent permitted by applicable law.
                    </p>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                        <br />
                    </p>

                </li>
                <li data-list-text="14.">
                    <h2 style={{ paddingLeft: '23pt', textIndent: '-18pt', textAlign: 'left' }}>General Terms.</h2>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                        <br />
                    </p>

                    <p style={{ paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>This Privacy Policy is governed by the laws
                        of the State of California, without regard to any conflict of law provisions which may otherwise cause
                        the laws of another jurisdiction to apply. You agree to the sole and exclusive jurisdiction and venue of
                        the federal or state courts of the State of California in the event of any dispute of any kind arising
                        from or relating to the App, or your use or review of it. The failure of Waterfront to enforce any
                        provision or right of this Privacy Policy will not constitute a waiver of such provision or right. This
                        Privacy Policy constitutes the entire agreement between the parties with respect to the subject matter
                        hereof and supersedes and replaces all prior or contemporaneous understandings or agreements, written or
                        oral, regarding such subject matter. If for any reason a court of competent jurisdiction finds any
                        provision or portion of this Privacy Policy to be unenforceable, the remainder of this Privacy Policy
                        will continue in full force and effect.</p>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                        <br />
                    </p>

                </li>
                <li data-list-text="15.">
                    <h2 style={{ paddingLeft: '23pt', textIndent: '-18pt', textAlign: 'left' }}>
                        How to Contact Us.
                    </h2>
                </li>
            </ol>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                <br />
            </p>

            <p className="s6" style={{ paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>
                If you have questions about this Privacy Policy, please e-mail us at &nbsp;
                <a href="mailto:Info@WaterfrontTransport.com" className="a" target="_blank">
                    Info@WaterfrontTransport.com
                </a>
                <span className="p"> with “Privacy Policy” in the subject line or mail us at the following address: Waterfront Transport LLC, 9800 Hillwood Pkwy</span>
            </p>
            <p style={{ paddingLeft: '23pt', textIndent: '0pt', textAlign: 'left' }}>
                Suite 140, Fort Worth, TX 76177.
            </p>

        </div>
    )
}

export default PrivacyPolicy
