import { PickdropDetails } from '../../../api/types/Jobs'
import EndLocationIcon from '../../../assets/images/svg/EndLocation'
import InfoIcon from '../../../assets/images/svg/Info'
import StartLocationIcon from '../../../assets/images/svg/StartLocation'
import { formatAmount } from '../../../utils/NumberUtils'
import { displayPickDropLocation } from '../../../utils/RadarUtils'

const JobDetailTrackingLocation = ({
  pickDropDetails,
  showStart = true
}: {
  pickDropDetails: PickdropDetails
  showStart: boolean
}) => {
  const caption = showStart ? 'Start Location' : 'End Location'
  const label = displayPickDropLocation(
    showStart
      ? pickDropDetails?.starting_location
      : pickDropDetails?.ending_location,
    { includeFlag: false }
  )
  return (
    <div className={`job-location ${showStart ? 'start' : 'end'}`}>
      <div className="caption">
        {showStart ? <StartLocationIcon /> : <EndLocationIcon />}
        <div className="caption-text">{caption}</div>
      </div>
      <div className="location">{label}</div>
    </div>
  )
}
export default JobDetailTrackingLocation
