import React, { useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import DetailHeader from '../driver-detail/header/header'
import { FormikProvider, useFormik } from 'formik'
import { addTruckSchema } from '../../../utils/Validations'
import TruckImage from '../../../assets/images/png/TruckImage.png'
import FormControl from '../../../components/formControl/FormControl'
import PrimaryButton from '../../../components/primaryButton/PrimaryButton'
import { AddTruckPayload } from '../../../api/types/Trucks'
import PdfIcon from '../../../assets/images/png/PdfIcon.png'
import '../../../components/table/table.scss'
import { Chip } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useAcceptDocumentMutation,
  useGetTruckDocumentsQuery,
  useRejectDocumentMutation,
  useTruckDetailQuery
} from '../../../api/network/truckApiServices'
import { TruckResponse } from '../../../api/types/Driver'
import { fetchUserData, fetchUserType } from '../../../utils/storage'
import { userTypes } from '../../../utils/constants'
import { Document } from '../../../api/types/TruckDocuments'
import TruckDocumentPreviewModal from '../../../components/modal/documentPreview/TruckDocumentPreviewModal'
import { saveAs } from 'file-saver'
import { toast } from 'react-toastify'
import { DateFormat, changeDateFormat } from '../../../utils/DateUtils'
import { ROUTE_NAMES } from '../../../utils/route/routerNames'
import { API_TRUCK, Add_Truck, BASE_URL } from '../../../api/network/ServerUrl'
import AppStyledModal from '../../../components/modal/appStyledModal/appStyledModal'
import DeleteIcon from '../../../assets/images/svg/DeleteIcon'
import DocUpload from '../../../assets/images/svg/DocUpload'

const TruckDetailsPage = () => {
  const { truckId }: { truckId?: string } = useParams()
  const userData = fetchUserData()
  const { data, isFetching } = useTruckDetailQuery(truckId || '1', {
    refetchOnMountOrArgChange: true
  })
  const { data: truckDocumentsData, isFetching: isFetchingTruckDocumentData } =
    useGetTruckDocumentsQuery(truckId || '1', {
      refetchOnMountOrArgChange: true
    })
  const navigate = useNavigate()
  const [acceptDocument] = useAcceptDocumentMutation()
  const [rejectDocument] = useRejectDocumentMutation()
  const [document, setDocument] = useState<Document>()
  const [image, setImage] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [show, setShow] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [isPdfPreview, setIsPdfPreview] = useState(false)
  const handleClose = () => setShow(false)
  const handleCloseModal = () => setShowModal(false)
  const handleCloseRejectModal = () => setShowRejectModal(false)
  console.log(truckDocumentsData)

  const initialValues: AddTruckPayload = {
    make_model: '',
    truck_number: '',
    license_plate: '',
    truck_documents: [
      {
        document: {
          document_type: 0,
          expiry_date: '',
          document_files: [{ file: '' }]
        }
      }
    ]
  }

  const [results, setResults] = useState([
    {
      document_type: 'Registrations',
      file_name: 'sample_filename_01.pdf',
      file_type: 'pdf',
      size: '2',
      thumbnail: ''
    },
    {
      document_type: 'Safety inspection',
      file_name: 'sample_filename_02.pdf',
      file_type: 'pdf',
      size: '2',
      thumbnail: ''
    },
    {
      document_type: 'Insurance',
      file_name: 'sample_filename_03.pdf',
      file_type: 'pdf',
      size: '2',
      thumbnail: ''
    },
    {
      document_type: 'RFID',
      file_name: 'sample_filename_04.pdf',
      file_type: 'pdf',
      size: '2',
      thumbnail: ''
    }
  ])

  const getStatusLabel = (statusCode: number) => {
    switch (statusCode) {
      case 1:
        return 'Approved'
      case 2:
        return 'Rejected'
      case 3:
        return 'Pending'
      case 4:
        return 'Expired'
      default:
        return ''
    }
  }

  const getFileName = (url: string): string | null => {
    // Split the URL by '/' to separate the path and query parameters
    const urlParts = url.split('?')[0].split('/')

    // Get the last part which contains the filename
    const filename = urlParts[urlParts.length - 1]

    return filename || null
  }

  const getFileType = (url: string): string | null => {
    // Split the URL by '/' to separate the path and query parameters
    const urlParts = url.split('?')[0].split('/')

    // Get the last part which contains the filename
    const filename = urlParts[urlParts.length - 1]

    // Split the filename by '.' to get the file extension
    const fileParts = filename.split('.')
    const fileType =
      fileParts.length > 1 ? fileParts[fileParts.length - 1] : null

    return fileType
  }

  const documentPreview = (
    document: Document,
    image: string,
    name: string,
    isPdfPreview: boolean
  ) => {
    setDocument(document)
    setImage(image)
    setName(name)
    setShow(true)
    setIsPdfPreview(isPdfPreview)
  }

  const columns = [
    {
      Header: 'Document Type',
      accessor: 'document_type'
    },
    {
      Header: 'Upload Date',
      accessor: 'updated_at'
    },
    {
      Header: 'Expiry Date',
      accessor: 'expiry_date'
    },
    {
      Header: 'Thumbnail',
      accessor: 'thumbnail'
    },
    {
      Header: 'Status',
      id: 'Status'
    }
  ]

  const columns2 = [
    {
      Header: 'Document Type',
      accessor: 'document_type'
    },
    {
      Header: 'Expiry Date',
      accessor: 'date'
    },
    // {
    //   Header: 'File Type',
    //   accessor: 'file_type'
    // },
    {
      Header: 'Thumbnail',
      accessor: 'thumbnail'
    },
    {
      Header: 'Status',
      id: 'status'
    },
    {
      Header: 'Action',
      id: 'action'
    }
  ]

  const handleAcceptDocument = async (id: number) => {
    await acceptDocument(id)
    window.location.reload()
  }
  const handleRejectDocument = async (id: number) => {
    await rejectDocument(id)
    window.location.reload()
  }
  //approve truck
  const approveTruck = async (id: number) => {
    let docCheck = false

    if (truckDocumentsData) {
      for (const truckDocument of truckDocumentsData) {
        console.log(truckDocument.is_required)
        console.log(truckDocument.truck_document?.document?.status)
        if (truckDocument.truck_document?.document?.status === 1) {
          docCheck = true
        } else {
          docCheck = false
          if (truckDocument.truck_document == null) {
            docCheck = true
          }
        }
      }
      if (docCheck) {
        const formData = new FormData()
        formData.append('status', '1')
        const response = await fetch(`${BASE_URL}${Add_Truck}${truckId}/`, {
          method: 'PATCH',
          body: formData,
          headers: {
            Authorization: `Token ${userData.token}`
          }
        })
        toast.success('Truck Approved')
        window.location.reload()
      } else {
        toast.error('Please approve all Document First')
      }
    }
  }

  //reject truck

  const rejectTruck = async (id: number) => {
    // let docCheck = false

    if (truckDocumentsData) {
      // for (const truckDocument of truckDocumentsData) {
      //   console.log(truckDocument.is_required)
      //   console.log(truckDocument.truck_document?.document?.status)
      //   if (truckDocument.truck_document?.document?.status === 2) {
      //     docCheck = true
      //   } else {
      //     docCheck = false
      //     if (truckDocument.truck_document == null) {
      //       docCheck = true
      //     }
      //   }
      // }
      // if (docCheck) {
      const formData = new FormData()
      formData.append('status', '2')
      const response = await fetch(`${BASE_URL}${Add_Truck}${truckId}/`, {
        method: 'PATCH',
        body: formData,
        headers: {
          Authorization: `Token ${userData.token}`
        }
      })
      toast.success('Truck Rejected Successfully!')
      window.location.reload()
      // } else {
      //   toast.error('Please approve all Document First')
      // }
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: addTruckSchema,
    onSubmit: (values: AddTruckPayload) => {
      //   if (isReadOnly) {
      //     setReadOnly(!isReadOnly)
      //   } else {
      //     updateUserApiTrigger(values)
      //       .unwrap()
      //       .then(response => {
      //         toast.success(response.message)
      //         // formik.resetForm()
      //         setReadOnly(!isReadOnly)
      //       })
      //       .catch(e => {})
      //   }
    }
  })

  const handleDeleteTruckData = async (id: number) => {
    const response = await fetch(`${BASE_URL}${Add_Truck}${id}/`, {
      method: 'DELETE',
      headers: {
        Authorization: `Token ${userData.token}`
      }
    })
    if (response.status === 204) {
      toast.success('Truck deleted successfully')
      navigate(ROUTE_NAMES.dashboardChildren.truckList)
    }
  }

  const [imageUpload, setImageUpload] = useState<
    {
      truckDocumentId: number
      id: any
      category: any
      img: string
      imagePrev: string
      is_expiry_date_required: boolean
    }[]
  >([])
  const [expiryDate, setExpiryDate] = useState<
    { id: any; category: any; expiry_date: string }[]
  >([])

  const handleImageChange = (
    event: any,
    category: any,
    truckDocumentId: number,
    is_expiry_date_required: any
  ) => {
    // console.log('event', event.target.files[0])
    // console.log('category', category)
    // const file = event.target.files[0]
    // console.log('id', event.target.id)
    // if (file) {
    //   const reader: any = new FileReader()
    //   console.log('reader', reader)
    //   reader.onload = () => {
    //     if (event.target.id == '1') {
    //       setImageUpload(prevImage => [
    //         ...prevImage,
    //         {
    //           truckDocumentId: truckDocumentId,
    //           id: event.target.id,
    //           category: category,
    //           img: file,
    //           imagePrev: reader.result
    //         }
    //       ])
    //     } else if (event.target.id == '2') {
    //       setImageUpload(prevImage => [
    //         ...prevImage,
    //         {
    //           truckDocumentId: truckDocumentId,
    //           id: event.target.id,
    //           category: category,
    //           img: file,
    //           imagePrev: reader.result
    //         }
    //       ])
    //     } else if (event.target.id == '3') {
    //       setImageUpload(prevImage => [
    //         ...prevImage,
    //         {
    //           truckDocumentId: truckDocumentId,
    //           id: event.target.id,
    //           category: category,
    //           img: file,
    //           imagePrev: reader.result
    //         }
    //       ])
    //     } else if (event.target.id == '4') {
    //       setImageUpload(prevImage => [
    //         ...prevImage,
    //         {
    //           truckDocumentId: truckDocumentId,
    //           id: event.target.id,
    //           category: category,
    //           img: file,
    //           imagePrev: reader.result
    //         }
    //       ])
    //     }
    //   }
    //   console.log('image', image)
    //   reader.readAsDataURL(file)
    // } else {
    //   // setImage(null)
    // }
    const available = imageUpload.find(item => item.id == event.target.id)
    if (available) {
      const file = event.target.files[0]
      const reader: any = new FileReader()
      reader.onload = () => {
        const updatedData: any = imageUpload.map(item =>
          item.id == event.target.id
            ? {
                ...item,
                img: file,
                imagePrev: reader.result
                // is_required: required
              }
            : item
        )
        setImage(updatedData)
      }
      event.target.value = ''
      console.log('image', image)
      reader.readAsDataURL(file)
    } else {
      const file = event.target.files[0]
      const reader: any = new FileReader()
      reader.onload = () => {
        setImageUpload(prevImage => [
          ...prevImage,
          {
            id: event.target.id,
            category: category,
            img: file,
            imagePrev: reader.result,
            // is_required: required
            is_expiry_date_required: is_expiry_date_required,
            truckDocumentId: truckDocumentId
          }
        ])
      }
      console.log('image', image)
      reader.readAsDataURL(file)
      event.target.value = ''
    }
  }

  const handleDateChange = (e: any, id: any, category: any, required: any) => {
    console.log(
      'date',
      e.target.value,
      'id',
      id,
      'category',
      category,
      'required',
      required
    )
    const available = expiryDate.find(item => item.id == id)
    // if (e.target.value) {
    if (available) {
      const updatedData: any = expiryDate.map(item =>
        item.id == id ? { ...item, expiry_date: e.target.value } : item
      )
      setExpiryDate(updatedData)
    } else {
      setExpiryDate(prevDate => [
        ...prevDate,
        {
          id: id,
          category: category,
          expiry_date: e.target.value,
          required: required
        }
      ])
    }
    console.log('expiryDate', expiryDate)
  }

  const handleDeleteDate = (id: any) => {
    console.log('id', id)
    const updatedDate = expiryDate.filter(item => item.id != id)
    setExpiryDate(updatedDate)
    console.log('updatedDate', updatedDate)
  }
  const handleDocDelete = (id: any) => {
    console.log('id', id)
    const updatedDocs = imageUpload.filter(item => item.id != id)
    setImageUpload(updatedDocs)
    console.log('image', image)
    handleDeleteDate(id)
  }

  const updateDocuments = () => {
    console.log('imageUpload', imageUpload)
    console.log('expiryDate len', expiryDate)
    // if (expiryDate.length > 0) {
    for (const item of Object.values(imageUpload)) {
      console.log('item', item)
      if (!item?.is_expiry_date_required == false) {
        const expiryDateItem: any = expiryDate?.find(
          dateItem => dateItem?.id == item?.id
        )
        if (expiryDateItem == undefined || expiryDateItem.expiry_date == '') {
          console.log(
            'expiryDateItem?.expiry_date',
            expiryDateItem?.expiry_date
          )
          toast.error('Please enter the expiry date for required documents!')
          return
        }
      } else {
        continue
      }
    }
    // } else {
    imageUpload.forEach(async imageElement => {
      console.log('expiry', expiryDate)
      console.log('imageElement', imageElement)

      // if (imageElement.is_expiry_date_required == true) {
      //   const expiry = expiryDate.find(row => {
      //     return row.id == imageElement.id
      //   })
      //   if (!expiry?.expiry_date) {
      //     toast.error('Please enter the expiry date for required documents!')
      //     return
      //   }
      //  else {
      if (imageElement.truckDocumentId) {
        //Update Truck Document
        const data = new FormData()
        data.append('document.document_files[0]file', imageElement.img)
        // data.append("document.document_files[0]name","test")
        const expiry = expiryDate.find(row => {
          return row.id == imageElement.id
        })
        data.append('document.expiry_date', expiry?.expiry_date || '')
        const response = await fetch(
          `${BASE_URL}${Add_Truck}${API_TRUCK.ADD_TRUCK_DOCUMENTS}${imageElement.truckDocumentId}/`,
          {
            method: 'PATCH',
            body: data,
            headers: {
              Authorization: `Token ${userData.token}`
            }
          }
        )
        if (response.ok) {
          toast.success('Document Updated Successfully')
          formik.resetForm()
          setImageUpload([])
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        }
      } else {
        //Add Truck Document
        const data = new FormData()
        data.append('truck', truckId || '')
        data.append('document.document_files[0]file', imageElement.img)
        data.append('document.document_type', '4')
        const expiry = expiryDate.find(row => {
          return row.id == imageElement.id
        })
        data.append('document.expiry_date', expiry?.expiry_date || '')

        const response = await fetch(
          `${BASE_URL}${Add_Truck}${API_TRUCK.ADD_TRUCK_DOCUMENTS}`,
          {
            method: 'POST',
            body: data,
            headers: {
              Authorization: `Token ${userData.token}`
            }
          }
        )
        if (response.ok) {
          toast.success('Document Updated Successfully')
          formik.resetForm()
          setImageUpload([])
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        }
      }

      // }

      // toast.success('Document Updated Successfully')
      // formik.resetForm()
      // setImageUpload([])
      // setTimeout(() => {
      //   window.location.reload()
      // }, 1000)
    })
    // }
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="p-0">
            <DetailHeader title="Truck Details" />
          </Col>
        </Row>
        <Row>
          <Col lg={3} md={6} className="card entity-detail-card">
            <div>
              <img src={TruckImage} alt="TruckImage" />
            </div>
            <div className="mt-1">
              (Status : {getStatusLabel(data?.status || 0)})
            </div>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit}>
                <FormControl
                  placeholder="Make and Model"
                  label="Details"
                  // controlId="validateLicenseNumber"
                  // onChange={e =>
                  //   formik.setFieldValue('license_number', e.target.value)
                  // }
                  // errorMessage={formik.errors.license_number}
                  value={data?.make_model}
                  // isInvalid={!!formik.errors.license_number}
                  disabled={true}
                  showLabel={true}
                />
                <FormControl
                  placeholder="Truck Number"
                  label=""
                  // controlId="validateLicenseNumber"
                  // onChange={e =>
                  //   formik.setFieldValue('license_number', e.target.value)
                  // }
                  // errorMessage={formik.errors.license_number}
                  value={data?.truck_number}
                  // isInvalid={!!formik.errors.license_number}
                  disabled={true}
                  showLabel={true}
                />
                <FormControl
                  placeholder="License Plate"
                  label=""
                  // controlId="validateLicenseNumber"
                  // onChange={e =>
                  //   formik.setFieldValue('license_number', e.target.value)
                  // }
                  // errorMessage={formik.errors.license_number}
                  value={data?.license_plate}
                  // isInvalid={!!formik.errors.license_number}
                  disabled={true}
                  showLabel={true}
                />
                <FormControl
                  placeholder="Allotted Driver"
                  controlId="AllottedDriver"
                  label="Allotment Details"
                  // onChange={e =>
                  //   formik.setFieldValue('license_number', e.target.value)
                  // }
                  // errorMessage={formik.errors.license_number}
                  value={data?.allocated_driver}
                  // isInvalid={!!formik.errors.license_number}
                  disabled={true}
                  showLabel={true}
                />
                {fetchUserType() === userTypes.COMPANY &&
                  data?.status !== 3 && (
                    <div>
                      <PrimaryButton
                        title="Edit"
                        style={{ width: '100%' }}
                        className="mt-2"
                        onClick={() =>
                          navigate(
                            `${ROUTE_NAMES.dashboardChildren.editTruck.replace(
                              ':truckId',
                              `${data?.id}`
                            )}`
                          )
                        }
                      />
                      <PrimaryButton
                        title="Delete"
                        style={{ width: '100%' }}
                        variant="outline-danger"
                        className="mt-2"
                        onClick={() => setShowModal(true)}
                      />
                    </div>
                  )}
                {fetchUserType() === userTypes.ADMIN && data?.status !== 1 && (
                  <div>
                    <PrimaryButton
                      title="Approve Truck"
                      style={{ width: '100%' }}
                      className="mt-2"
                      onClick={() => approveTruck(data?.id || 0)}
                    />
                    {/* <PrimaryButton
                        title="Reject Truck"
                        style={{ width: '100%' }}
                        variant="outline-danger"
                        className="mt-2"
                        onClick={() => setShowRejectModal(true)}
                      /> */}
                  </div>
                )}
                {(fetchUserType() === userTypes.ADMIN && data?.status == 1) ||
                (fetchUserType() === userTypes.ADMIN && data?.status == 3) ? (
                  <div>
                    {/* <PrimaryButton
                      title="Approve Truck"
                      style={{ width: '100%' }}
                      className="mt-2"
                      onClick={() => approveTruck(data?.id || 0)}
                    /> */}
                    <PrimaryButton
                      title="Reject Truck"
                      style={{ width: '100%' }}
                      variant="outline-danger"
                      className="mt-2"
                      onClick={() => setShowRejectModal(true)}
                    />
                  </div>
                ) : null}
              </Form>
            </FormikProvider>
          </Col>
          <Col>
            <div className="row">
              <div
                className="col-md-4"
                style={{
                  color: '#123051',
                  fontWeight: '600',
                  fontSize: '20px',
                  marginTop: '15px'
                }}
              >
                Documents
              </div>
              {truckDocumentsData?.some(
                doc =>
                  doc.truck_document !== null &&
                  (doc.truck_document.document.status === 2 ||
                    doc.truck_document.document.status === 4)
              ) && fetchUserType() === userTypes.COMPANY ? (
                <div className="col-md-4 offset-md-4">
                  <Button onClick={updateDocuments}>Save</Button>
                </div>
              ) : null}
            </div>

            {fetchUserType() === userTypes.ADMIN && (
              <table className="mt-3">
                <thead className="table-head" style={{ height: '50px' }}>
                  <tr>
                    {columns.map(column => (
                      <th key={column.Header} className="px-2">
                        {column.Header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {!isFetching &&
                    truckDocumentsData &&
                    truckDocumentsData.map(cell => (
                      <tr key={cell.id}>
                        <td>
                          <span style={{ fontWeight: 'bold' }}>
                            {cell.name}
                            {cell.is_required && <>*</>}
                          </span>
                        </td>
                        {/* <td>
                    {getFileName(cell.document?.document_files[0].file || "")}
                  </td> */}
                        <td>
                          {cell.truck_document !== null &&
                            changeDateFormat(
                              cell.truck_document.document?.updated_at,
                              DateFormat.YYYYMMMDDHHMMSS,
                              DateFormat.DDMMMYYYY1
                            )}
                        </td>
                        <td>
                          {cell.truck_document !== null &&
                            cell.truck_document.document?.expiry_date !==
                              null &&
                            changeDateFormat(
                              cell.truck_document.document?.expiry_date ?? '',
                              DateFormat.YYYYMMMDDHHMMSS,
                              DateFormat.DDMMMYYYY1
                            )}
                        </td>

                        <td>
                          {cell.truck_document !== null &&
                            (getFileType(
                              cell.truck_document.document?.document_files?.[0]
                                ?.file || ''
                            ) !== 'pdf' ? (
                              <img
                                src={
                                  cell.truck_document.document
                                    ?.document_files?.[0]?.file
                                }
                                // onClick={() => { documentPreview(cell.document, cell.document?.document_files[0].file, cell.name,false) }}
                                onClick={() => {
                                  saveAs(
                                    cell.truck_document.document
                                      ?.document_files?.[0]?.file
                                  )
                                }}
                                alt="Thumbnail"
                                style={{ width: 'auto', height: '25px' }}
                              />
                            ) : (
                              <img
                                src={PdfIcon}
                                alt="Thumbnail"
                                // onClick={() => { documentPreview(cell.document, cell.document?.document_files[0].file, cell.name,true) }}
                                onClick={() => {
                                  saveAs(
                                    cell.truck_document.document
                                      ?.document_files?.[0]?.file
                                  )
                                }}
                              />
                            ))}
                        </td>
                        <td>
                          {cell.truck_document &&
                          fetchUserType() === userTypes.ADMIN ? (
                            cell.truck_document.document &&
                            cell.truck_document.document?.status === 3 ? (
                              <div className="d-flex">
                                <Button
                                  className="me-2"
                                  variant="danger"
                                  onClick={() => {
                                    handleRejectDocument(
                                      cell.truck_document.document.id
                                    )
                                    toast.success('Document rejected')
                                  }}
                                >
                                  Reject
                                </Button>
                                <Button
                                  variant="success"
                                  onClick={() => {
                                    handleAcceptDocument(
                                      cell.truck_document.document.id
                                    )
                                    toast.success('Document approved')
                                  }}
                                >
                                  Accept
                                </Button>
                              </div>
                            ) : (
                              getStatusLabel(
                                cell.truck_document.document?.status
                              )
                            )
                          ) : (
                            cell.truck_document &&
                            getStatusLabel(
                              cell.truck_document.document?.status || 0
                            )
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}

            {fetchUserType() === userTypes.COMPANY && (
              <table className="mt-3">
                <thead className="table-head" style={{ height: '50px' }}>
                  <tr>
                    {columns2.map((column: any) => (
                      <th key={column.Header} className="px-2">
                        {column.Header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(truckDocumentsData) &&
                    truckDocumentsData?.map(
                      doc =>
                        doc?.category == 1 &&
                        (doc.truck_document !== null &&
                        (doc.truck_document.document.status === 1 ||
                          doc.truck_document.document.status === 3) ? (
                          <>
                            <tr key={doc.id}>
                              <td>
                                <span style={{ fontWeight: 'bold' }}>
                                  {doc.name}
                                  {doc.is_required && <>*</>}
                                </span>
                              </td>
                              {/* <td>
                              {getFileName(doc.document?.document_files[0].file || "")}
                          </td> */}

                              <td>
                                {
                                  // doc.truck_document !== null &&
                                  //   doc.truck_document.document?.expiry_date !==
                                  //     null &&
                                  doc.is_expiry_date_required &&
                                    changeDateFormat(
                                      doc.truck_document.document
                                        ?.expiry_date ?? '',
                                      DateFormat.YYYYMMMDDHHMMSS,
                                      DateFormat.DDMMMYYYY1
                                    )
                                }
                              </td>

                              <td>
                                {doc.truck_document !== null &&
                                  (getFileType(
                                    doc.truck_document.document
                                      ?.document_files[0].file || ''
                                  ) !== 'pdf' ? (
                                    <img
                                      src={
                                        doc.truck_document.document
                                          ?.document_files[0].file
                                      }
                                      // onClick={() => { documentPreview(cell.document, cell.document?.document_files[0].file, cell.name,false) }}
                                      alt="Thumbnail"
                                      style={{
                                        width: 'auto',
                                        height: '25px'
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={PdfIcon}
                                      alt="Thumbnail"
                                      // onClick={() => { documentPreview(cell.document, cell.document?.document_files[0].file, cell.name,true) }}
                                    />
                                  ))}
                              </td>
                              <td>
                                {getStatusLabel(
                                  doc.truck_document.document?.status || 0
                                )}
                              </td>
                            </tr>
                          </>
                        ) : (
                          <tr key={doc.name}>
                            <td>
                              <span style={{ fontWeight: 'bold' }}>
                                {doc.name} {doc.is_required && <>*</>}
                              </span>
                            </td>
                            <td className="d-flex">
                              <div
                                style={{
                                  fontWeight: 'bold'
                                  // marginTop: '20px'
                                }}
                              >
                                {`${doc.is_expiry_date_required ? '*' : ''}`}
                              </div>
                              {doc.is_expiry_date_required && (
                                <div className="w-75">
                                  <FormControl
                                    type="date"
                                    placeholder="Valid Till"
                                    controlId="validateValidTill"
                                    label=""
                                    onChange={e =>
                                      handleDateChange(
                                        e,
                                        doc.id,
                                        doc.category,
                                        doc.is_required
                                      )
                                    }
                                    // errorMessage={formik.errors.valid_till}
                                    value={
                                      expiryDate.find(el => el.id === doc.id)
                                        ?.expiry_date || ''
                                    }
                                    // isInvalid={!!formik.errors.valid_till}
                                    min={new Date().toISOString().split('T')[0]}
                                    // showLabel={true}
                                    marginTop={'0px'}
                                  />
                                </div>
                              )}
                            </td>

                            <td className="">
                              {imageUpload.map(item =>
                                // item.id == doc.id &&
                                // item.imagePrev &&
                                // item.imagePrev.indexOf('image/jpeg') !==
                                //   -1 ? (
                                //   <img
                                //     src={item.imagePrev}
                                //     alt="img"
                                //     style={{
                                //       width: 'auto',
                                //       height: '25px'
                                //     }}
                                //     onClick={() => {
                                //       saveAs(item.imagePrev)
                                //     }}
                                //   />
                                // ) :
                                item.id == doc.id &&
                                item.imagePrev &&
                                item.imagePrev.indexOf('application/pdf') !==
                                  -1 ? (
                                  <img
                                    src={PdfIcon}
                                    alt="img"
                                    style={{
                                      width: 'auto',
                                      height: '25px'
                                    }}
                                    onClick={() => {
                                      saveAs(item.imagePrev)
                                    }}
                                  />
                                ) : (
                                  item.id == doc.id &&
                                  item.imagePrev && (
                                    // item.imagePrev.indexOf('image/jpeg') !==
                                    //   -1 ?

                                    <img
                                      src={item.imagePrev}
                                      alt="img"
                                      style={{
                                        width: '35px',
                                        height: '35px',
                                        marginTop: '15px'
                                      }}
                                      onClick={() => {
                                        saveAs(item.imagePrev)
                                      }}
                                    />
                                  )
                                )
                              )}
                            </td>
                            <td>
                              {doc.truck_document &&
                                getStatusLabel(
                                  doc.truck_document.document.status
                                )}
                            </td>
                            <td>
                              <input
                                type="file"
                                // multiple
                                hidden
                                id={`${doc.id}`}
                                // label="upload"
                                onChange={e =>
                                  handleImageChange(
                                    e,
                                    doc.category,
                                    doc?.truck_document?.id,
                                    doc?.is_expiry_date_required
                                  )
                                }
                              />
                              {/* </td>
                        <td> */}
                              {imageUpload.find(
                                item => item.id == doc.id && item.img
                              ) ? (
                                <div onClick={() => handleDocDelete(doc.id)}>
                                  <DeleteIcon />
                                </div>
                              ) : (
                                <label htmlFor={`${doc.id}`}>
                                  <DocUpload />{' '}
                                </label>
                              )}
                            </td>
                          </tr>
                        ))
                    )}
                </tbody>
              </table>
            )}
          </Col>
        </Row>
        {/* <AppStyledModal
          closeButton={false}
          handleClose={handleCloseModal || handleCloseRejectModal}
          show={showModal || showRejectModal}
          title={showModal ? 'Delete Truck Details ' : 'Reject Truck'}
          rightOnPress={handleCloseModal || handleCloseRejectModal}
          rightButtonText={`Cancel`}
          leftOnPress={
            showModal
              ? () => handleDeleteTruckData(data?.id || 0)
              : () => rejectTruck(data?.id || 0)
          }
          leftButtonText={showModal ? `Delete` : `Reject`}
        >
          <p className="fs-20 my-3">
            {showModal
              ? 'Are you sure you want to delete this Truck?'
              : 'Are you sure you want to Reject this Truck?'}
            <i
              className="fas fa-exclamation-triangle"
              style={{ fontSize: '30px', color: 'red' }}
            />
          </p>
        </AppStyledModal>
  */}

        <AppStyledModal
          closeButton={false}
          handleClose={handleCloseModal}
          show={showModal}
          title="Delete Truck Details "
          rightOnPress={handleCloseModal}
          rightButtonText={`Cancel`}
          leftOnPress={() => handleDeleteTruckData(data?.id || 0)}
          leftButtonText={`Delete`}
        >
          <p className="fs-20 my-3">
            Are you sure you want to delete this Truck?
            <i
              className="fas fa-exclamation-triangle"
              style={{ fontSize: '30px', color: 'red' }}
            />
          </p>
        </AppStyledModal>
        <AppStyledModal
          closeButton={false}
          handleClose={handleCloseRejectModal}
          show={showRejectModal}
          title="Reject Truck"
          rightOnPress={handleCloseRejectModal}
          rightButtonText={`Cancel`}
          leftOnPress={() => rejectTruck(data?.id || 0)}
          leftButtonText={`Reject`}
        >
          <p className="fs-20 my-3">
            Are you sure you want to reject this Truck?
            <i
              className="fas fa-exclamation-triangle"
              style={{ fontSize: '30px', color: 'red' }}
            />
          </p>
        </AppStyledModal>
      </Container>

      <TruckDocumentPreviewModal
        show={show}
        handleClose={handleClose}
        document={document}
        image={image}
        name={name}
        pdf={isPdfPreview}
      />
    </>
  )
}

export default TruckDetailsPage
