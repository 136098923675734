import Button from "react-bootstrap/esm/Button"
import { IPrimaryButton } from "./types"

const PrimaryButton = (props: IPrimaryButton) => {
  const { title, size = "lg" } = props
  return (
    <Button {...props} size={size}>
      {title}
    </Button>
  )
}
export default PrimaryButton
