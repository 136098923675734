import { Col, Container, Row, Form } from "react-bootstrap"
import Logo from "../../assets/images/svg/Logo"
import Backdrop from "../../assets/images/png/Backdrop.png"
import PrimaryButton from "../../components/primaryButton/PrimaryButton"
import FormControl from "../../components/formControl/FormControl"
import { Formik, FormikProps } from "formik"
import { forgotPasswordSchema } from "../../utils/Validations"
import { useForgotPasswordMutation } from "../../api/network/authApiService"
import Loader from "../../components/loader/Loader"
import { ForgotPasswordPayload } from "../../api/types/ForgotPasword"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { Button } from "react-bootstrap"
import BackIcon from "../../assets/images/svg/BackIcon"

const PageForgotPassword = () => {
  const [forgotPasswordApiTrigger, { isLoading }] = useForgotPasswordMutation()
  const navigate = useNavigate()

  const initialValue: ForgotPasswordPayload = {
    email: ""
  }

  const handleSubmit = (values: ForgotPasswordPayload) => {
    forgotPasswordApiTrigger(values)
      .unwrap()
      .then(response => {
        toast.success(response.message)
        navigate(-1)
      })
      .catch(e => {})
  }

  const renderLoginForm = (renderProps: FormikProps<ForgotPasswordPayload>) => {
    const { errors, values, handleChange, handleSubmit } = renderProps
    return (
      <Form noValidate onSubmit={handleSubmit} className="d-flex flex-column">
        <FormControl
          placeholder="Registered email"
          controlId="validateEmail"
          errorMessage={errors.email}
          value={values.email}
          onChange={handleChange("email")}
          isInvalid={!!errors.email}
          className="bg-white"
        />
        <PrimaryButton
          title="Reset Link"
          type="submit"
          className="align-self-center mt-4 w-100"
        />
      </Form>
    )
  }

  return (
    <Container fluid>
      {isLoading && <Loader />}
      <Row>
        <Col className="loginContainer p-0">
          <div>
            <Logo />
            <h2 className="header d-flex align-items-center">
              <Button
                className="d-flex button align-items-center justify-content-center ps-0"
                variant="link"
                onClick={() => {
                  navigate(-1)
                }}
              >
                <BackIcon />
              </Button>
              <span>Forgot Password</span>
            </h2>
            <Formik
              initialValues={initialValue}
              onSubmit={values => handleSubmit(values)}
              validationSchema={forgotPasswordSchema}
            >
              {renderLoginForm}
            </Formik>
          </div>
        </Col>
        <Col className="vh100 imageContainer p-0">
          <img src={Backdrop} className="image" alt="" />
        </Col>
      </Row>
    </Container>
  )
}

export default PageForgotPassword
