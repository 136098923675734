import {
  Col,
  Container,
  Row,
  Form,
  ButtonGroup,
  ToggleButton,
  Button
} from 'react-bootstrap'
import './login.scss'
import Logo from '../../assets/images/svg/Logo'
import Backdrop from '../../assets/images/png/Backdrop.png'
import { useEffect, useState } from 'react'
import PrimaryButton from '../../components/primaryButton/PrimaryButton'
import FormControl from '../../components/formControl/FormControl'
import { LoginPayload, LoginResponse } from '../../api/types/Login'
import { Formik, FormikProps } from 'formik'
import { loginFieldsSchema } from '../../utils/Validations'
import { useLoginMutation } from '../../api/network/authApiService'
import Loader from '../../components/loader/Loader'
import { localStorageKeys, userTypes } from '../../utils/constants'
import { useNavigate } from 'react-router-dom'
import { ROUTE_NAMES } from '../../utils/route/routerNames'
import { useContactUSDetailQuery } from '../../api/network/feedbackApiService'
import { useAccountInfoQuery } from '../../api/network/userApiService'

const PageLogin = () => {
  const navigate = useNavigate()
  const [rememberMe, setRememberMe] = useState(true)

  const [userType, setUserType] = useState(userTypes.ADMIN)
  const [loginApiTrigger, { isLoading }] = useLoginMutation()
  const { data: contactUs } = useContactUSDetailQuery()

  const [loggedInResponse, setLoggedInResponse] = useState<LoginResponse>()
  const { data: dataAccountInfo, isLoading: isLoadingAccountInfo } =
    useAccountInfoQuery(
      {},
      {
        skip:
          !loggedInResponse ||
          (!!loggedInResponse &&
            Number(loggedInResponse?.user.user_type) ===
              Number(userTypes.ADMIN))
      }
    )

  const initialValue: LoginPayload = {
    username: '',
    password: '',
    user_type: Number(userType)
  }
  const chips = [
    { name: 'Admin', value: userTypes.ADMIN },
    { name: 'Company', value: userTypes.COMPANY }
  ]
  const handleSubmit = (values: LoginPayload) => {
    values.user_type = Number(userType)
    loginApiTrigger(values)
      .unwrap()
      .then(response => {
        if (response) {
          if (rememberMe)
            localStorage.setItem(
              localStorageKeys.user,
              JSON.stringify(response)
            )
          else
            sessionStorage.setItem(
              localStorageKeys.user,
              JSON.stringify(response)
            )
        }
        setLoggedInResponse(response)
        if (Number(response?.user.user_type) === Number(userTypes.ADMIN)) {
          redirectBasedOnUserStatus(response)
        } else {
          // redirect after retrieving account info (via useEffect)
        }
      })
      .catch(e => {})
  }

  const redirectBasedOnUserStatus = (res: LoginResponse) => {
    if (
      res.user.is_temporary_password &&
      res.user.user_type === userTypes.COMPANY
    ) {
      navigate(ROUTE_NAMES.authChildren.setPassword)
    } else {
      window.location.href=ROUTE_NAMES.dashboardChildren.users
      // navigate(ROUTE_NAMES.dashboardChildren.users)
    }
  }

  useEffect(() => {
    if (!!dataAccountInfo && !isLoadingAccountInfo) {
      sessionStorage.setItem(
        localStorageKeys.accountInfo,
        JSON.stringify(dataAccountInfo)
      )
      if (loggedInResponse) redirectBasedOnUserStatus(loggedInResponse)
    }
  }, [dataAccountInfo, isLoadingAccountInfo])

  const renderLoginForm = (renderProps: FormikProps<LoginPayload>) => {
    const { errors, values, handleChange, handleSubmit } = renderProps
    return (
      <Form noValidate onSubmit={handleSubmit} className="d-flex flex-column">
        <FormControl
          placeholder="Email"
          controlId="validateEmail"
          errorMessage={errors.username}
          value={values.username}
          onChange={handleChange('username')}
          isInvalid={!!errors.username}
          className="bg-white"
        />
        <FormControl
          placeholder="Password"
          controlId="validatePassword"
          errorMessage={errors.password}
          type="password"
          value={values.password}
          onChange={handleChange('password')}
          isInvalid={!!errors.password}
          className="bg-white"
        />
        <div className="forgotContainer mb-4">
          <Form.Check
            type={'checkbox'}
            id={`default-checkbox`}
            label={'Remember Me'}
            defaultChecked={rememberMe}
            isValid={true}
            onChange={() => setRememberMe(!rememberMe)}
          />

          <Button
            onClick={() => {
              navigate(ROUTE_NAMES.authChildren.forgotPassword)
            }}
            variant="link"
            className="forgotPassword"
          >
            Forgot Password?
          </Button>
        </div>
        <PrimaryButton
          title="Login"
          type="submit"
          className="w-75 align-self-center"
        />
      </Form>
    )
  }

  return (
    <Container fluid>
      {isLoading && <Loader />}
      <Row>
        <Col
          //className="vh100  d-flex justify-content-center align-items-center"
          className="loginContainer p-0"
        >
          <div>
            <Logo />
            <h2 className="header"> Log In As</h2>
            <ButtonGroup className="mb-2 mt-4 w-100">
              {chips.map((radio, idx) => (
                <ToggleButton
                  key={idx}
                  id={`radio-${idx}`}
                  type="checkbox"
                  variant="outline-primary"
                  value={radio.value}
                  checked={userType === radio.value}
                  onChange={e => setUserType(e.currentTarget.value)}
                  className={`tab ${
                    userType === radio.value ? 'tabSelected' : 'tabUnSelected'
                  }${idx === chips.length - 1 ? ' me-0' : ''}`}
                >
                  {radio.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
            <Formik
              initialValues={initialValue}
              onSubmit={values => handleSubmit(values)}
              validationSchema={loginFieldsSchema}
            >
              {renderLoginForm}
            </Formik>
            {userType === userTypes.COMPANY && (
              <div className="mt-4 contactDetails">
                <div>Want to join us at Waterfront?</div>
                <div className="d-flex">
                  <div>Reach out to us at:</div>
                  <div className="contact">
                    {contactUs?.data.contact_number}
                  </div>
                </div>
                <div className="d-flex">
                  <div>or email us as at:</div>
                  <div className="contact">{contactUs?.data.contact_email}</div>
                </div>
              </div>
            )}
          </div>
        </Col>
        <Col className="vh100 imageContainer p-0">
          <img src={Backdrop} className="image" alt="" />
        </Col>
      </Row>
    </Container>
  )
}

export default PageLogin
