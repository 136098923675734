import React from "react"
import "./section-heading.scss"
import { Container } from "react-bootstrap";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ReactComponent as ExpandIcon } from '../../../../assets/images/svg/expand.svg';
interface Props {
  title: string;
  smallHeading?: boolean;
  disclaimer?: string;
  children?: any;
}

const SectionHeading = ({ title, smallHeading, disclaimer, children }: Props) => {
  return (<div className="seaction-heading-container">
    <Accordion className="accordian"
      sx={{
        '&.MuiAccordionSummary-content': {
            m: 0, // margin
        }
      }}
    >
      <AccordionSummary
        aria-controls="panel1-content"
        id="panel1-header"       
        expandIcon={<ExpandIcon />} 
      >
        <h2 className={smallHeading ? 'section-small-heading' : "section-heading"}>
          <span>{title}</span>
          {disclaimer && <span className="disclaimer"> - {disclaimer}</span>}
        </h2>        
        </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  </div>
  )
}

export default SectionHeading
