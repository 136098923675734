import React, { SVGProps } from 'react'

const DeleteIcon = (props: SVGProps<SVGSVGElement>) => {
  const { color = '#FFF' } = props
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99996 25.3333C7.99996 26.8 9.19996 28 10.6666 28H21.3333C22.8 28 24 26.8 24 25.3333V12C24 10.5333 22.8 9.33333 21.3333 9.33333H10.6666C9.19996 9.33333 7.99996 10.5333 7.99996 12V25.3333ZM12 12H20C20.7333 12 21.3333 12.6 21.3333 13.3333V24C21.3333 24.7333 20.7333 25.3333 20 25.3333H12C11.2666 25.3333 10.6666 24.7333 10.6666 24V13.3333C10.6666 12.6 11.2666 12 12 12ZM20.6666 5.33333L19.72 4.38667C19.48 4.14667 19.1333 4 18.7866 4H13.2133C12.8666 4 12.52 4.14667 12.28 4.38667L11.3333 5.33333H7.99996C7.26663 5.33333 6.66663 5.93333 6.66663 6.66667C6.66663 7.4 7.26663 8 7.99996 8H24C24.7333 8 25.3333 7.4 25.3333 6.66667C25.3333 5.93333 24.7333 5.33333 24 5.33333H20.6666Z"
        fill="#BF0000"
      />
    </svg>
  )
}

export default DeleteIcon
