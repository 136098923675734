import { useEffect, useState } from 'react'
import Table from '../../../components/table/table'
import { useLazyAllDriversQuery } from '../../../api/network/userApiService'
import {
  DEBOUNCE_TIME,
  userStatus,
  USER_STATUS,
  TRUCK_STATUSES,
  truckStatus,
  TRUCK_STATUS
} from '../../../utils/constants'
import { ReactComponent as VisibilityIcon } from '../../../assets/images/svg/visibility.svg'
import { useNavigate } from 'react-router-dom'
import { ROUTE_NAMES } from '../../../utils/route/routerNames'
import useDebounce from '../../../utils/hooks/useDedounce'

import { FilterValue } from 'react-table'
import Filter from '../../../components/formFilter/formFilter'
//import { Filter_Obj } from '../../../components/formFilter/formFilter/types'
import { getStatusFilterKeys } from '../../../utils/helper'
import { useLazyAllTrucksQuery } from '../../../api/network/truckApiServices'
import { ITruckTable } from './types'
import { Filter_Obj } from '../../../components/formFilter/types'

const TruckList = (props: ITruckTable) => {
  const { search = '', trucks } = props

  const [page, setPage] = useState(1)
  const [statusFilter, setStatusFilter] = useState<Filter_Obj[]>([])

  const navigate = useNavigate()
  const debouncedValue = useDebounce(search, DEBOUNCE_TIME)
  const [allTrucksTrigger, { data, isLoading }] = useLazyAllTrucksQuery() // Provide an empty object as the argument

  useEffect(() => {
    setPage(1)
    if (statusFilter.length) {
      allTrucksTrigger({
        search: debouncedValue,
        page: 1,
        status: getStatusFilterKeys(statusFilter, 'status')
      })
    } else {
      allTrucksTrigger({ search: debouncedValue, page: 1 })
    }
  }, [debouncedValue, statusFilter, allTrucksTrigger])

  useEffect(() => {
    allTrucksTrigger({ search: debouncedValue, page })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, allTrucksTrigger])
  // const { data, isLoading } = useAllTrucksQuery({}) // Provide an empty object as the argument

  const { next, previous, results, total_pages } = data || {}

  console.log(results)

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }
  const renderTruckStatus = (status: number) => (
    <span
      className={
        status === 1 ? 'fc-green' : status === 3 ? 'fc-orange' : 'fc-red'
      }
    >
      {TRUCK_STATUS[status]}
    </span>
  )

  function SelectColumnFilter({
    column: { filterValue, setFilter }
  }: FilterValue) {
    return (
      <Filter
        options={[
          { id: `${truckStatus.APPROVED}`, value: 'Approved' },
          { id: `${truckStatus.REJECTED}`, value: 'Rejected' },
          { id: `${truckStatus.PENDING}`, value: 'Pending' },
          { id: `${truckStatus.EXPIRED}`, value: 'Expired' }
        ]}
        filter={filterValue}
        setFilter={setFilter}
      />
    )
  }

  const columns = [
    {
      Header: 'Make & Model',
      accessor: 'make_model'
    },
    {
      Header: 'Company/Contractor',
      accessor: 'company'
    },
    {
      Header: 'Truck Number',
      accessor: 'truck_number'
    },

    {
      Header: 'License Plate',
      accessor: 'license_plate'
    },

    {
      Header: 'Allotted Driver',
      accessor: 'allocated_driver'
    },

    {
      Header: 'Status',
      accessor: (cell: any) => renderTruckStatus(cell?.status),
      defaultCanFilter: true
      // Filter: SelectColumnFilter
    },

    {
      Header: '',
      id: 'action',
      accessor: (cell: any) => (
        <VisibilityIcon
          role="button"
          onClick={() =>
            navigate(`${ROUTE_NAMES.dashboardChildren.trucks}/${cell.id}`)
          }
        />
      )
    }
  ]

  return (
    <Table
      data={results || []}
      columns={columns}
      page={page}
      setPage={setPage}
      onNext={onNext}
      onPrev={onPrev}
      pageCount={total_pages || 0}
      isLoading={isLoading}
      filter={statusFilter}
      setFilter={setStatusFilter}
    />
  )
}

export default TruckList
