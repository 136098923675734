import { useNavigate } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import { FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { DateFormat, getDateInFormat } from '../../../utils/DateUtils'
import PrimaryButton from '../../../components/primaryButton/PrimaryButton'
import { JOB_CONFIG, JOB_STATUSES } from '../../../utils/constants'
import CardWithHeader from '../../../components/cardWithHeader/CardWithHeader'
import { useState } from 'react'
import FormControl from '../../../components/formControl/FormControl'
import { jobDetail } from '../../../api/types/Jobs'
import FormSelect from '../../../components/formSelect/FormSelect'

const JobDetailBasic = ({
  jobId,
  jobDetail,
  putJobApiTrigger
}: {
  jobId: string
  jobDetail: jobDetail
  putJobApiTrigger: any
}) => {
  const navigate = useNavigate()
  const [isReadOnly, setReadOnly] = useState(true)
  const [loadtype, setLoadType] = useState([
    {
      id: '1',
      name: 'Dry'
    },
    {
      id: '2',
      name: 'Refer'
    }
  ])
  const [getSize, setSize] = useState([
    {
      id: 24,
      name: '24'
    },
    {
      id: 40,
      name: '40'
    },
    {
      id: 45,
      name: '45'
    },
    {
      id: 53,
      name: '53'
    }
  ])
  const [getHazMatType, setHazMatType] = useState([
    {
      id: 1,
      name: 'Explosives'
    },
    {
      id: 2,
      name: 'Gases'
    },
    {
      id: 3,
      name: 'Flammable Liquid & Combustible Liquid'
    },
    {
      id: 4,
      name: 'Flammable solid, Spontaneously Combustible & Dangerous When Wet'
    },
    {
      id: 5,
      name: 'Oxidizer & Organic Peroxide'
    },
    {
      id: 6,
      name: 'Poison (Toxic) & Poison Inhalation Hazard'
    },
    {
      id: 7,
      name: 'Radioactive'
    },
    {
      id: 8,
      name: 'Corrosive'
    },
    {
      id: 9,
      name: 'Miscellaneous'
    },
    {
      id: 10,
      name: 'Dangerous: Indicates a mixed load of hazardous materials'
    }
  ])

  const formatTime = (dateString: string) => {
    if (dateString == null) return null
    const dateObject = new Date(dateString)
    // const hours = dateObject.getUTCHours();
    const hours = dateObject?.getHours()
    // const minutes = dateObject.getUTCMinutes();
    const minutes = dateObject?.getMinutes()
    const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${
      minutes < 10 ? '0' : ''
    }${minutes}`
    return formattedTime
  }
  const formik = useFormik({
    initialValues: {
      company_name: jobDetail?.job_details?.company_name || '',
      posted_on: jobDetail?.job_details?.posted_on || '',
      job_requirements:
        jobDetail?.job_details?.pickdrop_details?.job_requirements || '',
      load_type: jobDetail?.job_details?.pickdrop_details?.load_type || '',
      weight: jobDetail?.job_details?.pickdrop_details?.weight || '',
      is_hazmat: jobDetail?.job_details?.pickdrop_details?.is_hazmat || false,
      type_of_hazmat:
        jobDetail?.job_details?.pickdrop_details?.type_of_hazmat || '',
      appointment_number:
        jobDetail?.job_details?.pickdrop_details?.appointment_number || '',
      time_limit: jobDetail?.job_details?.pickdrop_details?.time_limit || '',
      size: jobDetail?.job_details?.pickdrop_details?.size || '',
      appointment_date:
        jobDetail?.job_details?.pickdrop_details?.appointment_date_time?.split(
          'T'
        )[0] || '',
      appointment_time:
        formatTime(
          jobDetail?.job_details?.pickdrop_details?.appointment_date_time
        ) || '',
      starting_location:
        jobDetail?.job_details?.pickdrop_details?.starting_location || '',
      ending_location:
        jobDetail?.job_details?.pickdrop_details?.ending_location || '',
      scheduled_pick_up_time:
        jobDetail?.job_details?.pickdrop_details?.scheduled_pick_up_time || '',
      scheduled_pick_up_date:
        jobDetail?.job_details?.pickdrop_details?.scheduled_pick_up_date || '',
      container_number:
        jobDetail?.job_details?.pickdrop_details?.container_number || '',
      proposed_rate:
        jobDetail?.job_details?.financial_details?.proposed_rate || '',
      customer_priority:
        jobDetail?.job_details?.financial_details?.customer_priority || '',
      total_rate: jobDetail?.job_details?.financial_details?.total_rate || ''
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({}),
    onSubmit: (values: any) => {
      let requestData = {
        job_description: jobDetail?.job_details?.job_description,
        contact_name: 'abc',
        phone_number: '1234567000',
        visibility: 2,
        proposed_rate: jobDetail?.job_details?.financial_details?.proposed_rate,
        customer_priority:
          jobDetail?.job_details?.financial_details?.customer_priority,
        total_rate: jobDetail?.job_details?.financial_details?.total_rate,
        job_requirements:
          jobDetail?.job_details?.pickdrop_details?.job_requirements,
        load_type: jobDetail?.job_details?.pickdrop_details?.load_type,
        weight: jobDetail?.job_details?.pickdrop_details?.weight,
        appointment_date:
          jobDetail?.job_details?.pickdrop_details?.appointment_date_time?.split(
            'T'
          )[0],
        appointment_time: formatTime(
          jobDetail?.job_details?.pickdrop_details?.appointment_date_time
        ),
        size: jobDetail?.job_details?.pickdrop_details?.size,
        starting_location:
          jobDetail?.job_details?.pickdrop_details?.starting_location,
        ending_location:
          jobDetail?.job_details?.pickdrop_details?.ending_location,
        starting_location_latitude:
          jobDetail?.job_details?.pickdrop_details?.starting_location_latitude,
        starting_location_longitude:
          jobDetail?.job_details?.pickdrop_details?.starting_location_longitude,
        ending_location_latitude:
          jobDetail?.job_details?.pickdrop_details?.ending_location_latitude,
        ending_location_longitude:
          jobDetail?.job_details?.pickdrop_details?.ending_location_longitude,
        scheduled_pick_up_date:
          jobDetail?.job_details?.pickdrop_details?.scheduled_pick_up_date,
        scheduled_pick_up_time:
          jobDetail?.job_details?.pickdrop_details?.scheduled_pick_up_time,
        container_number:
          jobDetail?.job_details?.pickdrop_details?.container_number,
        time_limit: jobDetail?.job_details?.pickdrop_details?.time_limit,
        ...values,
        id: jobId
      }
      putJobApiTrigger(requestData)
        .unwrap()
        .then((response: any) => {
          toast.success(response.message)
          formik.resetForm()
        })
        .catch((e: any) => {})
    }
  })

  return (
    <FormikProvider value={formik}>
      <CardWithHeader
        title={`Details`}
        rightText={
          JOB_CONFIG.showTrackingForStatuses.indexOf(
            jobDetail?.job_details?.status
          ) > -1
            ? JOB_STATUSES[
                jobDetail?.job_details?.status ?? 1
              ].label.toUpperCase()
            : ''
        }
        backgroundColorName={
          JOB_CONFIG.editableForStatuses.indexOf(
            jobDetail?.job_details?.status
          ) > -1
            ? 'blue'
            : JOB_STATUSES[jobDetail?.job_details?.status ?? 1].status_class
        }
      >
        <Form className="mt-3" onSubmit={formik.handleSubmit}>
          <div className="d-flex justify-content-between align-item-center text-break">
            <div className="job-detail_account d-flex">
              {/* <div className="avatar me-2"></div> */}
              <div className="account d-flex flex-column justify-content-center">
                <div className="full-name">
                  {jobDetail?.job_details?.job_description}
                </div>
                {/* <div className="company">
                {jobDetail?.job_details?.company_name}
              </div> */}
                <div
                  className={`status fc-${
                    JOB_STATUSES[jobDetail?.job_details?.status ?? 1]
                      .status_class
                  }`}
                >
                  {JOB_STATUSES[jobDetail?.job_details?.status ?? 1].label}
                </div>
              </div>
            </div>
          </div>
          {jobDetail?.job_details?.requested_truck_reason && (
            <FormControl
              placeholder="Reason"
              value={jobDetail?.job_details?.requested_truck_reason}
              readOnly={true}
              disabled={true}
              showLabel={true}
            />
          )}
          <FormControl
            placeholder="Job ID"
            value={jobDetail?.job_details?.id}
            readOnly={true}
            disabled={true}
            showLabel={true}
          />
          <FormControl
            placeholder="Job Posted On"
            controlId="validatePosted"
            onChange={(e: any) =>
              formik.setFieldValue('posted_on', e.target.value)
            }
            errorMessage={formik.errors.posted_on}
            value={getDateInFormat(
              new Date(formik.values.posted_on),
              DateFormat.DDMMMYYYY1
            )}
            isInvalid={!!formik.errors.posted_on}
            disabled={true}
            showLabel={true}
          />
          <FormControl
            placeholder="Posted By"
            controlId="validateCompany"
            onChange={(e: any) =>
              formik.setFieldValue('company_name', e.target.value)
            }
            errorMessage={formik.errors.company_name}
            value={formik.values.company_name}
            isInvalid={!!formik.errors.company_name}
            disabled={true}
            showLabel={true}
          />
          {/* <FormControl
            placeholder="Job Requirment"
            controlId="validateJob"
            onChange={(e: any) =>
              formik.setFieldValue('job_requirements', e.target.value)
            }
            errorMessage={formik.errors.job_requirements}
            value={formik.values.job_requirements}
            isInvalid={!!formik.errors.job_requirements}
            disabled={isReadOnly}
            showLabel={true}
          /> */}
          <FormSelect
            placeholder="Type of Load"
            controlId="validateloadType"
            onChange={e => formik.setFieldValue('load_type', e.target.value)}
            errorMessage={formik.errors.load_type}
            value={formik.values.load_type}
            isInvalid={!!formik.errors.load_type}
            showLabel={true}
            disabled={isReadOnly}
          >
            {loadtype?.map(ele => {
              return <option value={ele.id}>{ele.name}</option>
            })}
          </FormSelect>

          <FormControl
            placeholder="Weight"
            controlId="validateWeight"
            onChange={e => {
              const inputValue = e.target.value
              // Use a regex to allow only numeric input
              if (/^\d*$/.test(inputValue)) {
                formik.setFieldValue('weight', inputValue)
              }
            }}
            errorMessage={formik.errors.weight}
            disabled={isReadOnly}
            value={formik.values.weight}
            isInvalid={!!formik.errors.weight}
            showLabel={true}
          />

          <Form.Group
            className="form-field form-field-- col-sm-12 position-relative"
            controlId="is_hazmat"
          >
            <Form.Label className="text-primary">Hazmat</Form.Label>

            <Form.Check
              type="checkbox"
              id="is_hazmat"
              label="Hazmat"
              // value="true"
              disabled={isReadOnly}
              checked={formik.values.is_hazmat}
            />
          </Form.Group>

          <FormSelect
            placeholder="Type of Hazmat"
            controlId="validateTypeOfHazmat"
            onChange={e =>
              formik.setFieldValue('type_of_hazmat', parseInt(e.target.value))
            }
            errorMessage={formik.errors.type_of_hazmat}
            value={formik.values.type_of_hazmat}
            isInvalid={!!formik.errors.type_of_hazmat}
            showLabel={true}
            disabled={isReadOnly}
          >
            {getHazMatType?.map(ele => {
              return <option value={ele.id}>{ele.name}</option>
            })}
          </FormSelect>

          <FormControl
            placeholder="Size (feet)"
            controlId="validateSize"
            onChange={e => {
              const inputValue = e.target.value
              // Use a regex to allow only numeric input
              if (/^\d*$/.test(inputValue)) {
                formik.setFieldValue('weight', inputValue)
              }
            }}
            errorMessage={formik.errors.size}
            disabled={isReadOnly}
            value={formik.values.size}
            isInvalid={!!formik.errors.size}
            showLabel={true}
          />
          {/* <FormSelect
            placeholder="Size (feet)"
            controlId="validateSize"
            onChange={e => formik.setFieldValue('size', e.target.value)}
            errorMessage={formik.errors.size}
            value={formik.values.size}
            isInvalid={!!formik.errors.size}
            showLabel={true}
            disabled={isReadOnly}
          >
            {getSize?.map(ele => {
              return <option value={ele.id}>{ele.name}</option>
            })}
          </FormSelect> */}

          <FormControl
            type="date"
            placeholder="Appointment Date"
            controlId="validateAppDate"
            disabled={isReadOnly}
            onChange={e =>
              formik.setFieldValue('appointment_date', e.target.value)
            }
            errorMessage={formik.errors.appointment_date}
            value={formik.values.appointment_date}
            isInvalid={!!formik.errors.appointment_date}
            min={new Date().toISOString().split('T')[0]}
            showLabel={true}
          />
          <FormControl
            type="time"
            placeholder="Appointment Time"
            controlId="validateAppTime"
            disabled={isReadOnly}
            onChange={e =>
              formik.setFieldValue('appointment_time', e.target.value)
            }
            errorMessage={formik.errors.appointment_time}
            value={formik.values.appointment_time}
            isInvalid={!!formik.errors.appointment_time}
            showLabel={true}
          />
          <FormControl
            placeholder="Appointment Number"
            controlId="validateAppNumber"
            disabled={isReadOnly}
            onChange={e => {
              const inputValue = e.target.value
              // Use a regex to allow only numeric input
              if (/^\d*$/.test(inputValue)) {
                formik.setFieldValue('appointment_number', inputValue)
              }
            }}
            errorMessage={formik.errors.appointment_number}
            value={formik.values.appointment_number}
            isInvalid={!!formik.errors.appointment_number}
            showLabel={true}
          />
          <FormControl
            placeholder="Time Limit(Hrs)"
            controlId="validateTimeLimit"
            disabled={isReadOnly}
            onChange={e => {
              const inputValue = e.target.value
              // Use a regex to allow only numeric input
              if (/^\d*$/.test(inputValue)) {
                formik.setFieldValue('appointment_number', inputValue)
              }
            }}
            errorMessage={formik.errors.time_limit}
            value={formik.values.time_limit}
            isInvalid={!!formik.errors.time_limit}
            showLabel={true}
          />
          <FormControl
            type="locationAutocomplete"
            placeholder="Starting Location"
            onTypeaheadChange={(selectedLocation: any) => {
              formik.setFieldValue(
                'starting_location',
                JSON.stringify(selectedLocation)
              )
            }}
            errorMessage={formik.errors.starting_location}
            value={formik.values.starting_location}
            isInvalid={!!formik.errors.starting_location}
            disabled={isReadOnly}
            showLabel={true}
          />
          <FormControl
            type="locationAutocomplete"
            placeholder="End Location"
            onTypeaheadChange={(selectedLocation: any) => {
              formik.setFieldValue(
                'ending_location',
                JSON.stringify(selectedLocation)
              )
            }}
            errorMessage={formik.errors.ending_location}
            value={formik.values.ending_location}
            isInvalid={!!formik.errors.ending_location}
            disabled={isReadOnly}
            showLabel={true}
          />
          <FormControl
            placeholder="Scheduled Pick Up Date"
            type="date"
            controlId="validateDate"
            onChange={e =>
              formik.setFieldValue('scheduled_pick_up_date', e.target.value)
            }
            errorMessage={formik.errors.scheduled_pick_up_date}
            value={formik.values.scheduled_pick_up_date}
            isInvalid={!!formik.errors.scheduled_pick_up_date}
            disabled={isReadOnly}
            showLabel={true}
          />
          <FormControl
            placeholder="Scheduled Pick Up Time"
            type="time"
            controlId="validateTime"
            onChange={e =>
              formik.setFieldValue('scheduled_pick_up_time', e.target.value)
            }
            errorMessage={formik.errors.scheduled_pick_up_time}
            value={formik.values.scheduled_pick_up_time}
            isInvalid={!!formik.errors.scheduled_pick_up_time}
            disabled={isReadOnly}
            showLabel={true}
          />
          <FormControl
            placeholder="Container Number"
            controlId="validateContainer"
            onChange={e =>
              formik.setFieldValue('container_number', e.target.value)
            }
            errorMessage={formik.errors.container_number}
            value={formik.values.container_number}
            isInvalid={!!formik.errors.container_number}
            disabled={isReadOnly}
            showLabel={true}
          />
          {JOB_CONFIG.showTrackingForStatuses.indexOf(
            jobDetail?.job_details?.status
          ) > -1 && (
            <>
              <FormControl
                placeholder="Contact Name"
                controlId="validateContainer"
                value={jobDetail?.job_details?.contact_name}
                disabled={isReadOnly}
                showLabel={true}
              />
              <FormControl
                placeholder="Phone Number"
                controlId="validateContainer"
                value={jobDetail?.job_details?.phone_number}
                disabled={isReadOnly}
                showLabel={true}
              />
            </>
          )}
          {isReadOnly ? (
            ''
          ) : (
            <PrimaryButton
              title="Update"
              type="submit"
              className="w-100 align-self-center"
            />
          )}
        </Form>
      </CardWithHeader>
    </FormikProvider>
  )
}
export default JobDetailBasic
