import React, { PureComponent } from 'react'
import { Label, PieChart, Pie, ResponsiveContainer } from 'recharts'
import { APPCONFIG } from '../../app-config'
import PieChartActiveShape from './pieChartActiveShape'

import './pieChartWithLegend.scss'

type Datum = {
  name: string
  value: number
  fill?: string
}
type Props = {
  data: Datum[]
  interactiveLabel?: boolean
  maxHeight?: any
  showLegend?: boolean
  totalCountLabel?: string
  totalCountValue?: number
}
type State = {
  activeIndex?: number
}

export default class PieChartWithLegend extends PureComponent<Props, State> {
  state = {
    activeIndex: 0
  }

  onPieEnter = (_: any, index: number) => {
    this.setState({
      activeIndex: index
    })
  }

  render() {
    return (
      <div className="pie-chart-w-legend">
        <div className="pie-chart" style={{ height: this.props.maxHeight }}>
          <ResponsiveContainer
            width="100%"
            height="100%"
            maxHeight={this.props.maxHeight ?? 400}
          >
            <PieChart width={400} height={400}>
              <Pie
                {...(this.props.interactiveLabel
                  ? {
                      activeIndex: this.state.activeIndex,
                      activeShape: PieChartActiveShape
                    }
                  : { label: item => item.value, labelLine: false })}
                data={this.props.data.filter(item => item.value > 0)}
                cx="50%"
                cy="50%"
                innerRadius={65}
                outerRadius={100}
                fill="#D00"
                dataKey="value"
                onMouseEnter={this.onPieEnter}
                fontSize={16}
                fontWeight={'bold'}
              >
                {!!this.props.totalCountLabel && (
                  <>
                    <Label
                      value={this.props.totalCountLabel}
                      position="center"
                      dy={-6}
                      fill={APPCONFIG.COLORS.grey_444}
                      fontSize={14}
                      fontWeight={600}
                    />
                    <Label
                      value={
                        this.props.totalCountValue ??
                        this.props.data.reduce(
                          (total, item) => total + item.value,
                          0
                        )
                      }
                      position="center"
                      dy={14}
                      fill={APPCONFIG.COLORS.vividCerulean}
                      fontSize={20}
                      fontWeight={600}
                    />
                  </>
                )}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        {!!this.props.showLegend && (
          <div className="legend">
            {this.props.data.map((datum, i) => (
              <div className="item" key={i}>
                <span className="color">
                  <span style={{ backgroundColor: datum.fill }}></span>
                </span>
                <div className="name">{datum.name}</div>
                <div className="value">{datum.value}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }
}
