import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'radar-sdk-js/dist/radar.css'
import './App.css'
import { RouterProvider } from 'react-router-dom'
import router from './utils/route/routers'
import { Provider } from 'react-redux'
import { store } from './api/store/store'
import { ToastContainer } from 'react-toastify'

function App() {
  return (
    <div>
      <Provider store={store}>
        <RouterProvider router={router} />
        <ToastContainer position="top-right" theme="colored" />
      </Provider>
    </div>
  )
}

export default App
