import React, { SVGProps } from 'react'

function TruckIcon(props: SVGProps<SVGSVGElement>) {
  const { color = '#fff' } = props

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 8H17.5V6C17.5 4.9 16.6 4 15.5 4H3.5C2.4 4 1.5 4.9 1.5 6V15C1.5 16.1 2.4 17 3.5 17C3.5 18.66 4.84 20 6.5 20C8.16 20 9.5 18.66 9.5 17H15.5C15.5 18.66 16.84 20 18.5 20C20.16 20 21.5 18.66 21.5 17H22.5C23.05 17 23.5 16.55 23.5 16V12.67C23.5 12.24 23.36 11.82 23.1 11.47L20.8 8.4C20.61 8.15 20.31 8 20 8ZM6.5 18C5.95 18 5.5 17.55 5.5 17C5.5 16.45 5.95 16 6.5 16C7.05 16 7.5 16.45 7.5 17C7.5 17.55 7.05 18 6.5 18ZM20 9.5L21.96 12H17.5V9.5H20ZM18.5 18C17.95 18 17.5 17.55 17.5 17C17.5 16.45 17.95 16 18.5 16C19.05 16 19.5 16.45 19.5 17C19.5 17.55 19.05 18 18.5 18Z"
        fill={color}
      />
    </svg>
  )
}

export default TruckIcon
