import {
  ButtonGroup,
  Col,
  Container,
  Form,
  Row,
  ToggleButton
} from "react-bootstrap"
import { FormikProvider, useFormik } from "formik"
import FormControl from "../../../components/formControl/FormControl"
import { addCompanySchema } from "../../../utils/Validations"
import CardWithHeader from "../../../components/cardWithHeader/CardWithHeader"
import PageActionsBar from "../../../layout/dashboardLayout/pageActionsBar"
import PrimaryButton from "../../../components/primaryButton/PrimaryButton"
import { companyType, companyTypes, userTypes } from "../../../utils/constants"
import { IAddUser } from "../add-user/types"
import { useAddUserMutation } from "../../../api/network/userApiService"
import { toast } from "react-toastify"
import Loader from "../../../components/loader/Loader"
import { AddUserPayload, IDNameResponse } from "../../../api/types/Driver"
import FormSelect from "../../../components/formSelect/FormSelect"
import DetailHeader from "../../jobs/jobDetail/header/header"

const PageAddCompany = (props: IAddUser) => {
  const { userType, setUserType } = props
  const [addUserApiTrigger, { isLoading }] = useAddUserMutation()



  const initialValues: AddUserPayload = {
    //name: "",
    email: "",
    confirmEmail: "",
    phone_number: "",
    company_name: "",
    contact_person_name: "",
    user_type: Number(userType),
    company_type: "",
    street_address: "",
    city: "",
    zip_code: "",
    country: "",
    state: ""
  }
  const formik = useFormik({
    initialValues,
    validationSchema: addCompanySchema,
    onSubmit: (values: AddUserPayload) => {
      addUserApiTrigger(values)
        .unwrap()
        .then(response => {
          toast.success(response.message)
          formik.resetForm()
        })
        .catch(e => {})
    }
  })
  const chips = [
    { name: "Company", value: userTypes.COMPANY }
  ]
  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Container fluid>
          {isLoading && <Loader />}
          <Row>
            <Col>
              <DetailHeader
                title="Add User"
              />
              <PageActionsBar
                wrapperClassName={`mt-4 page-actions-bar-single`}
                ContentLeft={() => (
                  <>
                    <ButtonGroup>
                      {chips.map((radio, i) => (
                        <ToggleButton
                          key={i}
                          id={`radio-${i}`}
                          type="checkbox"
                          variant="outline-primary"
                          value={radio.value}
                          checked={userType === radio.value}
                          onChange={e => setUserType(e.currentTarget.value)}
                          className={`tab ${
                            userType === radio.value
                              ? "tabSelected"
                              : "tabUnSelected"
                          }`}
                        >
                          {radio.name}
                        </ToggleButton>
                      ))}
                    </ButtonGroup>
                  </>
                )}
                ContentRight={() => (
                  <div>
                    <PrimaryButton
                      title="Create"
                      type="submit"
                    />
                  </div>
                )}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={4}>
              <CardWithHeader title={"Personal Details"}>
                <div>
                  {/* <FormControl
                    placeholder="Full Name"
                    controlId="validateName"
                    onChange={e => formik.setFieldValue("name", e.target.value)}
                    errorMessage={formik.errors.name}
                    value={formik.values.name}
                    isInvalid={!!formik.errors.name}
                  /> */}
                  <FormControl
                    type="email"
                    placeholder="Email"
                    controlId="validateEmail"
                    onChange={e =>
                      formik.setFieldValue("email", e.target.value)
                    }
                    errorMessage={formik.errors.email}
                    value={formik.values.email}
                    isInvalid={!!formik.errors.email}
                    showLabel={true}
                  />
                   <FormControl
                    type="email"
                    placeholder="Confirm Email"
                    controlId="validateEmail"
                    onChange={e =>
                      formik.setFieldValue("confirmEmail", e.target.value)
                    }
                    errorMessage={formik.errors.confirmEmail}
                    value={formik.values.confirmEmail}
                    isInvalid={!!formik.errors.confirmEmail}
                    showLabel={true}
                  />
                  <FormControl
                    type="number"
                    placeholder="Phone"
                    controlId="validatePhone"
                    onChange={e =>
                      formik.setFieldValue("phone_number", e.target.value)
                    }
                    errorMessage={formik.errors.phone_number}
                    value={formik.values.phone_number}
                    isInvalid={!!formik.errors.phone_number}
                    showLabel={true}
                  />
                </div>
              </CardWithHeader>
            </Col>
            <Col md={4}>
              <CardWithHeader title={"Company Details"}>
                <div>
                  <FormControl
                    placeholder="Company Name"
                    controlId="validateCompanyName"
                    onChange={e =>
                      formik.setFieldValue("company_name", e.target.value)
                    }
                    errorMessage={formik.errors.company_name}
                    value={formik.values.company_name}
                    isInvalid={!!formik.errors.company_name}
                    showLabel={true}
                  />
                  <FormSelect
                    placeholder="Company Type"
                    controlId="validateCompanyType"
                    onChange={e =>
                      formik.setFieldValue("company_type", e.target.value)
                    }
                    errorMessage={formik.errors.company_type}
                    value={formik.values.company_type}
                    isInvalid={!!formik.errors.company_type}
                    showLabel={true}
                  >
                    {companyType?.map((ele: IDNameResponse) => {
                      return <option value={ele.id}>{ele.name}</option>
                    })}
                  </FormSelect>
                  <FormControl
                    placeholder="Contact Person Name"
                    controlId="validateCompanyPersonName"
                    onChange={e =>
                      formik.setFieldValue(
                        "contact_person_name",
                        e.target.value
                      )
                    }
                    errorMessage={formik.errors.contact_person_name}
                    value={formik.values.contact_person_name}
                    isInvalid={!!formik.errors.contact_person_name}
                    showLabel={true}
                  />
                </div>
              </CardWithHeader>
            </Col>
            <Col md={4}>
              <CardWithHeader title="Address Details">
                <FormControl
                  placeholder="Address"
                  controlId="validateAddress"
                  onChange={e =>
                    formik.setFieldValue("street_address", e.target.value)
                  }
                  errorMessage={formik.errors.street_address}
                  value={formik.values.street_address}
                  isInvalid={!!formik.errors.street_address}
                  showLabel={true}
                />
                <FormControl
                  placeholder="City"
                  controlId="validateCity"
                  onChange={e => formik.setFieldValue("city", e.target.value)}
                  errorMessage={formik.errors.city}
                  value={formik.values.city}
                  isInvalid={!!formik.errors.city}
                  showLabel={true}
                />
                <FormControl
                  placeholder="Zip Code"
                  controlId="validateZip"
                  onChange={e =>
                    formik.setFieldValue("zip_code", e.target.value)
                  }
                  errorMessage={formik.errors.zip_code}
                  value={formik.values.zip_code}
                  isInvalid={!!formik.errors.zip_code}
                  showLabel={true}
                />
                <FormControl
                  placeholder="State"
                  controlId="validateState"
                  onChange={e => formik.setFieldValue("state", e.target.value)}
                  errorMessage={formik.errors.state}
                  value={formik.values.state}
                  isInvalid={!!formik.errors.state}
                  showLabel={true}
                />
                <FormControl
                  placeholder="Country"
                  controlId="validateCountry"
                  onChange={e =>
                    formik.setFieldValue("country", e.target.value)
                  }
                  errorMessage={formik.errors.country}
                  value={formik.values.country}
                  isInvalid={!!formik.errors.country}
                  showLabel={true}
                />
              </CardWithHeader>
            </Col>
          </Row>
        </Container>
      </Form>
    </FormikProvider>
  )
}

export default PageAddCompany
