import FormControl from '../formControl/FormControl'
import { ISearch } from './type'
import { ReactComponent as SearchIcon } from '../../assets/images/svg/search.svg'
import './formSearch.scss'

const FormSearch = (props: ISearch) => {
  const {
    search,
    setSearch,
    className,
    containerClassName,
    formControlWrapperClassName = ''
  } = props
  return (
    <div className={`form-search ${containerClassName ?? ''}`}>
      <FormControl
        fieldClassName={formControlWrapperClassName}
        wrapperClassName={formControlWrapperClassName}
        placeholder="Search"
        controlId="validateSearch1"
        value={search}
        onChange={e => setSearch(e.target.value)}
        className={className}
        FieldPreFC={() => <SearchIcon />}
      />
    </div>
  )
}

export default FormSearch
