import React, {JSXElementConstructor, ReactElement, ReactNode, ReactPortal, useEffect, useState} from 'react'
import { Col } from 'react-bootstrap'
import { APPCONFIG } from '../../app-config'
import Logo from '../../assets/images/svg/Logo';
import { Menu, MenuItem } from '@mui/material';
import { ROUTE_NAMES } from '../../utils/route/routerNames'
import {
  fetchAccountInfo,
  fetchUserData,
  fetchUserType
} from '../../utils/storage'
import {
  useFetchNotificationsQuery,
  useReadAllNotificationsMutation,
  useReadSpecificNotificationMutation
} from '../../api/network/notificationsApiService';
import { ToastContentProps, toast } from 'react-toastify'
import {
  notiPayload,
} from '../../api/types/Notifications';
import { userTypes } from '../../utils/constants'

import './header.scss'
import NotificationIcon from '../../assets/images/svg/NotificationIcon'

interface NotificationData {
  id?: number;
  is_read?: boolean;
}

const DashHeader = () => {
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const userDetail = fetchUserData()
  const accountInfo = fetchAccountInfo()
  const { data: notifications, isLoading } = useFetchNotificationsQuery(undefined);
  const [getNotificationsData, setNotificationsData]= useState(notifications || []);
  useEffect(() =>{
    setNotificationsData(notifications || []);
    // setNotificationsData([]);
  }, [notifications]);
  const [notiSingleReadAPITriger, { isLoading: notiLoading }] =
  useReadSpecificNotificationMutation()
  const [notiReadAllAPITriger, { isLoading: notiAllLoading }] =
  useReadAllNotificationsMutation()
  const userData = localStorage?.getItem('user');
  const userDataType = userData ? JSON.parse(userData).user?.user_type : null;


  const [anchorE2, setAnchorE2] = useState<HTMLElement | null>(null);

  const handleNotificationsMenuOpen = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorE2(event.currentTarget);
    setNotificationsOpen(true);
  };

const handleNotificationsMenuClose = () => {
  setAnchorE2(null);
  setNotificationsOpen(false);
};

const handleReadNoti= (data: NotificationData) =>{
  const suspendPayload: notiPayload = {
    id: Number(data?.id),
    is_read: Boolean(true)
  }
  notiSingleReadAPITriger(suspendPayload)
    .unwrap()
    .then((response: any) => {
      // toast.success('Notification read successfully');
      let arr=[];
      arr?.push(response);
      const updatedArr = getNotificationsData?.map((item: any) => {
        if (item?.id === response?.id) {
          // If the ids match, update the item with values from the response
          return { ...item, ...response };
        }
        return item;
      });
      setNotificationsData(updatedArr);
    })
    .catch(_e => {})
}

const handleReadAllNoti= (data: NotificationData) =>{
  const suspendPayload: notiPayload = {
    id: Number(data?.id),
    is_read: Boolean(true)
  }
  notiReadAllAPITriger(suspendPayload)
    .unwrap()
    .then((_response: any) => {      
      // toast.success('All notifications read successfully');
      setNotificationsData(_response);
    })
    .catch(_e => {})
}

  return (
    <>
    <Col className="d-flex align-items-center justify-content-between dash-header">
      <a
        className="d-flex logo-anchor ms-4"
        href={ROUTE_NAMES.dashboard}
        title={APPCONFIG.APPTITLE}
      >
        <Logo />
      </a>
      <div className="dash-header__account d-flex">        
      {
        userDataType===2 &&
      <NotificationIcon className='noti-icon' style={{ filter: 'brightness(25%)', cursor: 'pointer' }} 
      onClick={handleNotificationsMenuOpen as unknown as React.MouseEventHandler<SVGSVGElement>}
       />       
      }
      <>
        {fetchUserType() === userTypes.COMPANY ?
        <div
          className="avatar me-2"
          style={{
            backgroundImage: `url(${accountInfo?.user_profile?.profile_photo})`
          }}
        ></div> : "" }
        </>
        <div className="details d-flex flex-column justify-content-center">
          <div className="d-flex flex-row justify-content-center">
            <div className="welcome">Welcome</div>
            <div className="full-name ps-1">{userDetail?.user?.name ?? ''}</div>
          </div>
          <div className="user-role">
            {fetchUserType() === userTypes.COMPANY ? 'Company' : 'Admin'}
          </div>
        </div>
      </div>
    </Col>
    <Menu
                anchorEl={anchorE2}
                open={notificationsOpen}
                onClose={handleNotificationsMenuClose}
                className="notifications-wrapper-1">
                  
                <MenuItem className="noti-heading">
                  <div className='noti-head-wrapper'>
                    <div className='noti-head'>Notifications</div>
                  </div>
                  </MenuItem> 
                <>
                  {
                    getNotificationsData?.map((item: any, _index: number)=>
                    <MenuItem
                    className="menu-item-noti">
                    <div className="notification-items-wrapper d-flex align-items-center w-100"  onClick={() => handleReadNoti(item)}>
                      <div className={item?.is_read===false ? 'green-dot' : 'green-dot-1'}></div>
                      <div className='menu-div-wrapper'>
                        <div className="menu-item-div1" key={item?.id}>
                        {item?.title}
                          </div>
                          <div className="menu-item-msg" key={item?.id}>
                        {item?.message}
                          </div>
                          </div>
                          </div>
                          </MenuItem>   
                    )
                  } 
                  {
                    getNotificationsData.length ===0 &&
                    <MenuItem
                    className="menu-item-noti no-record-exists">
                      No Record Exists
                    </MenuItem>
                  }
                  </> 
                  {
                    getNotificationsData.length>0 &&
                  <div className='noti-markall' onClick={() => handleReadAllNoti({})}>Mark all as Read</div>
                }                 
                         
            </Menu>
    </>
  )
}
export default DashHeader
