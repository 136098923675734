import { useEffect, useState } from 'react'
import Table from '../../../components/table/table'
import { DEBOUNCE_TIME, JOB_STATUSES } from '../../../utils/constants'
import { ReactComponent as VisibilityIcon } from '../../../assets/images/svg/visibility.svg'
import { useNavigate } from 'react-router-dom'
import { ROUTE_NAMES } from '../../../utils/route/routerNames'
import useDebounce from '../../../utils/hooks/useDedounce'
import { FilterValue } from 'react-table'
import Filter from '../../../components/formFilter/formFilter'
import { Filter_Obj } from '../../../components/formFilter/types'
import { getStatusFilterKeys } from '../../../utils/helper'
import { useLazyFetchAllJobsQuery } from '../../../api/network/jobsApiService'
import { DateFormat, changeDateFormat } from '../../../utils/DateUtils'
import { IJobTable } from './types'
import './jobs.scss'
import { displayPickDropLocation } from '../../../utils/RadarUtils'
import { formatAmount } from '../../../utils/NumberUtils'
import { ReactComponent as HazmatIcon } from '../../../assets/images/svg/hazmat-danger.svg'

interface RenderUserStatusProps {
  status: number
}
export const RenderUserStatus:React.FC<RenderUserStatusProps> = (props) => {
  return (
    <span className={`fc-${JOB_STATUSES[props.status].status_class}`}>
      {JOB_STATUSES[props.status].label}
    </span>
  )
}

const JobTable = (props: IJobTable) => {
  const { search = '', jobs } = props
  const [page, setPage] = useState(1)
  const [statusFilter, setStatusFilter] = useState<Filter_Obj[]>([])
  const navigate = useNavigate()
  const debouncedValue = useDebounce(search, DEBOUNCE_TIME)
  const [allJobsTrigger, { data, isLoading }] = useLazyFetchAllJobsQuery()

  useEffect(() => {
    if (!jobs) {
      setPage(1)
      if (statusFilter.length) {
        allJobsTrigger({
          search: debouncedValue,
          page: 1,
          status: getStatusFilterKeys(statusFilter, 'status')
        })
      } else {
        allJobsTrigger({ search: debouncedValue, page: 1 })
      }
    }
  }, [debouncedValue, statusFilter, allJobsTrigger, jobs])

  useEffect(() => {
    if (!jobs) {
      allJobsTrigger({ search: debouncedValue, page })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, allJobsTrigger, jobs])

  const { next, previous, results, total_pages } = data || {}
  const all_jobs = results?.all_jobs ?? []

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }
  function SelectColumnFilter({
    column: { filterValue, setFilter }
  }: FilterValue) {
    return (
      <Filter
        options={Object.keys(JOB_STATUSES).map((item, i) => {
          return { id: item, value: JOB_STATUSES[parseInt(item)].label }
        })}
        filter={filterValue}
        setFilter={setFilter}
      />
    )
  }
  const columns = [
    {
      Header: 'Load #',
      accessor: (cell: any) => (
        <div className="jobs">
          <span className="text-break">{cell.id}</span>
        </div>
      )
    },
    {
      Header: 'Load Description',
      accessor: (cell: any) => (
        <div className="jobs">
          <span className="text-break">{cell.job_description}</span>
        </div>
      )
    },
    {
      Header: 'Posted On',
      // accessor: "posted_on"
      accessor: (cell: any) => (
        <span>
          {changeDateFormat(
            cell.posted_on,
            DateFormat.YYYYMMMDDHHMMSSA,
            DateFormat.DDMMMYYYY1
          )}
        </span>
      )
    },
    {
      Header: 'Location',
      accessor: (cell: any) => {
        const cellValue = cell.pickdrop_details.starting_location
        return <>{displayPickDropLocation(cellValue)}</>
      }
    },
    {
      Header: 'Pick Up Date/Time',
      // accessor: "pickdrop_details.scheduled_pick_up_date"
      accessor: (cell: any) => (
        <span>
          {changeDateFormat(
            cell.pickdrop_details.scheduled_pick_up_date +
              ' ' +
              cell.pickdrop_details.scheduled_pick_up_time,
            DateFormat.YYYYMMMDDHHMMSS,
            DateFormat.DDMMMYYYYHHMM
          )}
        </span>
      )
    },
    {
      Header: 'HazMat',
      // accessor: "pickdrop_details.scheduled_pick_up_date"
      accessor: (cell: any) => (
        <span>
          {
            cell.pickdrop_details.is_hazmat ? <div className='text-center'><HazmatIcon/> </div> : ""
          }
        </span>
      )
    },
    {
      Header: 'Status',
      accessor: (cell: any) => <RenderUserStatus status={cell?.status} />,
      defaultCanFilter: true,
      Filter: SelectColumnFilter
    },
    {
      Header: 'Time Limit',
      accessor: (cell: any) => (
        <span className="fc-blue">
          {parseInt(cell?.pickdrop_details?.time_limit) + ' hours'}
        </span>
      )
    },
    {
      Header: 'Rate',
      accessor: (cell: any) => (
        <span className="fc-blue amount">
          {formatAmount(cell?.financial_details?.total_rate)}
        </span>
      )
    },
    {
      Header: '',
      id: 'action',
      accessor: (cell: any) => (
        <VisibilityIcon
          role="button"
          onClick={() =>
            navigate(`${ROUTE_NAMES.dashboardChildren.jobs}/${cell.id}`)
          }
        />
      )
    }
  ]

  const wrapperClassName = `table-jobs-wrap mt-3`

  return jobs ? (
    <Table
      data={jobs || []}
      columns={columns}
      hidePagination={true}
      wrapperClassName={wrapperClassName}
    />
  ) : (
    <Table
      data={jobs || all_jobs || []}
      columns={columns}
      page={page}
      setPage={setPage}
      onNext={onNext}
      onPrev={onPrev}
      pageCount={total_pages || 0}
      isLoading={isLoading}
      filter={statusFilter}
      setFilter={setStatusFilter}
      wrapperClassName={wrapperClassName}
    />
  )
}

export default JobTable
