import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { AUTH_ERROR_CODE } from './ResponseCode'
import { toast } from 'react-toastify'
import { sideEffectLogOut } from '../../utils/helper'

//Handle Error and show alerts.
const handleAuthErrorCode = (error: FetchBaseQueryError) => {
  const { status } = error
  const data: any = error.data!

  if (status === 'PARSING_ERROR') {
    // Handle Error 500 / HTML Response
    toast.error(
      'An unexpected fatal error has occurred. We regret the inconvenience caused. Please contact the administrator.',
      { autoClose: false }
    )
  } else if (Number(status) === AUTH_ERROR_CODE) {
    // Handle Error 401
    toast.error(
      data?.error ??
        `Your session has expired. You are being logged out. Please login again.`
    )
    setTimeout(() => {
      sideEffectLogOut()
    }, 3000)
  } else if (data?.error) {
    toast.error(data?.error)
  } else if (
    !!status &&
    Math.floor(Number(status) / 100) === 4 &&
    !!data?.meta?.message
  ) {
    toast.error(data?.meta?.message)
  } else {
    const errors = Object.keys(data).map(errKey => {
      const values: string | string[] = data[`${errKey}`]
      return typeof values === 'string'
        ? `${errKey} : ${values}`
        : `${errKey} : ${values?.join('\n')}`
    })
    toast.error(errors?.[0])
  }
}

export { handleAuthErrorCode }
