// src/components/TomTomMap.tsx
import React, { useEffect, useRef } from 'react';
import tt, { AnyLayer, LngLatLike, Map, Marker } from '@tomtom-international/web-sdk-maps';
import tts, { CalculateRouteOptions } from "@tomtom-international/web-sdk-services";
import { ITomtomMap } from './types';

const TomTomMap = (props: ITomtomMap) => {
  const { mapContainer, mapId, marker, startMarker, endMarker, height, width, lineColor,
    lineWidth, zoom, pointToCurrentLocationOnChange } =
    props;

  const API_KEY = '96MAb4aGE5jRuVp8TtMeS8kNODymuuNJ';

  const map: React.MutableRefObject<Map | undefined> = useRef<Map | undefined>();

  const currentMarker: React.MutableRefObject<Marker | undefined> = useRef<Marker | undefined>();

  const currentTimeout: React.MutableRefObject<NodeJS.Timeout | undefined> = useRef<NodeJS.Timeout | undefined>();

  const source: LngLatLike = startMarker?.lat && startMarker?.lng ?
    [startMarker?.lng, startMarker?.lat] :
    [0, 0];

  const destination: LngLatLike = endMarker?.lat && endMarker?.lng ?
    [endMarker?.lng, endMarker?.lat] :
    [0, 0];

  const currentLocation: LngLatLike = marker?.lat && marker?.lng ?
    [marker?.lng, marker?.lat] :
    [0, 0];

  const degreesToRadians = (degrees: number) =>{
      return degrees * (Math.PI / 180);
  }
  
  const radiansToDegrees = (radians: number) =>{
      return radians * (180 / Math.PI);
  }

  const calculateBearing = (startLat: any, startLng: any, destLat: any, destLng: any) =>{
    const startLatRad = degreesToRadians(startLat);
    const startLngRad = degreesToRadians(startLng);
    const destLatRad = degreesToRadians(destLat);
    const destLngRad = degreesToRadians(destLng);

    const y = Math.sin(destLngRad - startLngRad) * Math.cos(destLatRad);
    const x = Math.cos(startLatRad) * Math.sin(destLatRad) -
              Math.sin(startLatRad) * Math.cos(destLatRad) * Math.cos(destLngRad - startLngRad);
    
    return (radiansToDegrees(Math.atan2(y, x)) + 360) % 360;
}

  const addMarker = (map: Map | undefined, location: LngLatLike, title: string, color?: string) => {
    if (!map) {
      return undefined
    }
    return new tt.Marker({
      color: color
    })
      .setLngLat(location)
      .setPopup(new tt.Popup({ offset: 35 }).setHTML(title))
      .addTo(map);
  }

  const addCurrentMarker = (map: Map | undefined, location: LngLatLike, title: string,direction: number, color?: string) => {
    if (!map) {
      return undefined
    }
    const el = document.createElement(`div`);
    el.setAttribute('id','cmarker')
    el.innerHTML = `<svg style="transform:rotate(${direction}deg);transform-origin:bottom;" width="25" height="35" viewBox="0 0 92 158" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="12.25" y="65.25" width="58" height="67" rx="5" transform="rotate(-90 12.25 65.25)" fill="#00416D"/>
    <path d="M63.25 36.25L77.75 21.25L89.25 21.25M29.25 36.25L14.75 21.25L3.25 21.25M11.25 56.25L11.25 12.25C11.25 12.25 29.9551 2.75 44.75 2.75C59.5449 2.75 81.25 12.25 81.25 12.25L81.25 56.25L11.25 56.25ZM11.25 65.25L11.25 21.25C11.25 21.25 29.9551 11.75 44.75 11.75C59.5449 11.75 81.25 21.25 81.25 21.25L81.25 65.25L11.25 65.25ZM26.25 56.25L26.25 33.25L66.25 33.25L66.25 56.25L26.25 56.25ZM34.25 12.25L33.25 7.25L59.25 7.25L57.75 12.25L44.75 11.25L34.25 12.25Z" stroke="#0F75BC" stroke-width="5" stroke-linecap="round"/>
    <rect x="8.25" y="157.25" width="82" height="76" rx="2" transform="rotate(-90 8.25 157.25)" fill="#23AAE1"/>
    <rect x="28.25" y="54.25" width="19" height="36" transform="rotate(-90 28.25 54.25)" fill="#23AAE1"/>
    <path d="M12.25 85.25L79.75 85.25" stroke="black" stroke-opacity="0.1" stroke-linecap="round"/>
    <path d="M12.25 94.25L79.75 94.25" stroke="black" stroke-opacity="0.1" stroke-linecap="round"/>
    <path d="M12.25 103.25L79.75 103.25" stroke="black" stroke-opacity="0.1" stroke-linecap="round"/>
    <path d="M12.25 112.25L79.75 112.25" stroke="black" stroke-opacity="0.1" stroke-linecap="round"/>
    <path d="M12.25 121.25L79.75 121.25" stroke="black" stroke-opacity="0.1" stroke-linecap="round"/>
    <path d="M12.25 130.25L79.75 130.25" stroke="black" stroke-opacity="0.1" stroke-linecap="round"/>
    <path d="M12.25 139.25L79.75 139.25" stroke="black" stroke-opacity="0.1" stroke-linecap="round"/>
    <path d="M12.25 148.25L79.75 148.25" stroke="black" stroke-opacity="0.1" stroke-linecap="round"/>
    </svg>
    `
    // el.innerHTML = `<svg width="30" height="30" style="transform:rotate(${direction - 90}deg)"  viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
    // <path d="M20 8H17.5V6C17.5 4.9 16.6 4 15.5 4H3.5C2.4 4 1.5 4.9 1.5 6V15C1.5 16.1 2.4 17 3.5 17C3.5 18.66 4.84 20 6.5 20C8.16 20 9.5 18.66 9.5 17H15.5C15.5 18.66 16.84 20 18.5 20C20.16 20 21.5 18.66 21.5 17H22.5C23.05 17 23.5 16.55 23.5 16V12.67C23.5 12.24 23.36 11.82 23.1 11.47L20.8 8.4C20.61 8.15 20.31 8 20 8ZM6.5 18C5.95 18 5.5 17.55 5.5 17C5.5 16.45 5.95 16 6.5 16C7.05 16 7.5 16.45 7.5 17C7.5 17.55 7.05 18 6.5 18ZM20 9.5L21.96 12H17.5V9.5H20ZM18.5 18C17.95 18 17.5 17.55 17.5 17C17.5 16.45 17.95 16 18.5 16C19.05 16 19.5 16.45 19.5 17C19.5 17.55 19.05 18 18.5 18Z" fill="#333"/>
    // </svg>
    // `;
    return new tt.Marker({
      element:el,
      offset:{x:0,y:0}
    })
      .setLngLat(location)
      .setPopup(new tt.Popup({ offset: 35 }).setHTML(title))
      .addTo(map);
  }

  const createRoute = () => {
    if (!marker) {
      map.current?.setCenter(destination)
      return;
    }
    const routeOptions: CalculateRouteOptions = {
      key: API_KEY,
      locations: [
        currentLocation,
        destination,
      ],
      travelMode: "truck",
      vehicleCommercial: true,
    };

    clearTimeout(currentTimeout.current);
    currentTimeout.current = setTimeout(() => {
      tts.services.calculateRoute(routeOptions).then((response) => {
        var geojson = response.toGeoJson();
        const existingLayer: AnyLayer | undefined = map.current?.getLayer('route');
        if (existingLayer) {
          const existingSourceId = existingLayer.id;
          map.current?.removeLayer('route');
          map.current?.removeSource(existingSourceId);
        }
        map?.current?.addLayer({
          id: "route",
          type: "line",
          source: {
            type: "geojson",
            data: geojson,
          },
          paint: {
            "line-color": lineColor || "#a51414",
            "line-width": lineWidth || 8,
          },
        });

        var bounds = new tt.LngLatBounds();
        const direction = calculateBearing(
          geojson.features[0].geometry.coordinates[0][1],
          geojson.features[0].geometry.coordinates[0][0],
          geojson.features[0].geometry.coordinates[1][1],
          geojson.features[0].geometry.coordinates[1][0]);

        if (currentMarker.current) {
          map.current && currentMarker.current.setLngLat(currentLocation).addTo(map.current)
          const el = document.getElementById('cmarker')?.children?.[0] as HTMLElement;
          if(el?.style?.transform){
            el.style.transform = `rotate(${direction}deg)`
          }
        } else {
          currentMarker.current = addCurrentMarker(map?.current, currentLocation, marker?.title || '',direction, marker?.color,);
        }  

        geojson.features[0].geometry.coordinates.forEach(function (point: any) {
          bounds.extend(tt.LngLat.convert(point)); // creates a bounding area
        });
        pointToCurrentLocationOnChange && map.current?.setCenter(currentLocation)
        // map?.current?.fitBounds(bounds, {
        //   duration: 300,
        //   padding: 50,
        //   maxZoom: 14,
        // }); // zooms the map to the searched route
      }).catch((err: any) => {
        console.log(err?.detailedError?.message);
      });

    }, 1000);
  }

  const adjustMarkerSize = (zoomLevel: number) =>{
    const iconElement = document.getElementById('cmarker')?.children?.[0] as HTMLElement;
    if (iconElement) {
      // Adjust size based on zoom level
      // Example: smaller size at lower zoom levels
      const width = zoomLevel < 8 ? 10 : zoomLevel < 12 ? 15 : 20; // Modify as per your requirement
      const height = zoomLevel < 8 ? 20 : zoomLevel < 12 ? 25 : 30; // Modify as per your requirement
      iconElement.style.width = width + 'px';
      iconElement.style.height = height + 'px';
    }    
  }

  useEffect(() => {
    // Initialize the map
    map.current = tt.map({
      key: API_KEY,
      container: mapContainer,
      center: currentLocation,
      zoom: zoom || 0,
    });


    // Add markers for current, destination and source locations
    addMarker(map.current, source, startMarker?.title || '', startMarker?.color)
    addMarker(map.current, destination, endMarker?.title || '', endMarker?.color)

    createRoute();

    map.current.on('zoomend', () => {
      adjustMarkerSize(map?.current?.getZoom() || zoom || 0);
    });

    return () => {
      // Clean up resources if needed
      map?.current?.remove();
    };
  }, []);

  useEffect(() => {
    map.current && createRoute();
  }, [marker?.lat, marker?.lng])

  return <div id={mapContainer} style={{ width: width || '100%', height: height }}></div>;
};

export default TomTomMap;
