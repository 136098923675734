import React, { useEffect, useRef, useState } from 'react'
import { IFormControl } from './types'
import { Form, InputGroup } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { LocationSearchPayload } from '../../api/types/Radar'
import { useLocationSearchQuery } from '../../api/network/radarApiService'
import {
  displayPickDropLocation,
  getPickDropLocationObject
} from '../../utils/RadarUtils'
import Typeahead from 'react-bootstrap-typeahead/types/core/Typeahead'

const FormControl = (props: IFormControl) => {
  const {
    wrapperClassName = `col-sm-12 position-relative`,
    as,
    label,
    controlId,
    errorMessage,
    isInvalid,
    onChange,
    onTypeaheadChange,
    placeholder,
    showLabel = false,
    type = 'text',
    value,
    fieldClassName = '',
    fieldPreText,
    FieldPreFC,
    fieldPostText,
    fieldInfoText,
    marginTop,
    ...restFormControlProps
  } = props

  const debugFormControl = false
  const formControlRef = useRef<React.Ref<Typeahead>>()

  const [showPassword, setShowPassword] = useState(false)

  const [searchQuery, setSearchQuery] = useState<LocationSearchPayload>({
    query: ''
  })
  const [asyncTypeaheadState, setAsyncTypeaheadState] = useState({
    isLoading: false,
    options: []
  })

  const { data: dataLocationSearch, isLoading: isLoadinglocationSearch } =
    useLocationSearchQuery(searchQuery, {
      skip: !!!searchQuery.query || !searchQuery.query
    })

  useEffect(() => {
    if (!!searchQuery.query) {
      setAsyncTypeaheadState({
        ...asyncTypeaheadState,
        isLoading: true
      })
    }
  }, [searchQuery])

  useEffect(() => {
    if (!!dataLocationSearch && !isLoadinglocationSearch) {
      console.log('data', dataLocationSearch)
      setAsyncTypeaheadState({
        isLoading: false,
        options: dataLocationSearch.addresses
      })
    }
  }, [dataLocationSearch, isLoadinglocationSearch])

  const defaultFieldValue =
    type === 'locationAutocomplete'
      ? [displayPickDropLocation(value as string, { includeFlag: false })]
      : value

  return (
    <Form.Group
      className={`form-field form-field--${type} ${fieldClassName}${
        !!errorMessage ? ' form-field--invalid' : ''
      } col-sm-12 position-relative`}
      controlId={controlId}
      style={{marginTop: marginTop}}
    >
      {showLabel && (
        <Form.Label className="text-primary">{label ?? placeholder}</Form.Label>
      )}
      <div
        className={`form-control-wrap position-relative${
          type === 'locationAutocomplete' && !asyncTypeaheadState.isLoading
            ? ' is-location-picker'
            : ''
        }`}
      >
        {type === 'locationAutocomplete' && !props?.disabled ? (
          <AsyncTypeahead
            ref={formControlRef as React.Ref<Typeahead>}
            delay={1000}
            disabled={props?.disabled}
            id={restFormControlProps.id ?? `${type}-${Math.random() * 10e5}`}
            isLoading={asyncTypeaheadState.isLoading}
            labelKey={(option: any) =>
              displayPickDropLocation('', { includeFlag: false }, option)
            }
            onSearch={query => {
              if (debugFormControl) console.log('[onSearch]')
              setSearchQuery({ query })
            }}
            options={asyncTypeaheadState.options}
            onChange={e => {
              if (debugFormControl) console.log('[onChange]')
              const typeAheadField: any = { ...formControlRef.current }
              if (debugFormControl)
                console.log('typed text', typeAheadField?.state?.text)
              if (e.length) onTypeaheadChange?.(e)
              else onTypeaheadChange?.()
            }}
            placeholder={placeholder}
            isInvalid={isInvalid}
            onBlur={e => {
              if (debugFormControl) console.log('[onBlur]')
              const fieldValue = (e.target as HTMLInputElement).value
              if (!fieldValue) {
                onTypeaheadChange?.()
              }
            }}
            selected={
              value ? [getPickDropLocationObject(value as string)] : undefined
            }
          />
        ) : (
          <>
            <InputGroup>
              {(!!fieldPreText || !!FieldPreFC) && (
                <InputGroup.Text
                  className={`${!!FieldPreFC ? 'has-icon' : ''}${
                    props.plaintext ? ' input-group-text--plaintext' : ''
                  }`}
                >
                  {!!FieldPreFC && <FieldPreFC />}
                  {fieldPreText ?? ''}
                </InputGroup.Text>
              )}
              <Form.Control
                {...restFormControlProps}
                className={`${props.className} pe-2`}
                type={showPassword ? 'text' : type}
                placeholder={placeholder}
                value={defaultFieldValue}
                onChange={onChange}
                isInvalid={isInvalid}
                as={as}
              />
              {!!fieldPostText && (
                <InputGroup.Text>{fieldPostText}</InputGroup.Text>
              )}
            </InputGroup>
            {!!fieldInfoText && (
              <p className="form-control-infotext">{fieldInfoText}</p>
            )}
          </>
        )}
        {type === 'password' || type === 'location' ? (
          <span
            className={type === 'password' ? 'visibility-toggle' : 'location'}
            onClick={() => {
              setShowPassword(!showPassword)
            }}
          ></span>
        ) : (
          ''
        )}
      </div>
      <Form.Control.Feedback type="invalid">
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  )
}
export default FormControl
