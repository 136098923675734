import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  API_TRUCK_URL_SUFFIX,
  API_TRUCK,
  API_USER_URL_SUFFIX,
  BASE_URL,
  DOCUMENTS_TYPE,
  USER_ACCOUNT_INFO,
  API_TRUCK_DOCUMENT_SUFFIX
} from './ServerUrl'
import { fetchUserData } from '../../utils/storage'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import {
  DocResponse,
  GenericPagingResponse,
  TruckResponse,
  UserQuery,
  UserResponse
} from '../types/Driver'

import { handleAuthErrorCode } from './errorCodes'
import { TruckDocument } from '../types/TruckDocuments'
import { AddTruckPayload } from '../types/Trucks'

export enum TruckAPITags {
  TRUCK_DETAIL = 'TRUCK_DETAIL',
  // APPROVE_DOCUMENT = 'APPROVE_DOCUMENT',
  UPDATE_TRUCK = 'UPDATE_TRUCK'
}
export const TruckAPIS = createApi({
  reducerPath: 'truckAPIS',
  keepUnusedDataFor: 0,
  tagTypes: [TruckAPITags.TRUCK_DETAIL, TruckAPITags.UPDATE_TRUCK],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: async headers => {
      if (!headers.has('Content-Type')) {
        headers.set('Content-Type', 'application/json')
      }
      const userData = fetchUserData()
      headers.set('Authorization', `Token ${userData.token}`)

      return headers
    }
  }),
  endpoints: builder => ({
    accountInfo: builder.query<UserResponse, any>({
      query: () => {
        return {
          url: `${API_USER_URL_SUFFIX}${USER_ACCOUNT_INFO}`
        }
      },
      transformErrorResponse(baseQueryReturnValue) {
        handleAuthErrorCode(baseQueryReturnValue)
      },
      // providesTags: [UserAPITags.USER_DETAIL, UserAPITags.UPDATE_USER],
      transformResponse: (response: UserResponse) => {
        return response
      }
    }),

    allTrucks: builder.query<GenericPagingResponse<TruckResponse>, UserQuery>({
      query: (q: UserQuery) => {
        return {
          url: `${API_TRUCK_URL_SUFFIX}`,
          params: q
        }
      },
      transformErrorResponse(baseQueryReturnValue) {
        handleAuthErrorCode(baseQueryReturnValue)
      },
      providesTags: [TruckAPITags.TRUCK_DETAIL, TruckAPITags.UPDATE_TRUCK],
      transformResponse: (response: GenericPagingResponse<TruckResponse>) => {
        return response
      }
    }),

    allDocumentsType: builder.query<
      GenericPagingResponse<DocResponse>,
      UserQuery
    >({
      query: (q: UserQuery) => {
        return {
          url: `${DOCUMENTS_TYPE}`,
          params: q
        }
      },
      transformErrorResponse(baseQueryReturnValue) {
        handleAuthErrorCode(baseQueryReturnValue)
      },
      providesTags: [TruckAPITags.TRUCK_DETAIL, TruckAPITags.UPDATE_TRUCK],
      transformResponse: (response: GenericPagingResponse<DocResponse>) => {
        return response
      }
    }),

    postTruck: builder.mutation<Response, FormData>({
      query: (params: FormData) => {
        return {
          url: API_TRUCK_URL_SUFFIX,
          method: 'POST',
          body: params,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          formData: true
        }
      },
      transformErrorResponse(baseQueryReturnValue) {
        handleAuthErrorCode(baseQueryReturnValue)
      },
      transformResponse: async (data: Response) => {
        return data
      }
    }),

    truckDetail: builder.query<TruckResponse, string>({
      query: (id: string) => `${API_TRUCK_URL_SUFFIX}${id}/`,
      // providesTags: [TruckAPITags.TRUCK_DETAIL, TruckAPITags.UPDATE_TRUCK],
      keepUnusedDataFor: 0,
      transformErrorResponse(baseQueryReturnValue) {
        handleAuthErrorCode(baseQueryReturnValue)
      },
      transformResponse: (response: TruckResponse) => {
        return response
      }
    }),

    acceptDocument: builder.mutation({
      query: (id: number) => ({
        url: `${API_TRUCK_DOCUMENT_SUFFIX}/${id}/accept/`,
        method: 'POST'
      })
    }),

    rejectDocument: builder.mutation({
      query: (id: number) => ({
        url: `${API_TRUCK_DOCUMENT_SUFFIX}/${id}/reject/`,
        method: 'POST'
      })
    }),

    getTruckDocuments: builder.query<TruckDocument[], string>({
      query: (id: string) =>
        `${API_TRUCK_URL_SUFFIX}${id}/${API_TRUCK.TRUCK_DOCUMENTS}`,
      keepUnusedDataFor: 0,
      transformErrorResponse(baseQueryReturnValue) {
        handleAuthErrorCode(baseQueryReturnValue)
      },
      transformResponse: (response: TruckDocument[]) => {
        return response
      }
    })

    // putTruck: builder.mutation<Response, FormData>({
    //   query: (params: FormData) => {
    //     return {
    //       url: `${API_TRUCK_URL_SUFFIX}${params.id}/`,
    //       method: 'PUT',
    //       body: params,
    //       headers: {
    //         'Content-Type': 'multipart/form-data'
    //       },
    //       formData: true
    //     };
    //   },
    //   invalidatesTags: [JobApiTags.UPDATE_JOB],
    //   transformErrorResponse(baseQueryReturnValue) {
    //     handleAuthErrorCode(baseQueryReturnValue);

    //   },
    //   transformResponse: async (data: Response) => {
    //     return data;
    //   },
    // }),
  })
})

export const {
  useAccountInfoQuery,
  useLazyAllTrucksQuery,
  useAllDocumentsTypeQuery,
  usePostTruckMutation,
  useTruckDetailQuery,
  useGetTruckDocumentsQuery,
  useAcceptDocumentMutation,
  useRejectDocumentMutation
} = TruckAPIS
