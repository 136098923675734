export const APPCONFIG = {
  APPTITLE: "Waterfront Transport",
  debugApiResponses: true,

  COLORS: {
    brightRed: '#F80000',
    diablo: '#999',
    error: '#BF0000',
    grey_444: '#444',
    success: '#1bbf00',
    vividCerulean: '#0041bf',

    barChart: [
      '#01A0FD',
      '#0041BF',
      '#23AAE1',
      '#0F75BC'
    ],
    pieChart: [
      '#23AAE1',
      '#0F75BC',
      '#BF0000'
    ]
  },

  mileageMaxDigits: 3,
  defaultMapCenter: [-73.9911, 40.7342], // NYC
}
