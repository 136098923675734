import { FormikProvider, useFormik } from "formik"
import { Col, Form } from "react-bootstrap"
import CardWithHeader from "../../../components/cardWithHeader/CardWithHeader"
import { ChangePasswordPayload } from "../../../api/types/ForgotPasword"
import { toast } from "react-toastify"
import { changePasswordFieldsSchema } from "../../../utils/Validations"
import FormControl from "../../../components/formControl/FormControl"
import PrimaryButton from "../../../components/primaryButton/PrimaryButton"
import { useChangePasswordMutation } from "../../../api/network/settingApiService"
import { useEffect } from "react"
import { ISettings } from "../types"

const PageChangePassword = (props: ISettings) => {
  const initialValues: ChangePasswordPayload = {
    old_password: "",
    new_password: "",
    confirm_password: ""
  }
  const [changePasswordApiTrigger, { isLoading }] = useChangePasswordMutation()
  const { setLoading } = props
  useEffect(() => {
    setLoading(isLoading)
  }, [isLoading, setLoading])

  const formik = useFormik({
    initialValues,
    validationSchema: changePasswordFieldsSchema,
    onSubmit: (values: ChangePasswordPayload) => {
      changePasswordApiTrigger(values)
        .unwrap()
        .then(response => {
          formik.resetForm()
          toast.success(response.message)
        })
        .catch(e => {})
    }
  })

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Col md={6}>
        <CardWithHeader title={"Change Password"}>
          <FormControl
            type="password"
            placeholder="Enter Current Password"
            controlId="validateOldPassword"
            onChange={e => formik.setFieldValue("old_password", e.target.value)}
            errorMessage={formik.errors.old_password}
            value={formik.values.old_password}
            isInvalid={!!formik.errors.old_password}
          />
          <FormControl
            type="password"
            placeholder="Enter New Password"
            controlId="validateNewPassword"
            onChange={e => formik.setFieldValue("new_password", e.target.value)}
            errorMessage={formik.errors.new_password}
            value={formik.values.new_password}
            isInvalid={!!formik.errors.new_password}
          />
          <FormControl
            type="password"
            placeholder="Re-enter New Password"
            controlId="validateConfirmPassword"
            onChange={e =>
              formik.setFieldValue("confirm_password", e.target.value)
            }
            errorMessage={formik.errors.confirm_password}
            value={formik.values.confirm_password}
            isInvalid={!!formik.errors.confirm_password}
          />
          <div className="d-flex justify-content-end">
            <PrimaryButton title="Submit" className="mt-4" type="submit" />
          </div>
        </CardWithHeader>
        </Col>
      </Form>
    </FormikProvider>
  )
}

export default PageChangePassword

