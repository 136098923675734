import { FinancialDetails } from '../../../api/types/Jobs'
import InfoIcon from '../../../assets/images/svg/Info'
import { formatAmount } from '../../../utils/NumberUtils'

const JobDetailTrackingPayout = ({
  jobFinancialDetails
}: {
  jobFinancialDetails: FinancialDetails
}) => {
  return (
    <div className="job-detail__tracking--payout">
      <div className="payout-details">
        <div className="amount">
          {formatAmount(parseFloat(jobFinancialDetails?.total_rate))}
        </div>
        <InfoIcon />
      </div>
      <div className='note'>*This is a quoted Salary and is subject to change</div>
    </div>
  )
}
export default JobDetailTrackingPayout
