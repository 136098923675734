import React from "react"
import "./user-info-item.scss"
interface Props {
  title?: string;
  value?: string;
}

const UserInfoItem = (props: Props) => {
  const { title, value =''} = props
  return (
    <div className="user-item_info">
      {title && <div className="label">{title}</div>}
      <div className={`value ${title? 'mt-6' : ''}`}>{value}</div>
    </div>
  )
}
export default UserInfoItem
