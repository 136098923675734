import { CompanyDetails } from '../api/types/Driver'
import { LoginResponse } from '../api/types/Login'
import { localStorageKeys } from './constants'

export const fetchUserData = () => {
  const user =
    localStorage.getItem(localStorageKeys.user) ||
    sessionStorage.getItem(localStorageKeys.user)
  const userData: LoginResponse = JSON.parse(user ?? '{}')
  return userData
}

export const fetchUserType = () => {
  return `${fetchUserData()?.user?.user_type}`
}

export const fetchAccountInfo = () => {
  const accountInfo = sessionStorage.getItem(localStorageKeys.accountInfo)
  const userData: CompanyDetails = JSON.parse(accountInfo ?? '{}')
  return userData
}
