import { Col, Container, Form, Row } from "react-bootstrap";
import { IDriverTrafficConvictions } from "./types";
import { FormikProvider, useFormik } from "formik";
import CardWithHeader from "../../../../components/cardWithHeader/CardWithHeader";
import FormControl from "../../../../components/formControl/FormControl";
import { ITrafficConvictions } from "../../../../api/types/Driver";
import './traffic-convictions.scss';
import SectionHeading from "../section-heading/section-heading";


const TrafficConvictions = (props: IDriverTrafficConvictions) => {

  const { trafficConvictions, recordNumber } = props;

  const initialValues: ITrafficConvictions = {
    date: trafficConvictions?.date,
    penalty: trafficConvictions?.penalty,
    state_of_violation: trafficConvictions?.state_of_violation,
    violation: trafficConvictions?.violation,
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values: ITrafficConvictions) => { }
  })

  return (
    <Col lg={4} md={6}>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <CardWithHeader title={'Entry '+ recordNumber}>
            <div>
              <FormControl
                type="date"
                placeholder="Date"
                controlId="validateDate"
                onChange={e => formik.setFieldValue('date', e.target.value)}
                errorMessage={formik.errors.date}
                value={formik.values.date}
                isInvalid={!!formik.errors.date}
                showLabel={true}
                readOnly
              />
              <FormControl
                placeholder="Violation"
                controlId="validateViolation"
                onChange={e => formik.setFieldValue('violation', e.target.value)}
                errorMessage={formik.errors.violation}
                value={formik.values.violation}
                isInvalid={!!formik.errors.violation}
                showLabel={true}
                readOnly
              />
              <FormControl
                placeholder="State of Violation"
                controlId="validateStateOfViolation"
                onChange={e => formik.setFieldValue('state_of_violation', e.target.value)}
                errorMessage={formik.errors.state_of_violation}
                value={formik.values.state_of_violation}
                isInvalid={!!formik.errors.state_of_violation}
                showLabel={true}
                readOnly
              />
              <FormControl
                placeholder="Penalty"
                controlId="validatePenalty"
                onChange={e => formik.setFieldValue('penalty', e.target.value)}
                errorMessage={formik.errors.penalty}
                value={formik.values.penalty}
                isInvalid={!!formik.errors.penalty}
                showLabel={true}
                readOnly
              />
            </div>
          </CardWithHeader>
        </Form>
      </FormikProvider>
    </Col>
  )
}

export default TrafficConvictions;