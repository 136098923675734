import React, { SVGProps } from 'react'

function StartLocationIcon(props: SVGProps<SVGSVGElement>) {
  const { color = '#1BBF00' } = props

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={color}
        d="M1.33301 7.99998C1.33301 4.31998 4.31967 1.33331 7.99967 1.33331C11.6797 1.33331 14.6663 4.31998 14.6663 7.99998C14.6663 11.68 11.6797 14.6666 7.99967 14.6666C4.31967 14.6666 1.33301 11.68 1.33301 7.99998ZM7.99967 12C10.2063 12 11.9997 10.2066 11.9997 7.99998C11.9997 5.79331 10.2063 3.99998 7.99967 3.99998C5.79301 3.99998 3.99967 5.79331 3.99967 7.99998C3.99967 10.2066 5.79301 12 7.99967 12Z"
      />
    </svg>
  )
}

export default StartLocationIcon
