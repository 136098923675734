import { useEffect, useState } from "react"
import { DEBOUNCE_TIME } from "../constants"

const useDebounce = (value: string, delay: number = DEBOUNCE_TIME) => {
  const [debouncedValue, setDebouncedValue] = useState("")
  useEffect(() => {
    let timeout = setTimeout(() => setDebouncedValue(value), delay)

    return () => {
      clearTimeout(timeout)
    }
  }, [value, delay])

  return debouncedValue
}

export default useDebounce
