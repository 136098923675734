import { Button, Image } from 'react-bootstrap'
import { ApproveDocumentPayload, Documents } from '../../../../api/types/Driver'
import Table from '../../../../components/table/table'
import { DateFormat, changeDateFormat } from '../../../../utils/DateUtils'
import { DOCUMENT_TYPE } from '../../../../utils/constants'
import { IDocuments } from './types'
import './documents.scss'
import { useApproveDocumentMutation } from '../../../../api/network/userApiService'
import { toast } from 'react-toastify'
import SuspensionReasonModal from '../../../../components/modal/suspendReasonModal/SuspandReasonModal'
import { useState } from 'react'
import DocumentPreviewModal from '../../../../components/modal/documentPreview/DocumentPreviewModal'
import { set } from 'lodash'
import DocumentRejectReasonModal from '../../../../components/modal/documentRejectReasonModal/DocumentRejectReasonModal'

const PageDocuments = (props: IDocuments) => {
  const { documents, id } = props

  const [aprroveDocumentAPITrigger, { isLoading }] =
    useApproveDocumentMutation()

  const handleClose = () => setShow(false)
  const [show, setShow] = useState(false)
  const [document, setDocument] = useState<Documents>()
  const [image, setImage] = useState<string>('')
  const [imageView, setImageView] = useState<string>('')
  const [showRejectReasonModal, setShowRejectReasonModal] = useState(false);
  const [documentDetailToReject, setDocumentDetailToReject] = useState<Documents | null>(null);

  const handleRejectDocument = (reason: string) => {
    documentDetailToReject && approveDocument(documentDetailToReject, 2, reason);
    setDocumentDetailToReject(null);
    setShowRejectReasonModal(false);
  }

  const columns = [
    {
      Header: 'Document Type',
      accessor: (cell: any) => (
        <span className="document-type">
          {DOCUMENT_TYPE[cell.document_type]}
        </span>
      )
    },
    {
      Header: 'Uploaded File',
      accessor: (cell: any) => (
        <div className="d-flex flex-column files">
          <span className="filename">
            {cell.files.front?.substring(
              cell.files.front?.lastIndexOf('/') + 1,
              cell.files.front?.indexOf('?')
            )}
          </span>
          {cell.files.back && (
            <span className="filename">
              {cell.files.back?.substring(
                cell.files.back?.lastIndexOf('/') + 1,
                cell.files.back?.indexOf('?')
              )}
            </span>
          )}
        </div>
      )
    },
    {
      Header: 'Date of Upload',
      accessor: (cell: any) => (
        <span>
          {changeDateFormat(
            cell.updated_at,
            DateFormat.YYYYMMMDDHHMMSS,
            DateFormat.DDMMMYYYY1
          )}
        </span>
      )
    },
    {
      Header: 'Thumbnail',
      accessor: (cell: any) => (
        <div className="d-flex">
          <Image
            width={50}
            height={25}
            src={cell.files.front}
            thumbnail
            onClick={() => {
              documentPreview(cell, cell.files.front, 'Front')
            }}
          />
          {cell.files.back && (
            <Image
              width={50}
              height={25}
              src={cell.files.back}
              thumbnail
              onClick={() => {
                documentPreview(cell, cell.files.back, 'Back')
              }}
            />
          )}
        </div>
      )
    },
    {
      Header: 'Action',
      accessor: (cell: any) =>
        cell?.status === 3 ? (
          <div className="d-flex">
            <Button
              className="me-2"
              variant="danger"
              onClick={() => {
                setDocumentDetailToReject(cell);
                setShowRejectReasonModal(true);
              }}
            >
              Reject
            </Button>
            <Button
              variant="success"
              onClick={() => {
                approveDocument(cell, 1)
              }}
            >
              Accept
            </Button>
          </div>
        ) : (
          <span className="fc-blue">
            {cell?.status === 2 ? 'Rejected' : 'Approved'}
          </span>
        )
    }
  ]

  const approveDocument = (document: Documents, status: number, reason?: string) => {
    const payload: ApproveDocumentPayload = {
      id: id,
      status: status,
      document_type: document.document_type,
      reason: reason
    }
    aprroveDocumentAPITrigger(payload)
      .unwrap()
      .then(response => {
        toast.success(response.message)
      })
      .catch(e => {})
  }

  const documentPreview = (
    document: Documents,
    image: string,
    side: string
  ) => {
    setDocument(document)
    setImage(image)
    setImageView(side)
    setShow(true)
  }

  console.log('documents', documents)

  return (
    <>
      <Table data={documents} columns={columns} hidePagination={true} />
      <DocumentPreviewModal
        show={show}
        handleClose={handleClose}
        document={document}
        image={image}
        imageView={imageView}
      />
      <DocumentRejectReasonModal
        handleClose={() => {
          setShowRejectReasonModal(false)
        }}
        show={showRejectReasonModal}
        onConfirm={handleRejectDocument} />
    </>
  )
}

export default PageDocuments
