import React, { useEffect, useState } from 'react'
import { useLazyLoadTakenQuery } from '../../api/network/analyticsApiService'
import { UserResponse } from '../../api/types/Driver'
import { APPCONFIG } from '../../app-config'
import AppBarChart from '../../components/appBarChart/appBarChart'
import CardWithHeader from '../../components/cardWithHeader/CardWithHeader'
import AnSecCompDriverSelector from './components/driverSelector'

export interface IAnSecLoadTakenProps {
  allDriversList: UserResponse[]
  title?: string
}

const AnSecLoadTaken = (props: IAnSecLoadTakenProps) => {
  const { allDriversList, title = 'Load Taken' } = props
  const [selectedDriverId, setSelectedDriverId] = useState<number>(
    allDriversList[0]?.driver_details?.id
  )
  console.log('allDriversList', allDriversList)

  const [
    loadTakenAPITrigger,
    {
      data: dataLoadTaken,
      isLoading: isLoadingLoadTaken,
      isError: isErrorLoadTaken
    }
  ] = useLazyLoadTakenQuery()

  useEffect(() => {
    if (!!selectedDriverId) {
      console.log('Selected driver', selectedDriverId)
      loadTakenAPITrigger({ driverId: selectedDriverId })
    }
  }, [selectedDriverId])

  return (
    <CardWithHeader title={title} wrapperClassName={`ansec-load-taken`}>
      <AnSecCompDriverSelector
        allDriversList={allDriversList}
        onChange={event => {
          console.log(event.target.value)
          setSelectedDriverId(parseInt(event.target.value))
        }}
        selectedDriver={selectedDriverId}
      />
      <div className="total-jobs d-flex justify-content-between fs-12 fw-semibold">
        <div>Total Number of Jobs</div>
        <div className="fc-blue">{dataLoadTaken?.data.total_jobs ?? 0}</div>
      </div>
      {!!dataLoadTaken && !isLoadingLoadTaken && (
        <AppBarChart
          data={Object.entries(dataLoadTaken?.data)
            .filter(([key, value]) => key !== 'total_jobs')
            .map(([key, value], i) => {
              return {
                name: key,
                value: parseFloat(`${value}`),
                fill: APPCONFIG.COLORS.barChart[i]
              }
            })}
          yAxisLabel={`Average Number of Jobs`}
        />
      )}
    </CardWithHeader>
  )
}
export default AnSecLoadTaken
