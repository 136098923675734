import { jobDetail } from '../../../api/types/Jobs'
import CardWithHeader from '../../../components/cardWithHeader/CardWithHeader'
import FormControl from '../../../components/formControl/FormControl'

const JobDetailAcceptance = ({ jobDetail }: { jobDetail: jobDetail }) => {
  return (
    <CardWithHeader title={`Acceptance Details`}>
      {!!jobDetail?.job_details?.assigned_driver?.company_details && (
        <FormControl
          placeholder="Company"
          value={
            jobDetail?.job_details?.assigned_driver?.company_details
              ?.company_name
          }
          readOnly={true}
          showLabel={true}
        />
      )}
      <FormControl
        placeholder="Driver Name"
        value={jobDetail?.job_details?.assigned_driver?.name}
        readOnly={true}
        showLabel={true}
        fieldInfoText={
          !!jobDetail?.job_details?.assigned_driver?.company_details
            ? ''
            : `This driver is an independent contractor.`
        }
      />
    </CardWithHeader>
  )
}
export default JobDetailAcceptance
