import { useEffect, useState } from "react"
import Table from "../../../components/table/table"
import { useLazyAllCompaniesQuery } from "../../../api/network/userApiService"
import { DEBOUNCE_TIME, USER_STATUS } from "../../../utils/constants"
import { ReactComponent as VisibilityIcon } from "../../../assets/images/svg/visibility.svg"
import { ROUTE_NAMES } from "../../../utils/route/routerNames"
import { useNavigate } from "react-router-dom"
import useDebounce from "../../../utils/hooks/useDedounce"
import { Filter_Obj } from "../../../components/formFilter/types"
import { getStatusFilterKeys } from "../../../utils/helper"
import { FilterValue } from "react-table"
import Filter from "../../../components/formFilter/formFilter"

const PageCompanyList = ({ search }: { search: string }) => {
  const [page, setPage] = useState(1)
  const [statusFilter, setStatusFilter] = useState<Filter_Obj[]>([])
  const navigate = useNavigate()
  const debouncedValue = useDebounce(search, DEBOUNCE_TIME)

  const [allCompanyTrigger, { data, isLoading }] = useLazyAllCompaniesQuery()

  useEffect(() => {
    setPage(1)
    allCompanyTrigger({
      search: debouncedValue,
      page: 1,
      status: getStatusFilterKeys(statusFilter, "status")
    })
  }, [debouncedValue, statusFilter, allCompanyTrigger])

  useEffect(() => {
    allCompanyTrigger({ search: debouncedValue, page })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, allCompanyTrigger])

  const { next, previous, results, total_pages } = data || {}

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }
  const renderUserStatus = (status: number) => (
    <span className={status === 2 ? "fc-green" : "fc-red"}>
      {USER_STATUS[status]}
    </span>
  )
  function SelectColumnFilter({
    column: { filterValue, setFilter }
  }: FilterValue) {
    return (
      <Filter
        options={[
          { id: "2", value: "Active" },
          { id: "4", value: "Suspended" }
        ]}
        filter={filterValue}
        setFilter={setFilter}
      />
    )
  }
  const columns = [
    {
      Header: "Company",
      accessor: "company_details.company_name"
    },
    {
      Header: "Email",
      accessor: "email"
    },
    {
      Header: "Phone No.",
      accessor: "user_profile.phone_number"
    },
    {
      Header: "Status",
      accessor: (cell: any) => renderUserStatus(cell?.user_profile?.status),
      defaultCanFilter: true,
      Filter: SelectColumnFilter
    },
    {
      Header: "Contact Person",
      accessor: (cell: any) => (
        <span className="fc-blue">
          {cell?.company_details?.contact_person_name}
        </span>
      )
    },
    {
      Header: "",
      id: "action",
      accessor: (cell: any) => (
        <VisibilityIcon
          role="button"
          onClick={() =>
            navigate(
              `${ROUTE_NAMES.dashboardChildren.users}/company/${cell.id}`
            )
          }
        />
      )
    }
  ]

  return (
    <Table
      data={results || []}
      columns={columns}
      page={page}
      setPage={setPage}
      onNext={onNext}
      onPrev={onPrev}
      pageCount={total_pages || 0}
      isLoading={isLoading}
      filter={statusFilter}
      setFilter={setStatusFilter}
    />
  )
}

export default PageCompanyList
