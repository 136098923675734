import React, { useEffect, useState } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { DateRangePicker } from 'react-dates'
import {
  useLazyAllCompaniesQuery,
  useLazyAllDriversQuery
} from '../../api/network/userApiService'
import { UserResponse } from '../../api/types/Driver'
import Loader from '../../components/loader/Loader'
import { userStatus } from '../../utils/constants'
import DetailHeader from '../jobs/jobDetail/header/header'
import AnSecDumpLoads from './analytics-section-dump-loads'
import AnSecEmptyJob from './analytics-section-empty-job'
import AnSecJobAcceptDuration from './analytics-section-job-accept-duration'
import AnSecJobReassign from './analytics-section-job-reassign'
import AnSecLoadTaken from './analytics-section-load-taken'
import AnSecUserDetails from './analytics-section-user-details'
import './analytics.scss'
import 'react-dates/lib/css/_datepicker.css';

const PageAnalytics = () => {
  const [loading, setLoading] = useState({
    companies: false,
    drivers: false,
    all: false
  })
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [focusedInput, setFocusedInput] = useState<any>(null);

  // START: seqiential API calls to fetch all COMPANIES
  const [companiesListPage, setCompaniesListPage] = useState<number>(1)
  const [companiesList, setCompaniesList] = useState<UserResponse[]>([])
  let companyQueryParams = {
    search: '',
    page: companiesListPage
  }
  const [
    allCompaniesAPITrigger,
    { data: dataAllCompanies, isLoading: isLoadingAllCompanies }
  ] = useLazyAllCompaniesQuery()

  useEffect(() => {
    if (!!dataAllCompanies && !isLoadingAllCompanies) {
      setCompaniesList([
        ...companiesList,
        ...dataAllCompanies?.results.filter(
          company => company?.user_profile?.status === userStatus.ACTIVE
        )
      ])
      if (companiesListPage < dataAllCompanies?.total_pages) {
        setCompaniesListPage(companiesListPage + 1)
      } else {
        setLoading({
          ...loading,
          companies: false,
          all: loading.drivers ?? false
        })
      }
    }
  }, [dataAllCompanies, isLoadingAllCompanies])

  useEffect(() => {
    allCompaniesAPITrigger({
      ...companyQueryParams,
      page: companiesListPage
    })
  }, [companiesListPage])
  // END: seqiential API calls to fetch all COMPANIES

  // START: seqiential API calls to fetch all DRIVERS
  const [allDriversListPage, setAllDriversListPage] = useState<number>(1)
  const [allDriversList, setAllDriversList] = useState<UserResponse[]>([])
  let allDriversQueryParams = {
    search: '',
    page: allDriversListPage,
    status: `${userStatus.ACTIVE}`
  }

  const [
    allDriversAPITrigger,
    { data: dataAllDrivers, isLoading: isLoadingAllDrivers }
  ] = useLazyAllDriversQuery()

  useEffect(() => {
    if (!!dataAllDrivers && !isLoadingAllDrivers) {
      setAllDriversList([...allDriversList, ...dataAllDrivers?.results])
      if (allDriversListPage < dataAllDrivers?.total_pages) {
        setAllDriversListPage(allDriversListPage + 1)
      } else {
        setLoading({
          ...loading,
          drivers: false,
          all: loading.companies ?? false
        })
      }
    }
  }, [dataAllDrivers, isLoadingAllDrivers])

  useEffect(() => {
    allDriversAPITrigger({
      ...allDriversQueryParams,
      page: allDriversListPage
    })
  }, [allDriversListPage])
  // END: seqiential API calls to fetch all DRIVERS

  useEffect(() => {
    setLoading({ companies: true, drivers: true, all: true })
    allCompaniesAPITrigger(companyQueryParams)
    allDriversAPITrigger(allDriversQueryParams)
  }, [])

  const AnalyticsDateRange = () => (
    <>
      <p className="m-3">
        <label>Date Range</label>
      </p>
      <DateRangePicker 
        startDate={startDate}
        startDateId="s_id"
        endDate={endDate}
        endDateId="e_id"
        onDatesChange={({ startDate, endDate }) => { setStartDate(startDate); setEndDate(endDate); }}
        focusedInput={focusedInput}
        onFocusChange={e => setFocusedInput(e)}
        displayFormat="DD/MM/YYYY"
      />
    </>
  )

  return (
    <Container
      fluid
      className="settings d-flex flex-column page-content-height containerWidth"
    >
      {loading.all && <Loader />}
      <Row>
        <Col>
          <DetailHeader
            title="Analytics"
            CtaComponent={<AnalyticsDateRange />}
          />
        </Col>
      </Row>
      {!!!loading.all && (
        <>
          <Row>
            <Col lg={{ order: 1, span: 8 }} xs={{ order: 2, span: 12 }}>
              <AnSecJobAcceptDuration companiesList={companiesList} startDate={startDate} endDate={endDate} />
              <AnSecJobReassign startDate={startDate} endDate={endDate}/>
            </Col>
            <Col lg={{ order: 2, span: 4 }} xs={{ order: 1, span: 12 }}>
              <AnSecUserDetails />
              <AnSecLoadTaken allDriversList={allDriversList} />
              <AnSecDumpLoads allDriversList={allDriversList} />
            </Col>
          </Row>
          <Row>
            <Col>
              <AnSecEmptyJob companiesList={companiesList} startDate={startDate} endDate={endDate}/>
            </Col>
          </Row>
        </>
      )}
    </Container>
  )
}
export default PageAnalytics
