import { Col, Container, Image, Modal, Row } from 'react-bootstrap'
import { DOCUMENT_TYPE } from '../../../utils/constants'
import { IDocumentPreview } from './types'
import './documentPreview.scss'
import UserInfoItem from '../../userInfoItem/user-info-item'
import PrimaryButton from '../../primaryButton/PrimaryButton'
import { useEffect, useState } from 'react'
import { DateFormat, changeDateFormat } from '../../../utils/DateUtils'
import { saveAs } from 'file-saver'

const DocumentPreviewModal = (props: IDocumentPreview) => {
  const { show, handleClose, document, image, imageView } = props
  console.log('document', image)
  const [images, setImage] = useState('')
  const [view, setView] = useState('')
  useEffect(() => {
    !document?.files?.back ? setView('') : setView(imageView)
    setImage(image)
  }, [image])

  const handleImageNavigation = (image: string) => {
    // setView(view === 'Front' ? 'Back' : 'Front')
    setImage(image)
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="document-preview modal-lg"
    >
      <Modal.Header closeButton className="document-preview-header">
        {/* <Col className="fs-24">Driver Documents</Col> */}
        <Col className="fs-24">
          {`${DOCUMENT_TYPE[document?.document_type || 1]}${
            view ? ` - ${view}` : ''
          }`}
        </Col>
      </Modal.Header>
      <Modal.Body className="document-body">
        <Container fluid>
          <Row>
            <div className="d-flex align-items-center p-0 justify-content-between">
              {document?.files.back && (
                <div
                  className="image-navigation-left"
                  onClick={() => {
                    handleImageNavigation(document.files.front)
                    setView('Front')
                  }}
                />
              )}
              <div className="image">
                <Image src={images} thumbnail fluid />
              </div>
              {document?.files.back && (
                <div
                  className="image-navigation-right"
                  onClick={() => {
                    handleImageNavigation(document.files.back)
                    setView('Back')
                  }}
                />
              )}
            </div>
          </Row>
          <Row>
            <Col className="p-0">
              <UserInfoItem
                title="Uploaded File"
                value={images.substring(
                  images.lastIndexOf('/') + 1,
                  images.indexOf('?')
                )}
              />
            </Col>
            <Col>
              <UserInfoItem
                title="Uploaded Date"
                value={changeDateFormat(
                  document?.updated_at ?? '',
                  DateFormat.YYYYMMMDDHHMMSS,
                  DateFormat.DDMMMYYYY1
                )}
              />
            </Col>
            <Col className="d-flex Xalign-items-center p-0 justify-content-end">
              <PrimaryButton
                title="Download"
                className="mt-5"
                onClick={() => {
                  saveAs(images, 'image.jpg')
                }}
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  )
}

export default DocumentPreviewModal
