import React from 'react'
import "./employment-histories.scss"
import { Col, Row } from "react-bootstrap";
import SectionHeading from "../section-heading/section-heading";
import EmploymentHistory from './employment-history/employment-history';
import { IEmploymentHistoriesProps } from './types';
import { formatAmount } from '../../../../utils/NumberUtils';


const EmploymentHistories = (props: IEmploymentHistoriesProps) => {

    const { employmentHistory } = props;

    const getHeading = (index: number) => {
        if (index === 0) {
            return 'Current (Most Recent) Employee'
        } else if (index === 1) {
            return 'Second (Most Recent) Employee'
        } else if (index === 2) {
            return 'Third (Most Recent) Employee'
        } else {
            return 'Employee'
        }
    }

    return (<>
        <Row>
            <Col sm={12}>
                <SectionHeading title="Employment History" smallHeading={false}
                    disclaimer="">
                    <Row>
                        {
                            employmentHistory?.map((record, index) => {
                                return (
                                    <Col lg={4} md={6}>
                                        <EmploymentHistory
                                            title={"Employer "+ (index + 1) + (record?.is_current_employer ? ' (Current)' : '') }
                                            employmentHistory={{
                                                name: record.employer_name || '',
                                                address: record?.address || '',
                                                phone: record?.phone || '',
                                                position_held: record?.position || '',
                                                date_from: record?.from_date || '',
                                                date_to: record?.to_date || '',
                                                explain_gaps: record?.explain_gaps || '',
                                                state_of_violation: 'Illinois' || '',
                                                salary: formatAmount(record?.salary || 0),
                                                reason_for_leaving: record?.reason_for_leaving || '',
                                                federal_motor_carrier_safety_regulation: record?.subjected_to_federal_motor || false,
                                                safety_sensitive_function: record?.subjected_to_dot_alcohol || false
                                            }}
                                        />
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </SectionHeading>
            </Col>
        </Row>
    </>
    )
}

export default EmploymentHistories