import React, { useEffect, useState } from 'react'
import { ButtonGroup, Col, Row, ToggleButton } from 'react-bootstrap'
import { UserResponse } from '../../api/types/Driver'
import CardWithHeader from '../../components/cardWithHeader/CardWithHeader'
import AnSecCompCompanySelector from './components/companySelector'
import AnSecEmptyRescheduledJob from './analytics-section-empty-rescheduled-job'
import AnSecEmptyDwellingJob from './analytics-section-empty-dweling-job'
import { minutesToHoursFormat } from '../../utils/DateUtils'

export interface IAnSecEmptyJobProps {
  companiesList: UserResponse[]
  title?: string
  startDate: any
  endDate: any
}

const AnSecEmptyJob = (props: IAnSecEmptyJobProps) => {
  const {
    companiesList,
    title = 'Empty Job Rescheduling',
    startDate,
    endDate
  } = props
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(0)

  const [page, setPage] = useState(1)
  const [avgDuration, setAvgDuration] = useState<any>()

  const chips = [
    { name: 'Rescheduled', value: 'rescheduled' },
    { name: 'Dwelling', value: 'dwelling' }
  ]
  const [radioValue, setRadioValue] = useState(chips[0].value)

  return (
    <CardWithHeader title={title} wrapperClassName={`ansec-empty-job`}>
      <Row className="mt-4">
        <Col className="md-6">
          <ButtonGroup>
            {chips.map((radio, i) => (
              <ToggleButton
                key={i}
                id={`radio-${i}`}
                type="checkbox"
                variant="outline-primary"
                value={radio.value}
                checked={radioValue === radio.value}
                onChange={e => {
                  window.location.hash = radio.value
                  setRadioValue(e.currentTarget.value)
                }}
                className={`tab ${
                  radioValue === radio.value ? 'tabSelected' : 'tabUnSelected'
                }`}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </Col>
        <Col>
          <p className="m-0 fw-bold text-right">
            Results are displayed are per the date range selected.
          </p>
        </Col>
      </Row>
      <Row className="py-3 align-items-center">
        <Col>
          <AnSecCompCompanySelector
            companiesList={companiesList}
            onChange={event => {
              console.log(event.target.value)
              setSelectedCompanyId(parseInt(event.target.value))
            }}
            selectedCompany={selectedCompanyId}
          />
        </Col>
        <Col>
          {radioValue === chips?.[0]?.value && (
            <p className="m-0 fw-bold text-right">
              Average Duration (hrs):{' '}
              <span className="fc-blue">
                {!!avgDuration ? minutesToHoursFormat(avgDuration) : '-'}
                {' hrs'}
              </span>
            </p>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {radioValue === chips?.[0]?.value ? (
            <AnSecEmptyRescheduledJob
              companyId={selectedCompanyId}
              startDate={startDate}
              endDate={endDate}
              setAvgDuration={setAvgDuration}
            />
          ) : (
            <AnSecEmptyDwellingJob
              companyId={selectedCompanyId}
              startDate={startDate}
              endDate={endDate}
            />
          )}
        </Col>
      </Row>
    </CardWithHeader>
  )
}
export default AnSecEmptyJob
