import React, { useState } from 'react';
import { ReactComponent as InfoIcon } from '../../assets/images/svg/Info.svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './bottomTooltip.scss';

const BottomTooltip = (props: { tooltipText: any; className: any; }) => {
  const [tooltipText, setTooltipTxt]= useState(props?.tooltipText || "");

  return (
    <OverlayTrigger
      key="bottom"
      placement="bottom"
      overlay={<Tooltip id={`tooltip-bottom`}>{tooltipText}</Tooltip>}
    >
      <InfoIcon className={`tooltip-trigger cursor-pointer ${props?.className}`} />
    </OverlayTrigger>
  );
};

export default BottomTooltip;
