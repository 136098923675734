import React, { useState } from 'react'
import { ButtonGroup, Col, Container, Row, ToggleButton } from 'react-bootstrap'
import './otherDetails.scss'
import PageDocuments from '../documents/documents'
import { IDriverOtherDetails } from './types'
import RadarMap from '../../../../components/radarMap/radarMap'
import TomTomMap from '../../../../components/tomtomMap/tomtomMap'
import tt from '@tomtom-international/web-sdk-maps'

const PageOtherDetails = (props: IDriverOtherDetails) => {
  const { driverDetail } = props
  const tabs = [
    // { name: 'Current Location', value: '1' },
    { name: 'Documents', value: '2' }
  ]
  const [selectedTab, setSelectedTab] = useState(tabs[0].value)
  console.log('driverDetail', driverDetail)
  const [zipcode, setZipcode] = useState('')
  const [location, setLocation] = useState(null)

  return (
    <Container fluid className="driver_other_details">
      <Row>
        <h2 className="title d-flex justify-content-between">
          <span>Other Details</span>
          {/* <span className="dumped-count">DUMPS: 07</span> */}
        </h2>
      </Row>
      <Row>
        <Col>
          <ButtonGroup className="w-100">
            {tabs.map((radio, i) => (
              <ToggleButton
                key={i}
                id={`radio-${i}`}
                type="checkbox"
                variant="outline-primary"
                value={radio.value}
                checked={selectedTab === radio.value}
                onChange={e => setSelectedTab(e.currentTarget.value)}
                className={`tabs ${
                  selectedTab === radio.value ? 'selected' : 'unSelected'
                }`}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
          <div className="w-100 divider" />
        </Col>
      </Row>
      <Row>
        <Col>
          {/* {selectedTab === '1' && (
            // <RadarMap
            //   mapContainer="radar-map-driver-current-location"
            //   mapId="radar-map-driver-current-location"
            //   height="400px"
            // />
            <TomTomMap
              mapContainer="radar-map-driver-current-location"
              mapId="radar-map-driver-current-location"
              // marker={{
              //   title: 'Current Location',
              //   // lat: parseFloat(
              //   //   driverDetail?.specific_driver_details?.driver_location?.latitude
              //   // ),
              //   // lng: parseFloat(
              //   //   driverDetail?.specific_driver_details?.driver_location?.longitude
              //   // ),
              //   color: '#f1e05a'
              // }}
              height="400px"
              lineWidth={8}
              lineColor="#0041bf"
              zoom={14}
              pointToCurrentLocationOnChange={true}
            />
          )} */}
          {/* {selectedTab === '2' && ( */}
          <PageDocuments
            documents={driverDetail?.documents ?? []}
            id={driverDetail?.specific_driver_details.id ?? 0}
          />
          {/* )} */}
        </Col>
      </Row>
    </Container>
  )
}

export default PageOtherDetails
