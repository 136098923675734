import { Col, Container, Row, Form } from "react-bootstrap"
import Logo from "../../assets/images/svg/Logo"
import Backdrop from "../../assets/images/png/Backdrop.png"
import PrimaryButton from "../../components/primaryButton/PrimaryButton"
import FormControl from "../../components/formControl/FormControl"
import { Formik, FormikProps } from "formik"
import { resetPasswordFieldsSchema } from "../../utils/Validations"
import Loader from "../../components/loader/Loader"
import { ResetPasswordPayload } from "../../api/types/ForgotPasword"
import { useResetPasswordMutation } from "../../api/network/authApiService"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import { ROUTE_NAMES } from "../../utils/route/routerNames"

const PageResetPassword = () => {
  const [resetPasswordApiTrigger, { isLoading }] = useResetPasswordMutation()
  const navigate = useNavigate()
  const { email ,token}: { email?: string , token?:string} = useParams()


  const initialValue: ResetPasswordPayload = {
    email: email??"",
    token: token??"",
    password: "",
    confirmPassword: ""
  }

  const handleSubmit = (values: ResetPasswordPayload) => {
    resetPasswordApiTrigger(values)
      .unwrap()
      .then(response => {
        toast.success(response.message)
        navigate(ROUTE_NAMES.landingPage)
      })
      .catch(e => {})
  }

  const renderLoginForm = (renderProps: FormikProps<ResetPasswordPayload>) => {
    const { errors, values, handleChange, handleSubmit } = renderProps
    return (
      <Form noValidate onSubmit={handleSubmit} className="d-flex flex-column">
        <FormControl
          placeholder="Enter New Password"
          controlId="validatePassword"
          errorMessage={errors.password}
          type="password"
          value={values.password}
          onChange={handleChange("password")}
          isInvalid={!!errors.password}
          className="bg-white"
        />
        <FormControl
          placeholder="Re-enter New Password"
          controlId="validateConfirmPassword"
          errorMessage={errors.confirmPassword}
          type="password"
          value={values.confirmPassword}
          onChange={handleChange("confirmPassword")}
          isInvalid={!!errors.confirmPassword}
          className="bg-white"
        />
        <PrimaryButton
          title="Confirm"
          type="submit"
          className="w-75  align-self-center"
        />
      </Form>
    )
  }

  return (
    <Container fluid>
      {isLoading && <Loader />}
      <Row>
        <Col className="loginContainer p-0">
          <div>
            <Logo />
            <h2 className="header">Reset Your Password</h2>
            <Formik
              initialValues={initialValue}
              onSubmit={values => handleSubmit(values)}
              validationSchema={resetPasswordFieldsSchema}
            >
              {renderLoginForm}
            </Formik>
          </div>
        </Col>
        <Col className="vh100 imageContainer p-0">
          <img src={Backdrop} className="image" alt="" />
        </Col>
      </Row>
    </Container>
  )
}

export default PageResetPassword
