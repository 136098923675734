import React, { useEffect } from 'react'
import { useFilters, useTable } from 'react-table'
import './table.scss'
import Footer from './footer'
import { ITable } from './types'

const Table = (props: ITable) => {
  const {
    columns,
    data,
    page = 1,
    setPage = () => {},
    onNext = () => {},
    onPrev = () => {},
    pageCount = 1,
    isLoading = false,
    hidePagination = false,
    filter = [],
    setFilter = () => {},
    wrapperClassName = 'mt-3'
  } = props
  const tableData = React.useMemo(() => data, [data])
  const tableColumns = React.useMemo(() => columns, [columns])
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: '',
      width: 150
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { filters }
  } = useTable(
    {
      columns: tableColumns,
      data: tableData,
      initialState: {
        filters: filter
      },
      manualFilters: true,
      defaultCanFilter: false,
      defaultColumn
    },
    useFilters
  )

  useEffect(() => {
    if (filters && filters.length) {
      setFilter(filters)
    }
  }, [filters])
  return (
    <div className={wrapperClassName}>
      <table {...getTableProps()}>
        <thead className="table-head">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th className="column-header" {...column.getHeaderProps()}>
                  <div className="column-container">
                    {column.render('Header')}
                    {column.render('Filter')}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {isLoading ? (
            <tr>
              <td colSpan={columns.length}>
                <div className="spinner m-auto"></div>
              </td>
            </tr>
          ) : rows.length > 0 ? (
            rows.map(row => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    )
                  })}
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={columns.length}>
                <div className="transports-container d-flex justify-content-center align-item-center h-100">
                  No Record found
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {!hidePagination && rows.length > 0 && (
        <Footer
          onPrev={onPrev}
          onNext={onNext}
          pageCount={pageCount}
          currentPage={page}
          setPage={setPage}
        />
      )}
    </div>
  )
}

export default Table
