import React from "react"
import "./residential-information.scss"
import { Col, Row } from "react-bootstrap";
import Address from "./address/address";
import SectionHeading from "../section-heading/section-heading";
import { IResidentialInformation } from "./types";

const ResidentialInformation = (props: IResidentialInformation) => {

  const { residentialInformation } = props;

  return (<>
    <Row>
      <Col sm={12}>
        <SectionHeading title="Residential Information">
          <Row>
            <Col lg={4} md={6}>
              <Address
                title='Current Address'
                address={{
                  street: residentialInformation?.current_address?.street || '',
                  state: residentialInformation?.current_address?.state || '',
                  city: residentialInformation?.current_address?.city || '',
                  zip_code: residentialInformation?.current_address?.zip_code || '',
                  no_of_years: residentialInformation?.current_address?.no_of_years
                }}
              />
            </Col>
            <Col lg={4} md={6}>
              <Address
                title='Mailing Address'
                address={{
                  street: residentialInformation?.mailing_address?.street || '',
                  state: residentialInformation?.mailing_address?.state || '',
                  city: residentialInformation?.mailing_address?.city || '',
                  zip_code: residentialInformation?.mailing_address?.zip_code || '',
                  no_of_years: residentialInformation?.mailing_address?.no_of_years
                }}
              />
            </Col>
            {!residentialInformation?.residing_more_than_3_years && <>
              <Col lg={4} md={6}>
                <Address
                  title='Previous Address'
                  address={{
                    street: residentialInformation?.previous_address?.street || '',
                    state: residentialInformation?.previous_address?.state || '',
                    city: residentialInformation?.previous_address?.city || '',
                    zip_code: residentialInformation?.previous_address?.zip_code || '',
                    no_of_years: residentialInformation?.previous_address?.no_of_years
                  }}
                />
              </Col>
              <Col lg={4} md={6}>
                <Address
                  title='Previous Address'
                  address={{
                    street: residentialInformation?.previous_address_2?.street || '',
                    state: residentialInformation?.previous_address_2?.state || '',
                    city: residentialInformation?.previous_address_2?.city || '',
                    zip_code: residentialInformation?.previous_address_2?.zip_code || '',
                    no_of_years: residentialInformation?.previous_address_2?.no_of_years
                  }}
                />
              </Col>
            </>}
          </Row>
        </SectionHeading>
      </Col>
    </Row>
  </>
  )
}

export default ResidentialInformation
