// Api URLs
// export const BASE_URL = 'https:/'+window?.location?.href?.split('/')?.slice(1,3)?.join('/')+'/';
// window.location.href.indexOf('staging') > -1 ||
// window.location.href.indexOf('localhost') > -1
//   ? 'https://waterfront-transpor-40-staging.botics.co/'
//   : 'https://dry-wave-40375.botics.co/'

export const BASE_URL =
  window.location.href.indexOf('localhost:3000') > -1
    ? 'https://waterfront-transpor-40-staging.botics.co/'
    : window.location.origin + '/'

export const BASE_URL_SUFFIX = 'api/v1/'
export const FEEDBACK_URL_SUFFIX = 'feedback/api/v1'
export const JOB_URL_SUFFIX = 'job/api/v1'

export const LOGIN = 'login/'
export const USER_ACCOUNT_INFO = 'account-info/'
export const COMPANIES = 'all-company/'
export const SUSPEND_USER = 'suspend-user/'
export const LOGOUT = 'logout/'
export const ADD_USER = 'add-user/'
export const VEHICLE_TYPE = 'get-vehicle-type/'
export const DRIVER_TYPE = 'get-driver-type/'
export const FEEDBACKS = 'feedback/'
export const FEEDBACK_REPLY = 'feedback-reply/'
export const FORGOT_PASSWORD = 'reset-password/send/'
export const RESET_PASSWORD = 'reset-password/verify/'
export const POST_JOBS = 'post-job/'
export const ALL_JOBS = 'all-job/'
export const JOB_DOCUMENT = 'job-documents/'
export const ACCEPT = '/accept/'
export const REJECT = '/reject/'
export const JOB_PROPOSED_RATE = '/proposed-rate/'
export const CHANGE_PASSWORD = 'change-password/'
export const DOCUMENT = 'document/'
export const CONTACT_US = 'contact-us/'
export const SETUP_NEW_PASSWORD = 'setup-new-password/'
export const REASSIGN_JOB = '/reassign/'
export const NOTIFICATIONS = '/notification/'
export const DRIVER_LOCATION = '/driver-location/'

export const DOCUMENTS_TYPE = '/api/v1/document-types/'
// User: Driver APIs
export const API_USER_URL_SUFFIX = 'users/api/'
export const API_USER = {
  ALL_DRIVERS: 'all-driver/'
}

export const API_DRIVER_URL_SUFFIX = 'driver/api/v1/'
export const API_DRIVER = {
  API_DRIVER_PROFILE_DETAILS: 'driver-profile-data/',
  API_DRIVER_PROFILE_UPDATE: 'driver/',
}
// Analytics APIs
export const API_ANALYTICS_URL_SUFFIX = '/analytics/api/v1/'
export const API_ANALYTICS = {
  USER_DETAILS: 'user-details/',
  DUMP_LOADS: 'dump-load/',
  DUMP_LOADS_DRIVER: 'dump-load/driver/[DRIVER_ID]/',
  LOAD_TAKEN_DRIVER: 'load-taken/driver/[DRIVER_ID]/',
  TRACK_JOB: 'track-job/',
  TRACK_JOB_COMPANY: 'track-job/company/[COMPANY_ID]/',
  REASSIGNED_JOB: 'reassigned-job/',
  EMPTY_JOB_RESCHEDULED: 'empty-job/rescheduled/',
  EMPTY_JOB_DWELLING: 'empty-job/dwelling/'
}

// Truck APIs
export const API_TRUCK_URL_SUFFIX = '/trucks/api/v1/'
export const Add_Truck = 'trucks/api/v1/'
export const API_TRUCK = {
  TRUCK_DOCUMENTS: 'view_truck_documents/',
  ADD_TRUCK_DOCUMENTS: 'truck-documents/'
}

// Document APIs
export const API_TRUCK_DOCUMENT_SUFFIX = '/api/v1/documents'