import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './personal-info.scss';
import { IDriverPersonalInfo } from './types';

const PersonalInfo = (props: IDriverPersonalInfo) => {

    return (<Container fluid className='personal-info mb-5'>
        <div className='personal-info-details'>
            <div>
                <div
                    className="avatar me-2"
                    style={{
                        backgroundImage: `url(${props.profilePhoto})`,
                        width: '120px',
                        height: '120px',
                        maxWidth: 'none'
                    }}
                ></div>
            </div>
            <div>
                <Row className='mb-2'>
                    <Col className='personal-info-name'>
                        {props?.name}
                    </Col>
                </Row>
                <Row className='mb-2'>
                    <Col className='personal-info-company'>
                        {props?.companyName}
                    </Col>
                </Row>
                <Row className='mb-2'>
                    <Col className='personal-info-contact'>
                        {props?.email}
                    </Col>
                </Row>
                <Row className='mb-2'>
                    <Col className='personal-info-contact'>
                        {props?.phone}
                    </Col>
                </Row>
            </div>
        </div>
    </Container>)
}

export default PersonalInfo