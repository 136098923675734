import React, { ReactNode } from 'react'
import BackIcon from '../../../../assets/images/svg/BackIcon'
import './header.scss'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import PrimaryButton from '../../../../components/primaryButton/PrimaryButton'

interface Props {
  title: string
  className?: string
  redText?: string
  ctaText?: string
  ctaOnClick?: React.MouseEventHandler<HTMLButtonElement>
  CtaComponent?: ReactNode
}

const DetailHeader = (props: Props) => {
  const {
    title,
    className,
    redText,
    ctaText = '',
    ctaOnClick,
    CtaComponent
  } = props
  const navigate = useNavigate()
  return (
    <div className={`detail_header d-flex ${className ?? ''}`}>
      {redText && (
        <Button
          className="d-flex back-button align-items-center justify-content-center"
          variant="link"
          onClick={() => {
            navigate(-1)
          }}
        >
          <BackIcon />
        </Button>
      )}
      <div className="title">{title}</div>
      <div className="flex-grow-1"></div>
      {!!redText && <div className="red-text">{redText}</div>}
      {!!ctaText && (
        <PrimaryButton
          title={ctaText}
          className={`mt-0 ms-4`}
          onClick={ctaOnClick}
        />
      )}
      {CtaComponent}
    </div>
  )
}
export default DetailHeader
