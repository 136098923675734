import { UserResponse } from '../../../api/types/Driver'
import FormSelect from '../../../components/formSelect/FormSelect'

interface IDriverSelectorProps {
  allDriversList: UserResponse[]
  onChange?: React.ChangeEventHandler<HTMLSelectElement>
  selectedDriver?: number
}

const AnSecCompDriverSelector = (props: IDriverSelectorProps) => {
  const { allDriversList, onChange, selectedDriver } = props

  return (
    <FormSelect
      placeholder="Driver"
      // placeholderOptionText={'All Drivers'}
      value={`${selectedDriver}`}
      onChange={onChange}
    >
      {!!allDriversList && allDriversList.length > 0 ? (
        allDriversList.map((driverItem: UserResponse, i: number) => (
          <option value={driverItem?.driver_details?.id} key={i}>
            {driverItem.name || `ID #${driverItem?.driver_details?.id}`}
            {!!driverItem?.company_details
              ? ` [${driverItem?.company_details?.company_name}]`
              : ' - WaterFront Driver'}
          </option>
        ))
      ) : (
        <option value={''} disabled>
          No drivers available
        </option>
      )}
    </FormSelect>
  )
}
export default AnSecCompDriverSelector
