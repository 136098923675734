import { Col, Container, Form, Row } from "react-bootstrap";
import { IDriverAccidentRecords } from "./types";
import { FormikProvider, useFormik } from "formik";
import CardWithHeader from "../../../../components/cardWithHeader/CardWithHeader";
import FormControl from "../../../../components/formControl/FormControl";
import { IAccidentRecords } from "../../../../api/types/Driver";
import './accident-records.scss';
import SectionHeading from "../section-heading/section-heading";


const AccidentRecords = (props: IDriverAccidentRecords) => {

  const { accidentRecords, recordNumber } = props;

  const initialValues: IAccidentRecords = {
    date: accidentRecords?.date,
    nature_of_accident: accidentRecords?.nature_of_accident,
    number_of_fatalities: accidentRecords?.number_of_fatalities,
    number_of_injuries: accidentRecords?.number_of_injuries,
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values: IAccidentRecords) => { }
  })

  return (
    <Col lg={4} md={6}>

      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <CardWithHeader title={'Entry '+ recordNumber}>
            <div>
              <FormControl
                type="date"
                placeholder="Date"
                controlId="validateDate"
                onChange={e => formik.setFieldValue('date', e.target.value)}
                errorMessage={formik.errors.date}
                value={formik.values.date}
                isInvalid={!!formik.errors.date}
                showLabel={true}
                readOnly
              />
              <FormControl
                placeholder="Nature of Accident"
                controlId="validateAccident"
                onChange={e => formik.setFieldValue('nature_of_accident', e.target.value)}
                errorMessage={formik.errors.nature_of_accident}
                value={formik.values.nature_of_accident}
                isInvalid={!!formik.errors.nature_of_accident}
                showLabel={true}
                readOnly
              />
              <FormControl
                placeholder="Number of Fatalities"
                controlId="validateFatalities"
                onChange={e => formik.setFieldValue('number_of_fatalities', e.target.value)}
                errorMessage={formik.errors.number_of_fatalities}
                value={formik.values.number_of_fatalities}
                isInvalid={!!formik.errors.number_of_fatalities}
                showLabel={true}
                readOnly
              />
              <FormControl
                placeholder="Number of Injuries"
                controlId="validateInjuries"
                onChange={e => formik.setFieldValue('number_of_injuries', e.target.value)}
                errorMessage={formik.errors.number_of_injuries}
                value={formik.values.number_of_injuries}
                isInvalid={!!formik.errors.number_of_injuries}
                showLabel={true}
                readOnly
              />
            </div>
          </CardWithHeader>
        </Form>
      </FormikProvider>
    </Col>
  )
}

export default AccidentRecords;