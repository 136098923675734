import React, { SVGProps } from 'react'

function ProblemIcon(props: SVGProps<SVGSVGElement>) {
  const { color = '#999999' } = props

  return (
    <svg
      width={26}
      height={26}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={color}
        d="M13 19.7688C13.3397 19.7688 13.6337 19.6447 13.882 19.3964C14.1302 19.1481 14.2544 18.8542 14.2544 18.5145C14.2544 18.1768 14.1302 17.882 13.882 17.6301C13.6337 17.3781 13.3397 17.2522 13 17.2522C12.6604 17.2522 12.3664 17.3781 12.1181 17.6301C11.8698 17.882 11.7457 18.1768 11.7457 18.5145C11.7457 18.8522 11.8698 19.1456 12.1181 19.3949C12.3664 19.6442 12.6604 19.7688 13 19.7688ZM13 15.0007C13.307 15.0007 13.5584 14.9028 13.7542 14.707C13.95 14.5112 14.0479 14.2599 14.0479 13.9529V7.00583C14.0479 6.70417 13.95 6.45362 13.7542 6.2542C13.5584 6.05478 13.307 5.95507 13 5.95507C12.6931 5.95507 12.4417 6.05421 12.2459 6.2525C12.0501 6.45081 11.9522 6.70136 11.9522 7.00413V13.9498C11.9522 14.2514 12.0501 14.5019 12.2459 14.7015C12.4417 14.901 12.6931 15.0007 13 15.0007ZM8.86528 25.1993C8.56161 25.1993 8.26525 25.1372 7.97618 25.013C7.68711 24.8889 7.43927 24.7244 7.23265 24.5196L1.48048 18.7674C1.27566 18.5608 1.11117 18.3129 0.987015 18.0239C0.862859 17.7348 0.800781 17.4384 0.800781 17.1348V8.86523C0.800781 8.56156 0.862859 8.2652 0.987015 7.97613C1.11117 7.68707 1.27566 7.43922 1.48048 7.2326L7.23265 1.48043C7.437 1.27608 7.68408 1.11038 7.97388 0.983333C8.26368 0.856266 8.56081 0.792732 8.86528 0.792732H17.1348C17.4393 0.792732 17.7364 0.856266 18.0262 0.983333C18.316 1.11038 18.5631 1.27608 18.7674 1.48043L24.5196 7.2326C24.724 7.43696 24.8897 7.68403 25.0167 7.97383C25.1438 8.26363 25.2073 8.56076 25.2073 8.86523V17.1348C25.2073 17.4392 25.1438 17.7364 25.0167 18.0262C24.8897 18.316 24.724 18.563 24.5196 18.7674L18.7674 24.5196C18.5608 24.7244 18.313 24.8889 18.0239 25.013C17.7348 25.1372 17.4385 25.1993 17.1348 25.1993H8.86528Z"
      />
    </svg>
  )
}

export default ProblemIcon
