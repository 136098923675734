import React from 'react'
import "./driving-experience.scss"
import { Col, Row } from "react-bootstrap";
import SectionHeading from "../section-heading/section-heading";
import Experience from './experience/experience';
import { IDrivingExperience } from './types';


const DrivingExperience = (props: IDrivingExperience) => {

    const { drivingExperience } = props;

    const getTruckType = (id: number) =>{
        switch(id){
            case 1:
                return 'Straight Truck';
            case 2:
                return 'Tractor & Semi-Trailer';
            case 3:
                return 'Tractor & 2 Trailers';
            case 4:
                return 'Tractor & Tanker';
            case 5:
                return 'Other';
        }
    }

    return (<>
        <Row>
            <Col sm={12}>
                <SectionHeading title="Driving Experience">
                    <Row>
                        {drivingExperience?.map((exp, index) => {
                            return <Col lg={4} md={6} key={index}>
                                <Experience
                                    title={getTruckType(exp.truck_type) || ''}
                                    experienceInfo={{
                                        type_of_equipment: exp?.equipment_type || '',
                                        date_from: exp?.date_from || '',
                                        date_to: exp?.date_to || '',
                                        approx_miles: exp?.approximate_miles_driven || undefined
                                    }}
                                />
                            </Col>

                        })}
                    </Row>
                </SectionHeading>
            </Col>
        </Row>
    </>
    )
}

export default DrivingExperience