import { Form } from "react-bootstrap";
import { FormikProvider, useFormik } from "formik";
import CardWithHeader from "../../../../../components/cardWithHeader/CardWithHeader";
import FormControl from "../../../../../components/formControl/FormControl";
import './education.scss'
import { IEducation } from "../../../../../api/types/Driver";
import { IDriverEducation } from "./types";

const Education = (props: IDriverEducation) => {

    const { education, title } = props;

    const initialValues: IEducation = {
        high_school: education?.high_school,
        location: education?.location,
        course_of_study: education?.course_of_study,
        year_completed: education?.year_completed,
        graduate: education?.graduate,
        details: education?.details,
    }

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (values: IEducation) => { }
    })

    return (
        <div className="driver-education">
            <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit}>
                    <CardWithHeader title={title || ''}>
                        <div>
                            <FormControl
                                placeholder={"Name"}
                                controlId="validateSchool"
                                onChange={e => formik.setFieldValue('high_school', e.target.value)}
                                errorMessage={formik.errors.high_school}
                                value={formik.values.high_school}
                                isInvalid={!!formik.errors.high_school}
                                showLabel={true}
                                readOnly
                            />
                            <FormControl
                                placeholder="Location"
                                controlId="validateLocation"
                                onChange={e => formik.setFieldValue('location', e.target.value)}
                                errorMessage={formik.errors.location}
                                value={formik.values.location}
                                isInvalid={!!formik.errors.location}
                                showLabel={true}
                                readOnly
                            />
                            <FormControl
                                placeholder="Course of Study"
                                controlId="validateCourseOfStudy"
                                onChange={e => formik.setFieldValue('course_of_study', e.target.value)}
                                errorMessage={formik.errors.course_of_study}
                                value={formik.values.course_of_study}
                                isInvalid={!!formik.errors.course_of_study}
                                showLabel={true}
                                readOnly
                            />
                            <FormControl
                                placeholder="Year of Completion"
                                controlId="validateYearCompleted"
                                onChange={e => formik.setFieldValue('year_completed', e.target.value)}
                                errorMessage={formik.errors.year_completed}
                                value={formik.values.year_completed}
                                isInvalid={!!formik.errors.year_completed}
                                showLabel={true}
                                readOnly
                            />
                            <div className="question">Graduate</div>
                            <div className="answer">{education?.graduate ? 'Yes' : 'No'}</div>
                            <FormControl
                                placeholder="Details to be listed here if any"
                                controlId="validateDetails"
                                onChange={e => formik.setFieldValue('details', e.target.value)}
                                errorMessage={formik.errors.details}
                                value={formik.values.details}
                                isInvalid={!!formik.errors.details}
                                showLabel={true}
                                readOnly
                            />
                        </div>
                    </CardWithHeader>
                </Form>
            </FormikProvider>
        </div>
    )
}

export default Education