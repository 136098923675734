import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { LoginResponse } from "../types/Login"

export interface CommonState {
  user?: LoginResponse;
}

const initialState: CommonState = {
  user: undefined
}

export const commonSlice = createSlice({
  name: "commonSlice",
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<LoginResponse>) => {
      state.user = action?.payload
    }
  }
})

export const { setUserData } = commonSlice.actions

export default commonSlice.reducer
