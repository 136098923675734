import { UserResponse } from '../../../api/types/Driver'

const JobDetailTrackingDriver = ({
  jobDriver
}: {
  jobDriver: UserResponse
}) => {
  return (
    <div className="job-detail__tracking--driver">
      <div
        className="avatar me-3"
        style={{
          backgroundImage: `url(${jobDriver?.user_profile?.profile_photo})`
        }}
      ></div>
      <div className="driver-details">
        <div className="name">
          {jobDriver?.name}
          {!!jobDriver?.company_details && (
            <>
              {' '}
              - <span>({jobDriver?.company_details?.company_name})</span>
            </>
          )}
        </div>
        <div className="phone">{jobDriver?.user_profile?.phone_number}</div>
      </div>
    </div>
  )
}
export default JobDetailTrackingDriver
