import { Form } from "react-bootstrap";
import { FormikProvider, useFormik } from "formik";
import CardWithHeader from "../../../../../components/cardWithHeader/CardWithHeader";
import FormControl from "../../../../../components/formControl/FormControl";
import './experience.scss'
import { IExperience } from "../../../../../api/types/Driver";
import { IDrivingExperience } from "./types";

const Experience = (props: IDrivingExperience) => {

    const { experienceInfo, title } = props;

    const initialValues: IExperience = {
        type_of_equipment: experienceInfo?.type_of_equipment,
        date_from: experienceInfo?.date_from,
        date_to: experienceInfo?.date_to,
        approx_miles: experienceInfo?.approx_miles
    }

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (values: IExperience) => { }
    })

    return (
        <div className="driver-license-info">
            <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit}>
                    <CardWithHeader title={title || ''}>
                        <div>
                            <FormControl
                                placeholder="Type of Equipment"
                                controlId="validateTypeOfEquipment"
                                onChange={e => formik.setFieldValue('type_of_equipment', e.target.value)}
                                errorMessage={formik.errors.type_of_equipment}
                                value={formik.values.type_of_equipment}
                                isInvalid={!!formik.errors.type_of_equipment}
                                showLabel={true}
                                readOnly
                            />
                            <FormControl
                                type="date"
                                placeholder="Date From"
                                controlId="validateDateFrom"
                                onChange={e => formik.setFieldValue('date_from', e.target.value)}
                                errorMessage={formik.errors.date_from}
                                value={formik.values.date_from}
                                isInvalid={!!formik.errors.date_from}
                                showLabel={true}
                                readOnly
                            />
                            <FormControl
                                type="date"
                                placeholder="Date to"
                                controlId="validateDateTo"
                                onChange={e => formik.setFieldValue('date_to', e.target.value)}
                                errorMessage={formik.errors.date_to}
                                value={formik.values.date_to}
                                isInvalid={!!formik.errors.date_to}
                                showLabel={true}
                                readOnly
                            />
                            <FormControl
                                placeholder=" Approximate Miles Done"
                                controlId="validateApproxMiles"
                                onChange={e => formik.setFieldValue('approx_miles', e.target.value)}
                                errorMessage={formik.errors.approx_miles}
                                value={formik.values.approx_miles}
                                isInvalid={!!formik.errors.approx_miles}
                                showLabel={true}
                                readOnly
                            />
                        </div>
                    </CardWithHeader>
                </Form>
            </FormikProvider>
        </div>
    )
}

export default Experience