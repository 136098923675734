import { createBrowserRouter, RouteObject } from 'react-router-dom'
import { ROUTE_NAMES } from './routerNames'
import LoginLayout from '../../layout/loginLayout/loginLayout'
import PageLogin from '../../pages/login/login'
import DashboardLayout from '../../layout/dashboardLayout/dashboardLayout'
import PageUsersList from '../../pages/dashboard/users-list/users-list'
import PageDriverDetail from '../../pages/dashboard/driver-detail/driver-detail'
import PageCompanyDetail from '../../pages/dashboard/company-detail/company-detail'
import PageAddUser from '../../pages/dashboard/add-user/add-user'
import PageFeedbacks from '../../pages/dashboard/feedbacks/feedbacks'
import PageForgotPassword from '../../pages/forgotPasswod/forgot-password'
import PageResetPassword from '../../pages/restPassword/reset-password'
import PageAddJobs from '../../pages/jobs/addJobs/add-jobs'
import PageJobList from '../../pages/jobs/viewJobs/job-list'
import PageJobDetail from '../../pages/jobs/jobDetail/job-detail'
import PageSettings from '../../pages/settings/settings'
import PageSetNewPassword from '../../pages/setNewPassword/set-new-password'
import EmptyPage from '../../pages/settings/emptyPage'
import PageAnalytics from '../../pages/analytics/analytics'
import PageFourOFour from '../../pages/four-o-four/four-o-four'
import TruckList from '../../pages/dashboard/truck-list/truck-list'
import AddTruckPage from '../../pages/add-truck/add-truck'
import TruckDetailsPage from '../../pages/dashboard/truck-details/truck-details'
import TruckData from '../../pages/dashboard/truck-list/truck-data'
import EditTruckPage from '../../pages/dashboard/truck-details/truck-edit'
import PrivacyPolicy from '../../pages/privacyPolicy/privay-policy'

const routes: RouteObject[] = [
  {
    path: ROUTE_NAMES.landingPage,
    element: <LoginLayout />,
    children: [
      {
        element: <PageLogin />,
        index: true
      },
      {
        path: ROUTE_NAMES.authChildren.forgotPassword,
        element: <PageForgotPassword />
      },
      {
        path: ROUTE_NAMES.authChildren.resetPassword,
        element: <PageResetPassword />
      },
      {
        path: ROUTE_NAMES.authChildren.setPassword,
        element: <PageSetNewPassword />
      },
      {
        path: ROUTE_NAMES.privacyPolicy,
        element: <PrivacyPolicy />
      }
    ]
  },
  {
    path: ROUTE_NAMES.dashboard,
    element: <DashboardLayout />,
    children: [
      {
        path: ROUTE_NAMES.dashboardChildren.addUsers,
        element: <PageAddUser />,
        index: true
      },
      {
        path: ROUTE_NAMES.dashboardChildren.users,
        element: <PageUsersList />
      },
      {
        path: ROUTE_NAMES.dashboardChildren.driverDetail,
        element: <PageDriverDetail />
      },
      {
        path: ROUTE_NAMES.dashboardChildren.companyDetail,
        element: <PageCompanyDetail />
      },
      {
        path: ROUTE_NAMES.dashboardChildren.feedback,
        element: <PageFeedbacks />
      },
      {
        path: ROUTE_NAMES.dashboardChildren.addJobs,
        element: <PageAddJobs />
      },
      {
        path: ROUTE_NAMES.dashboardChildren.editJob,
        element: <PageAddJobs />
      },
      {
        path: ROUTE_NAMES.dashboardChildren.jobs,
        element: <PageJobList />
      },
      {
        path: ROUTE_NAMES.dashboardChildren.jobDetail,
        element: <PageJobDetail />
      },
      {
        path: ROUTE_NAMES.dashboardChildren.settings,
        element: <PageSettings />
      },
      {
        path: ROUTE_NAMES.dashboardChildren.analytics,
        element: <PageAnalytics />
      },
      {
        path: ROUTE_NAMES.dashboardChildren.truckList,
        element: <TruckData />
      },
      {
        path: ROUTE_NAMES.dashboardChildren.addTruckDetails,
        element: <AddTruckPage />
      },
      {
        path: ROUTE_NAMES.dashboardChildren.editTruck,
        element: <EditTruckPage />
      },
      {
        path: ROUTE_NAMES.dashboardChildren.truckDetails,
        element: <TruckDetailsPage />
      }
    ]
  },
  {
    path: '*',
    element: <PageFourOFour />
  }
]

const router = createBrowserRouter(routes)

export default router
