import React from 'react'
import "./education-list.scss"
import { Col, Row } from "react-bootstrap";
import SectionHeading from "../section-heading/section-heading";
import Education from './education/education';
import { EducationListProps } from './types';


const EducationList = (props: EducationListProps) => {

    const { education } = props;

    return (<>
        <Row>
            <Col sm={12}>
                <SectionHeading title="Education" smallHeading={false}>
                    <Row>
                        {education?.map((record, index) => {
                            const name = index === 0 ? 'High School' : index === 1 ? 'College' : 'Other';
                            return <Col lg={4} md={6}>
                                <Education
                                    title={name}
                                    education={{
                                        high_school: record?.name || '',
                                        location: record?.location || '',
                                        course_of_study: record?.course_of_study || '',
                                        details: record?.details || '',
                                        graduate: record?.is_graduated || false,
                                        year_completed: record?.years_completed || undefined,
                                    }}
                                />
                            </Col>

                        })}
                    </Row>
                </SectionHeading>
            </Col>
        </Row>

    </>
    )
}

export default EducationList