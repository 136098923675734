import { Button, Modal } from 'react-bootstrap'
import { IAppStyledModal } from './types'
import PrimaryButton from '../../primaryButton/PrimaryButton'

import './appStyledModal.scss'

const AppStyledModal = (props: IAppStyledModal) => {
  const {
    children,
    closeButton = false,
    show,
    handleClose,
    title,
    message,
    leftButtonText,
    rightButtonText,
    rightButtonDisabled = false,
    leftOnPress,
    rightOnPress
  } = props
  return (
    <Modal className="app-styled" show={show} onHide={handleClose} centered>
      <Modal.Header closeButton={closeButton}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!!message && <p>{message}</p>}
        {children}
      </Modal.Body>
      <Modal.Footer>
        {!!leftButtonText && (
          <Button variant="secondary" onClick={leftOnPress ?? handleClose}>
            {leftButtonText}
          </Button>
        )}
        {!!rightButtonText && (
          <PrimaryButton
            title={rightButtonText}
            type="button"
            onClick={rightOnPress ?? handleClose}
            disabled={rightButtonDisabled}
            className='ms-3'
          />
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default AppStyledModal
