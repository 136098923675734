import { useEffect, useState, } from 'react'
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom'
import { Form, FormikProvider, useFormik } from 'formik'
import { jobDetail, ReassignJobPayload } from '../../../api/types/Jobs'
import FormControl from '../../../components/formControl/FormControl'
import AppStyledModal from '../../../components/modal/appStyledModal/appStyledModal'
import PrimaryButton from '../../../components/primaryButton/PrimaryButton'
import { reAssignJobSchema } from '../../../utils/Validations'
import { useLazyReverseGeocodeQuery } from '../../../api/network/radarApiService'
import { ReverseGeocodePayload } from '../../../api/types/Radar'
import {
    useLazyAllCompaniesQuery,
    useCompanyDetailQuery,
    useLazyAllDriversQuery
} from '../../../api/network/userApiService'
import FormSelect from '../../../components/formSelect/FormSelect'
import { UserResponse } from '../../../api/types/Driver'
import { userStatus } from '../../../utils/constants'
import { toast } from 'react-toastify'
import { useReassignJobMutation } from '../../../api/network/jobsApiService'
import { ROUTE_NAMES } from '../../../utils/route/routerNames'
import { useLazyAllTrucksQuery } from '../../../api/network/truckApiServices'
import { result } from 'lodash'
import { Add_Truck, BASE_URL } from '../../../api/network/ServerUrl'
import { fetchUserData } from '../../../utils/storage'
import Select from 'react-select';
import { InfiniteLoader, List, AutoSizer, IndexRange } from 'react-virtualized';
import { TruckResponse } from '../../../api/types/Driver'

const JobDetailTruckAssign = ({
    jobDetail,
    setIsProcessing
}: {
    jobDetail: jobDetail
    setIsProcessing: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    const navigate = useNavigate()
    const userData = fetchUserData()
    const [showTruckAssignModal, setShowTruckAssignModal] = useState(false)
    const [showAlertModal, setShowAlertModal] = useState(false)

    const [allTrucksTrigger, { data, isLoading }] = useLazyAllTrucksQuery()
    const { results, count, total_pages } = data || {}

    const [loading, setLoading] = useState(false);
    const [rowCount, setRowCount] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [collection, setCollection] = useState<TruckResponse[]>([]);
    // const [options, setOptions] = useState<Array<{ value: number; label: string }>>([])
    const pageSize = 10;
    const rowHeight = 24;
    const maxListheight = pageSize * rowHeight;

    const onGetAllTrucks = async (config:any) => {
            console.log("config",config)
            await allTrucksTrigger(config)
            // const { results, count, total_pages } = data || {}
            // setRowCount(count || 0);
            // setTotalPages(total_pages || 0);
            // console.log("data",data);
            // setCollection((prevCollection) => [...prevCollection, ...(results || [])]);
            // console.log("collection after",collection);
            // setLoading(false);
    };

    useEffect(() => {
        if (currentPage <= totalPages) {
            setLoading(true);
            onGetAllTrucks({ status: "1", page: currentPage });
            console.log("collection",collection);
        }
    }, [currentPage]);

    useEffect(() => {
        // Update options whenever collection changes
        console.log(data);
        const { results, count, total_pages } = data || {}
        setRowCount(count || 0);
        setTotalPages(total_pages || 0);
        console.log("data", data);
        setCollection((prevCollection) => [...prevCollection, ...(results || [])]);
        console.log("collection after", collection);
        setLoading(false);
        // const newOptions = collection.map((u) => ({ value: u.id, label: u?.make_model }));
        // setOptions(newOptions);
    }, [data]);

    const options = collection?.map((u) => ({ value: u.id, label: u?.make_model }));

    const loadMoreRows = ({ startIndex }: IndexRange): Promise<any> => {
        const newPage = Math.floor(startIndex / pageSize + 1);
        setCurrentPage(newPage);
        return Promise.resolve();
    };

    const currentListHeight = pageSize * rowHeight;
    const listHeight = collection.length > currentListHeight ? maxListheight : currentListHeight;

    interface MenuListProps {
        children: ReactNode;
    }
    interface RowRendererProps {
        index: number;
        isScrolling: boolean;
        isVisible: boolean;
        style: React.CSSProperties;
    }

    const MenuList: React.FC<MenuListProps> = ({ children, ...menuListProps }) => {
        console.log(menuListProps);
        const childrenArray = React.Children.toArray(children);

        const rowRenderer = ({ index, isScrolling, isVisible, style, ...rest }: RowRendererProps) => {
            const child = childrenArray[index];
            return (
                <div style={{ ...style }} {...rest}>
                    {loading ? `Loading...` : child}
                </div>
            );
        };

        return (
            <InfiniteLoader isRowLoaded={({ index }) => !!collection[index]} loadMoreRows={loadMoreRows} rowCount={rowCount} threshhold={5}>
                {({ onRowsRendered, registerChild }) => (
                    <AutoSizer disableHeight>
                        {({ width }) => (
                            <List height={listHeight} onRowsRendered={onRowsRendered} ref={registerChild} rowCount={rowCount} rowHeight={rowHeight} rowRenderer={rowRenderer} width={width} />
                        )}
                    </AutoSizer>
                )}
            </InfiniteLoader>
        );
    };

    useEffect(() => {

        allTrucksTrigger({status: "1"})

    }, [allTrucksTrigger])

    const [initialFormValues, setInitialFormValues] = useState({
        truck: ''
    })

    const formik = useFormik({
        initialValues: initialFormValues,
        enableReinitialize: true,
        validationSchema: reAssignJobSchema,
        onSubmit: (values: any) => {
            let requestData = {
                ...values
            }
            console.log(values)
        }
    })

    useEffect(() => {
        console.log("truck",formik.values.truck);
    }, [formik.values.truck])


    const handleTruckAssignClick = () => {
        setShowTruckAssignModal(true)
    }

    const dismissPopup = () => {
        setShowTruckAssignModal(false)
    }

    const dismissAlert = () => {
        handleAssignTruckAPI()
        setShowAlertModal(false)
        setShowTruckAssignModal(false)
    }

    const cancelAlert = () => {
        setShowAlertModal(false)
        setShowTruckAssignModal(true)
    }

    const handleSubmitReAssign = () => {
        if ((results?.find(item => item.id === parseInt(formik.values.truck)) || {}).allocated_driver !== "Not Allocated") {
            setShowAlertModal(true)
        } else {
            handleAssignTruckAPI()
            setShowTruckAssignModal(false)
        }
    }

    const handleAssignTruckAPI = async () => {
        const formData = new FormData()
        formData.append('driver', `${jobDetail.job_details.assigned_driver?.user_profile.id}`)

        try {
            const response = await fetch(`${BASE_URL}${Add_Truck}${formik.values.truck}/`, {
                method: 'PATCH',
                body: formData,
                headers: {
                    Authorization: `Token ${userData.token}`
                }
            })
            const data = await response.json()
            const key_data = Object.keys(data)[0]

            // Accessing the value using the key
            const error_value = data[key_data][0]
            if (response.ok) {
                console.log('response', response)
                toast.success('Truck assigned Successfully')
                formik.resetForm()
                // navigate(`${ROUTE_NAMES.dashboardChildren.jobs}`)
                setTimeout(() => {
                    window.location.reload();
                  }, 1000);

            } else {
                console.log('response', response)
                toast.error(error_value ? error_value : 'Something went wrong')
            }
        } catch (e) {
            console.log('error', e)
        }
    }

    return (
        <>
            <div className="d-flex">
                <PrimaryButton
                    title="Assign a New Truck"
                    className="mt-0"
                    type="button"
                    onClick={handleTruckAssignClick}
                    variant={
                        !!jobDetail?.job_details?.linked_job ? 'secondary' : 'primary'
                    }
                    disabled={!!jobDetail?.job_details?.linked_job}
                />

            </div>
            <FormikProvider value={formik}>
                <AppStyledModal
                    closeButton={false}
                    handleClose={dismissPopup}
                    show={showTruckAssignModal}
                    title="Re-assign Truck"
                    leftOnPress={dismissPopup}
                    leftButtonText={`Cancel`}
                    rightOnPress={handleSubmitReAssign}
                    rightButtonText={`Confirm`}
                >
                    <Form className="mt-3" onSubmit={formik.handleSubmit}>
                        <p className="fs-20">
                            Please select a truck you wish to assign to the Driver.
                        </p>


                        {/* <FormSelect
                            type="select"
                            placeholder="Truck"
                            placeholderOptionText="Please select a truck"
                            controlId="validateTruck"
                            onChange={e => formik.setFieldValue('truck', e.target.value)}
                            //   errorMessage={formik.errors}
                            value={formik.values.truck}
                            //   isInvalid={!!formik.errors}
                            showLabel={true}
                        >
                            {results?.map((truck) => {
                                return <option value={truck.id}>
                                    {truck.make_model}
                                </option>
                            })}
                        </FormSelect> */}

                        <Select
                            // defaultMenuIsOpen={true}
                            isLoading={loading}
                            options={options}
                            components={{
                                MenuList
                            }}
                            onChange={(selectedOption) => formik.setFieldValue('truck', selectedOption?.value)}
                        />

                    </Form>
                </AppStyledModal>

                <AppStyledModal
                    closeButton={false}
                    handleClose={dismissAlert}
                    show={showAlertModal}
                    title="New Truck Allotment"
                    leftOnPress={cancelAlert}
                    leftButtonText={`Cancel`}
                    rightOnPress={dismissAlert}
                    rightButtonText={`Confirm`}
                >
                    <p className="fs-20 my-3">
                        <p>
                            The truck selected is already assign to another driver. Are you
                            sure you want to assign the truck to this driver.
                        </p>
                        <p>This will remove the driver from this truck.</p>
                        <i
                            className="fas fa-exclamation-triangle"
                            style={{ fontSize: '30px', color: 'red' }}
                        />
                    </p>
                </AppStyledModal>
            </FormikProvider>
        </>
    )
}
export default JobDetailTruckAssign
