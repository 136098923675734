import { fetchUserData } from "../../utils/storage";
import { UserResponse } from "../types/Driver";

interface IFetchFileUploadProps {
  formData: FormData;
  headers?: any;
  method?: string;
  successCallback?: Function;
  url: string;
}

export const fetchFileUpload = ({ formData, headers = {}, method = 'POST', successCallback, url }: IFetchFileUploadProps) => {
  const userData = fetchUserData()
  fetch(url, {
    method,
    body: formData,
    headers: {
      Authorization: `Token ${userData.token}`,
      ...headers
    },
  })
  .then(response => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('File upload failed');
    }
  })
  .then(data => {
    console.log('Server response:', data);
    successCallback?.(data)
  })
  .catch(error => {
    console.error('Error uploading file:', error);
  });
}