import { Form, FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState, ReactNode } from 'react'
import { addTruckSchema } from '../../../utils/Validations'
import { Col, Container, Row } from 'react-bootstrap'
import FormControl from '../../../components/formControl/FormControl'
import DetailHeader from '../../jobs/jobDetail/header/header'
import CardWithHeader from '../../../components/cardWithHeader/CardWithHeader'
import { fetchUserData, fetchUserType } from '../../../utils/storage'
import PrimaryButton from '../../../components/primaryButton/PrimaryButton'
import PDF from '../../../assets/images/png/PdfIcon.png'
import { saveAs } from 'file-saver'
import {
  userTypes,
  localStorageKeys,
  DEBOUNCE_TIME
} from '../../../utils/constants'
import {
  useAllDocumentsTypeQuery,
  // useLazyAllTrucksQuery,
  useGetTruckDocumentsQuery,
  usePostTruckMutation,
  useTruckDetailQuery
} from '../../../api/network/truckApiServices'
import { Chip } from '@mui/material'
import DocUpload from '../../../assets/images/svg/DocUpload'
import { AddTruckPayload, UpdateTruckPayload } from '../../../api/types/Trucks'
import DeleteIcon from '../../../assets/images/svg/DeleteIcon'
import {
  API_TRUCK,
  API_TRUCK_URL_SUFFIX,
  Add_Truck,
  BASE_URL
} from '../../../api/network/ServerUrl'
import { toast } from 'react-toastify'
import { DateFormat, changeDateFormat } from '../../../utils/DateUtils'
import Loader from '../../../components/loader/Loader'
import { useNavigate, useParams } from 'react-router-dom'
import FormSelect from '../../../components/formSelect/FormSelect'
import { useLazyAllDriversQuery } from '../../../api/network/userApiService'
import useDebounce from '../../../utils/hooks/useDedounce'
import AppStyledModal from '../../../components/modal/appStyledModal/appStyledModal'
import { ROUTE_NAMES } from '../../../utils/route/routerNames'
import { Form as BootstrapForm, InputGroup } from 'react-bootstrap'
import Select from 'react-select';
import { InfiniteLoader, List, AutoSizer, IndexRange } from 'react-virtualized';
import { UserResponse } from '../../../api/types/Driver'

const EditTruckPage = () => {
  
  const [loading, setLoading] = useState(false)
  const { truckId }: { truckId?: string } = useParams()
  const userData = fetchUserData()
  // const [postTruckApiTrigger, { isLoading }] = usePostTruckMutation()
  console.log('loading', loading)
  const allData = useAllDocumentsTypeQuery({})
  const { data: truckDetails } = useTruckDetailQuery(truckId || '1')
  const [allDriverTrigger, { data: allDriverdata, isLoading }] =
    useLazyAllDriversQuery()
  const debouncedValue = useDebounce('', DEBOUNCE_TIME)

  const { data } = allData || []
  console.log('data', data)

  console.log('drivers', allDriverdata)

  const columns = [
    {
      Header: 'Document Type',
      accessor: 'document_type'
    },
    {
      Header: 'Expiry Date',
      accessor: 'date'
    },
    // {
    //   Header: 'File Type',
    //   accessor: 'file_type'
    // },
    {
      Header: 'Thumbnail',
      accessor: 'thumbnail'
    },
    {
      Header: 'Status',
      id: 'status'
    },
    {
      Header: 'Action',
      id: 'action'
    }
  ]

  const getStatusLabel = (statusCode: number) => {
    switch (statusCode) {
      case 1:
        return 'Approved'
      case 2:
        return 'Rejected'
      case 3:
        return 'Pending'
      case 4:
        return 'Expired'
      default:
        return ''
    }
  }

  //driver dropdown
  const [dropDownloading, setDropdownLoading] = useState(false);
  const [rowCount, setRowCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [collection, setCollection] = useState<UserResponse[]>([]);
  const pageSize = 10;
  const rowHeight = 24;
  const maxListheight = pageSize * rowHeight;

  const onGetAllDrivers = async (config:any) => {
          console.log("config",config)
          await allDriverTrigger(config)
  };

  useEffect(() => {
      if (currentPage <= totalPages) {
          setDropdownLoading(true);
          onGetAllDrivers({ status: "1", page: currentPage });
          console.log("collection",collection);
      }
  }, [currentPage]);

  useEffect(() => {
      // Update options whenever collection changes
      console.log(data);
      const { results, count, total_pages } = allDriverdata || {}
      setRowCount(count || 0);
      setTotalPages(total_pages || 0);
      console.log("data", allDriverdata);
      setCollection((prevCollection) => [...prevCollection, ...(results || [])]);
      console.log("collection after", collection);
      setDropdownLoading(false);
      
  }, [allDriverdata]);

  const options = collection?.map((u) => ({ value: u.user_profile.id, label: u?.name }));

  const loadMoreRows = ({ startIndex }: IndexRange): Promise<any> => {
      const newPage = Math.floor(startIndex / pageSize + 1);
      setCurrentPage(newPage);
      return Promise.resolve();
  };

  const currentListHeight = pageSize * rowHeight;
  const listHeight = collection.length > currentListHeight ? maxListheight : currentListHeight;

  interface MenuListProps {
      children: ReactNode;
  }
  interface RowRendererProps {
      index: number;
      isScrolling: boolean;
      isVisible: boolean;
      style: React.CSSProperties;
  }

  const MenuList: React.FC<MenuListProps> = ({ children, ...menuListProps }) => {
      console.log(menuListProps);
      const childrenArray = React.Children.toArray(children);

      const rowRenderer = ({ index, isScrolling, isVisible, style, ...rest }: RowRendererProps) => {
          const child = childrenArray[index];
          return (
              <div style={{ ...style }} {...rest}>
                  {loading ? `Loading...` : child}
              </div>
          );
      };

      return (
          <InfiniteLoader isRowLoaded={({ index }) => !!collection[index]} loadMoreRows={loadMoreRows} rowCount={rowCount} threshhold={5}>
              {({ onRowsRendered, registerChild }) => (
                  <AutoSizer disableHeight>
                      {({ width }) => (
                          <List height={listHeight} onRowsRendered={onRowsRendered} ref={registerChild} rowCount={rowCount} rowHeight={rowHeight} rowRenderer={rowRenderer} width={width} />
                      )}
                  </AutoSizer>
              )}
          </InfiniteLoader>
      );
  };

  const getFileType = (url: string): string | null => {
    // Split the URL by '/' to separate the path and query parameters
    const urlParts = url.split('?')[0].split('/')

    // Get the last part which contains the filename
    const filename = urlParts[urlParts.length - 1]

    // Split the filename by '.' to get the file extension
    const fileParts = filename.split('.')
    const fileType =
      fileParts.length > 1 ? fileParts[fileParts.length - 1] : null

    return fileType
  }

  const initialValues: UpdateTruckPayload = {
    make_model: '',
    truck_number: '',
    license_plate: '',
    driver: '',
    truck_documents: [
      {
        document: {
          document_type: 0,
          expiry_date: '',
          document_files: [{ file: '' }]
        }
      }
    ]
  }

  const formik = useFormik({
    initialValues,
    validationSchema: addTruckSchema,
    onSubmit: async (values: UpdateTruckPayload) => {
      // image?.map(item => {
      //   if (item.category == 1) {
      //     formik.values.truck_documents.push({
      //       document: {
      //         document_type: item.id,
      //         expiry_date: '2000-03-05',
      //         document_files: [{ file: item.img }]
      //       }
      //     })
      //   }
      // })
      // console.log('values', values)
      // const documents = formik.values.truck_documents?.filter(
      //   item => item.document.document_type != 0
      // )
      // console.log('documents', documents)

      // formik.values.truck_documents.shift()

      const formData = new FormData()
      if(truckDetails?.status!==1){
        formData.append('make_model', values.make_model)
        formData.append('truck_number', values.truck_number)
        formData.append('license_plate', values.license_plate)
      }
      if(truckDetails?.status===1){
        formData.append('driver', `${values.driver}`)
      }
      
      // formData.append('truck_documents[0]document.expiry_date', '')
      // console.log('values', formik.values.truck_documents)
      // formik.values.truck_documents.forEach((obj, index) => {
      //   formData.append(
      //     `truck_documents[${index}]document.document_files[0]file`,
      //     obj.document.document_files[0].file
      //   )
      //   formData.append(
      //     `truck_documents[${index}]document.document_type`,
      //     obj.document.document_type.toString() // Convert number to string
      //   )
      // })

      setTruckFormData(formData)

      const isDriverWithVehicleDetailsPresent = allDriverdata?.results?.some(
        driver => {
          return (
            driver.user_profile.id == values.driver &&
            driver.driver_details.vehicle_details !== null
          )
        }
      )

      if(isDriverWithVehicleDetailsPresent && values.driver!== truckDetails?.driver){
        setShowModal(true);
      }else{
        try {
          setLoading(true)
          const response = await fetch(`${BASE_URL}${Add_Truck}${truckId}/`, {
            method: 'PATCH',
            body: formData,
            headers: {
              Authorization: `Token ${userData.token}`
            }
          })
          const data = await response.json()
          const key_data = Object.keys(data)[0]

          // Accessing the value using the key
          const error_value = data[key_data][0]
          if (response.ok) {
            console.log('response', response)
            toast.success('Truck Updated Successfully')
            formik.resetForm()
            setLoading(false)
            setTimeout(() => {
              navigate(`${ROUTE_NAMES.dashboardChildren.trucks}/${truckId}`)
            }, 1000);
            
            // window.location.reload();
          } else {
            setLoading(false)
            console.log('response', response)
            toast.error(error_value ? error_value : 'Something went wrong')
          }
        } catch (e) {
          console.log('error', e)
          setLoading(false)
        }
      }
    }
  })

  const [truckFormData, setTruckFormData] = useState(new FormData())
  const [showModal, setShowModal] = useState(false)
  const handleCloseModal = () => setShowModal(false)
  const navigate = useNavigate()

  const handleSave = async () => {
    try {
      setLoading(true)
      const response = await fetch(`${BASE_URL}${Add_Truck}${truckId}/`, {
        method: 'PATCH',
        body: truckFormData,
        headers: {
          Authorization: `Token ${userData.token}`
        }
      })

      const data = await response.json()
      const key_data = Object.keys(data)[0]

      // Accessing the value using the key
      const error_value = data[key_data][0]

      if (response.ok) {
        console.log('response', response)
        toast.success('Truck Updated Successfully')
        formik.resetForm()
        setLoading(false)
        setTimeout(() => {
          navigate(`${ROUTE_NAMES.dashboardChildren.trucks}/${truckId}`)
        }, 1000);
        
        // window.location.reload();
      } else {
        setLoading(false)
        console.log('response', response)
        handleCloseModal()
        toast.error(error_value ? error_value : 'Something went wrong')
      }
    } catch (e) {
      console.log('error', e)
      setLoading(false)
    }
  }


  useEffect(() => {
    console.log("truck details", truckDetails)
    formik.values.make_model = truckDetails?.make_model || ''
    formik.values.truck_number = truckDetails?.truck_number || ''
    formik.values.license_plate = truckDetails?.license_plate || ''
    formik.values.driver = truckDetails?.driver || ''

    allDriverTrigger({
      search: debouncedValue
    })

    // if (!truckDetails.length) {
    console.log('model', formik.values.make_model)
    // formik.values.make_model = ''
    // formik.values.truck_number = ''
    // formik.values.license_plate = ''
    // formik.values.registration = ''
    // formik.values.sefty_inspection = ''
    // formik.values.insurance = ''
    // formik.values.rfid = ''
    // }
  }, [])
  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        {loading && (
          <div style={{ marginLeft: '-20%' }}>
            <Loader />
          </div>
        )}

        <Container fluid className="containerWidth">
          <Row>
            <Col>
              <DetailHeader
                title="Edit Truck"
                className={
                  fetchUserType() === userTypes.ADMIN ? 'mb-0' : 'mb-2'
                }
                {...(fetchUserType() === userTypes.COMPANY ||
                fetchUserType() === userTypes.ADMIN
                  ? {
                      CtaComponent: <PrimaryButton title="Save" type="submit" />
                    }
                  : {})}
              />
            </Col>
            <Row className="mt-3">
              <Col md={4}>
                <CardWithHeader title={'Truck Details'}>
                  <div>
                    <FormControl
                      placeholder="Truck Make and Modal *"
                      controlId="ModalName"
                      label="Details"
                      onChange={e =>
                        formik.setFieldValue('make_model', e.target.value)
                      }
                      errorMessage={formik.errors.make_model}
                      value={formik.values.make_model}
                      // isInvalid={!!formik.errors.name}
                      showLabel={true}
                      disabled={(truckDetails?.status == 1) || (truckDetails?.status == 3)}
                    />
                    <FormControl
                      type="text"
                      placeholder="Truck Number"
                      controlId="TruckNumber"
                      label=""
                      onChange={e =>
                        formik.setFieldValue('truck_number', e.target.value)
                      }
                      // errorMessage={formik.errors.truck_number}
                      value={formik.values.truck_number}
                      // isInvalid={!!formik.errors.email}
                      showLabel={true}
                      disabled={(truckDetails?.status == 1) || (truckDetails?.status == 3)}
                    />
                    <FormControl
                      type="text"
                      placeholder="License Plate *"
                      controlId="LicensePlate"
                      label=""
                      onChange={e =>
                        formik.setFieldValue('license_plate', e.target.value)
                      }
                      errorMessage={formik.errors.license_plate}
                      value={formik.values.license_plate}
                      // isInvalid={!!formik.errors.confirmEmail}
                      showLabel={true}
                      disabled={(truckDetails?.status == 1) || (truckDetails?.status == 3)}
                    />

                    {/* <FormSelect
                      placeholder="Allocate Driver"
                      controlId="validateloadType"
                      onChange={e =>
                        formik.setFieldValue('driver', e.target.value)
                      }
                      value={formik.values.driver}
                      showLabel={true}
                      disabled={truckDetails?.status !== 1}
                    >
                      {allDriverdata?.results?.map((driver) => {
                          // if(driver.driver_details.vehicle_details === null)
                          if (driver.user_profile.status === 2) {
                          return <option value={driver.user_profile.id} selected={driver.user_profile.id == formik.values.driver } >
                            {driver.name}
                          </option>
                          }
                      })}
                    </FormSelect> */}
                    <br/><br/>
                    <BootstrapForm.Label className="text-primary">Allotment Details</BootstrapForm.Label>
                    <Select
                      placeholder= "Allocate Driver"
                      isDisabled={truckDetails?.status !== 1}
                      // defaultMenuIsOpen={true}
                      isLoading={dropDownloading}
                      options={options}
                      components={{
                        MenuList
                      }}
                      onChange={(selectedOption) => formik.setFieldValue('driver', selectedOption?.value)}
                    />
                    {/* {truckDetails?.driver &&
                      <Row className='ms-1'>
                        Allocated Driver : {truckDetails?.driver}
                      </Row>
                    } */}
                  </div>
                </CardWithHeader>
              </Col>
            </Row>
          </Row>

          <AppStyledModal
            closeButton={false}
            handleClose={handleCloseModal}
            show={showModal}
            title="New Driver Allotment "
            rightOnPress={handleSave}
            rightButtonText={`Confirm`}
            leftOnPress={handleCloseModal}
            leftButtonText={`Cancel`}
          >
            <p className="fs-20 my-3">
              <p>
                The driver selected is already assign to another truck. Are you
                sure you want to assign this truck to the driver.
              </p>
              <p>This will remove the driver from the current truck.</p>
              <i
                className="fas fa-exclamation-triangle"
                style={{ fontSize: '30px', color: 'red' }}
              />
            </p>
          </AppStyledModal>
        </Container>
      </Form>
    </FormikProvider>
  )
}

export default EditTruckPage
