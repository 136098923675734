import { Form } from 'react-bootstrap'
import { IFormSelect } from './types'

const FormSelect = (props: IFormSelect) => {
  const {
    controlId,
    placeholder = '', // label text
    placeholderOptionText = 'Select',
    errorMessage,
    disabled = false,
    value,
    onChange,
    isInvalid,
    showLabel,
    wrapperClassName = ''
  } = props

  return (
    <Form.Group
      className={`form-field form-field--select col-sm-12 position-relative ${wrapperClassName}`}
      controlId={controlId}
    >
      {showLabel && (
        <Form.Label className="text-primary">{placeholder}</Form.Label>
      )}
      <Form.Select
        aria-label="Default select example"
        // value={value}
        defaultValue={value}
        disabled={disabled}
        onChange={onChange}
        isInvalid={isInvalid}
      >
        <option value={''}>{placeholderOptionText}</option>
        {props.children}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  )
}

export default FormSelect
