import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, FormikProvider, useFormik } from 'formik'
import { jobDetail, ReassignJobPayload } from '../../../api/types/Jobs'
import FormControl from '../../../components/formControl/FormControl'
import AppStyledModal from '../../../components/modal/appStyledModal/appStyledModal'
import PrimaryButton from '../../../components/primaryButton/PrimaryButton'
import { reAssignJobSchema } from '../../../utils/Validations'
import { useLazyReverseGeocodeQuery } from '../../../api/network/radarApiService'
import { ReverseGeocodePayload } from '../../../api/types/Radar'
import {
  useLazyAllCompaniesQuery,
  useCompanyDetailQuery,
  useLazyAllDriversQuery
} from '../../../api/network/userApiService'
import FormSelect from '../../../components/formSelect/FormSelect'
import { UserResponse } from '../../../api/types/Driver'
import { userStatus } from '../../../utils/constants'
import { toast } from 'react-toastify'
import { useReassignJobMutation } from '../../../api/network/jobsApiService'
import { ROUTE_NAMES } from '../../../utils/route/routerNames'

const JobDetailTrackingReAssign = ({
  jobDetail,
  setIsProcessing
}: {
  jobDetail: jobDetail
  setIsProcessing: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const navigate = useNavigate()
  const [showReAssignModal, setShowReAssignModal] = useState(false)

  const [initialFormValues, setInitialFormValues] = useState({
    pickup_address:
      jobDetail?.job_details?.pickdrop_details?.starting_location || '',
    company: `${
      jobDetail?.job_details?.assigned_driver?.company_details?.company_id ?? ''
    }`,
    ce_driver: '',
    ic_driver: ''
  })

  const formik = useFormik({
    initialValues: initialFormValues,
    enableReinitialize: true,
    validationSchema: reAssignJobSchema,
    onSubmit: (values: any) => {
      let requestData = {
        ...values
      }
      console.log(values)
    }
  })

  const [
    reverseGeocodeTrigger,
    {
      data: dataReverseGeocode,
      isLoading: isLoadingReverseGeocode,
      isError: isErrorReverseGeocode
    }
  ] = useLazyReverseGeocodeQuery()

  // START: seqiential API calls to fetch all COMPANIES
  const [companyListPage, setCompanyListPage] = useState<number>(1)
  const [companyList, setCompanyList] = useState<UserResponse[]>([])
  let companyQueryParams = {
    search: '',
    page: companyListPage
  }
  const [
    allCompaniesTrigger,
    { data: dataAllCompanies, isLoading: isLoadingAllCompanies }
  ] = useLazyAllCompaniesQuery()

  useEffect(() => {
    if (!!dataAllCompanies && !isLoadingAllCompanies) {
      setCompanyList([
        ...companyList,
        ...dataAllCompanies?.results.filter(
          company => company?.user_profile?.status === userStatus.ACTIVE
        )
      ])
      if (companyListPage < dataAllCompanies?.total_pages) {
        setCompanyListPage(companyListPage + 1)
      }
    }
  }, [dataAllCompanies, isLoadingAllCompanies])

  useEffect(() => {
    allCompaniesTrigger({
      ...companyQueryParams,
      page: companyListPage
    })
  }, [companyListPage])
  // END: seqiential API calls to fetch all COMPANIES

  const { data: dataCompanyDetails, isLoading: isLoadingCompanyDetails } =
    useCompanyDetailQuery(formik.values.company, {
      skip: !formik.values.company
    })

  // START: seqiential API calls to fetch all DRIVERS
  const [icDriverListPage, setIcDriverListPage] = useState<number>(1)
  const [icDriverList, setIcDriverList] = useState<UserResponse[]>([])
  let icDriverQueryParams = {
    search: '',
    page: icDriverListPage,
    status: `${userStatus.ACTIVE}`
  }
  const [
    allDriversTrigger,
    { data: dataAllDrivers, isLoading: isLoadingAllDrivers }
  ] = useLazyAllDriversQuery()

  useEffect(() => {
    if (!!dataAllDrivers && !isLoadingAllDrivers) {
      setIcDriverList([
        ...icDriverList,
        ...dataAllDrivers?.results.filter(
          icDriver =>
            icDriver?.company_details === null &&
            icDriver?.user_profile?.status === userStatus.ACTIVE &&
            icDriver?.id !== jobDetail?.job_details?.assigned_driver?.id
        )
      ])
      if (icDriverListPage < dataAllDrivers?.total_pages) {
        setIcDriverListPage(icDriverListPage + 1)
      }
    }
  }, [dataAllDrivers, isLoadingAllDrivers])

  useEffect(() => {
    allDriversTrigger({
      ...icDriverQueryParams,
      page: icDriverListPage
    })
  }, [icDriverListPage])
  // END: seqiential API calls to fetch all DRIVERS

  const handleReAssignClick = () => {
    const reverseGeocodeParams: ReverseGeocodePayload = {
      latitude: parseFloat(jobDetail?.job_details?.dump?.latitude as string),
      longitude: parseFloat(jobDetail?.job_details?.dump?.longitude as string)
    }
    if (!!dataReverseGeocode) {
      setShowReAssignModal(true)
    } else {
      reverseGeocodeTrigger(reverseGeocodeParams)
      if (!dataAllCompanies) {
        allCompaniesTrigger(companyQueryParams)
      }
      if (!dataAllDrivers) {
        allDriversTrigger(icDriverQueryParams)
      }
      setIsProcessing(true)
    }
  }

  useEffect(() => {
    if (
      !!dataReverseGeocode &&
      !isLoadingReverseGeocode &&
      !!dataAllCompanies &&
      !isLoadingAllCompanies
    ) {
      setIsProcessing(false)
      setInitialFormValues({
        ...initialFormValues,
        pickup_address: JSON.stringify(dataReverseGeocode?.addresses)
      })
      setShowReAssignModal(true)
    }
  }, [
    dataReverseGeocode,
    isLoadingReverseGeocode,
    dataAllCompanies,
    isLoadingAllCompanies
  ])

  useEffect(() => {
    if (!!isErrorReverseGeocode) {
      setIsProcessing(false)
      toast.info(
        'We could not pin point the exact dump location. Please choose the pick up location manually.',
        { autoClose: 15 * 1000 }
      )
      setInitialFormValues({
        ...initialFormValues
      })
      setShowReAssignModal(true)
    }
  }, [isErrorReverseGeocode])

  useEffect(() => {
    if (!!formik.values.company) {
      setIsProcessing(true)
    }
  }, [formik.values.company])

  // START: fetch CE Drivers
  const [ceDriverList, setCeDriverList] = useState<UserResponse[]>([])
  useEffect(() => {
    if (!!dataCompanyDetails && !isLoadingCompanyDetails) {
      setIsProcessing(false)
      const approvedCeDrivers = dataCompanyDetails.drivers.filter(
        ceDriver =>
          ceDriver?.user_profile?.status === userStatus.ACTIVE &&
          ceDriver?.id !== jobDetail?.job_details?.assigned_driver?.id
      )
      setCeDriverList(approvedCeDrivers)
      if (approvedCeDrivers.length === 0) {
        toast.info('The selected company does not have any approved drivers.')
      }
    } else {
      setCeDriverList([])
    }
  }, [dataCompanyDetails, isLoadingCompanyDetails])
  // END: fetch CE Drivers

  const [
    reassignJobAPITrigger,
    { data: dataReassignJob, isLoading: isLoadingReassignJob }
  ] = useReassignJobMutation()
  useEffect(() => {
    if (!!dataReassignJob && !isLoadingReassignJob) {
      console.log(dataReassignJob)
      toast.success(`The job has been re-assigned to the new driver.`)
      setShowReAssignModal(false)
    }
  }, [dataReassignJob, isLoadingReassignJob])

  const dismissPopup = () => {
    setShowReAssignModal(false)
  }

  const handleSubmitReAssign = () => {
    const { ce_driver, ic_driver, company, ...restFormValues } = formik.values
    if (!!ce_driver && !!ic_driver) {
      toast.error(
        'You have chosen both a Company Driver and a WaterFront Driver. Please select either of them.'
      )
    } else if (!ce_driver && !ic_driver) {
      toast.error(
        'Please select either a Company Driver or a WaterFront Driver.'
      )
    } else {
      let res = ce_driver ? ce_driver : ic_driver
      const reassignJobParams: ReassignJobPayload = {
        id: jobDetail?.job_details?.id,
        ...restFormValues,
        company:
          // !!ce_driver &&
           !isNaN(parseInt(company))
            ? companyList.find(
                companyItem => companyItem.id === parseInt(company)
              )?.company_details?.id
            : null,
        // assigned_driver: parseInt(ce_driver ?? ic_driver)
        assigned_driver: parseInt(res)
      }
      reassignJobAPITrigger(reassignJobParams)
    }
  }

  return (
    <>
      <div className="d-flex">
        <PrimaryButton
          title={`Re-assign${!!jobDetail?.job_details?.linked_job ? 'ed' : ''}`}
          className="mt-0"
          type="button"
          onClick={handleReAssignClick}
          variant={
            !!jobDetail?.job_details?.linked_job ? 'secondary' : 'primary'
          }
          disabled={!!jobDetail?.job_details?.linked_job}
        />
        {!!jobDetail?.job_details?.linked_job && (
          <PrimaryButton
            title="Go to New Job"
            className="mt-0 ms-3"
            type="button"
            onClick={() => {
              navigate(
                `${ROUTE_NAMES.dashboardChildren.jobs}/${jobDetail?.job_details?.linked_job}`
              )
            }}
          />
        )}
      </div>
      <FormikProvider value={formik}>
        <AppStyledModal
          closeButton={false}
          handleClose={dismissPopup}
          show={showReAssignModal}
          title="Re-assign Job"
          leftOnPress={dismissPopup}
          leftButtonText={`Cancel`}
          rightOnPress={handleSubmitReAssign}
          rightButtonText={`Confirm`}
          rightButtonDisabled={!formik.isValid}
        >
          <Form className="mt-3" onSubmit={formik.handleSubmit}>
            <p className="fs-20">
              Please select a driver you wish to assign this job to.
            </p>
            <FormControl
              type="locationAutocomplete"
              placeholder="Pick Up Location"
              onTypeaheadChange={(selectedLocation: any) => {
                formik.setFieldValue(
                  'pickup_address',
                  JSON.stringify(selectedLocation)
                )
              }}
              errorMessage={formik.errors.pickup_address as string}
              value={formik.values.pickup_address}
              isInvalid={!!formik.errors.pickup_address}
              showLabel={true}
            />
            <FormSelect
              placeholder="Company"
              controlId="validateCompany"
              onChange={e => formik.setFieldValue('company', e.target.value)}
              errorMessage={formik.errors.company}
              value={formik.values.company}
              isInvalid={!!formik.errors.company}
              showLabel={true}
            >
              {!!companyList &&
                companyList.map((coItem: UserResponse) => (
                  <option value={coItem.id} key={coItem.id}>
                    {coItem.name || `ID #${coItem.id}`}
                  </option>
                ))}
            </FormSelect>
            <FormSelect
              type="select"
              placeholder="Company Driver"
              placeholderOptionText="Please select a driver"
              controlId="validateCeDriver"
              onChange={e => formik.setFieldValue('ce_driver', e.target.value)}
              errorMessage={formik.errors.ce_driver}
              value={formik.values.ce_driver}
              isInvalid={!!formik.errors.ce_driver}
              showLabel={true}
            >
              {!!ceDriverList && ceDriverList.length > 0 ? (
                ceDriverList.map((coDriverItem: UserResponse) => (
                  <option
                    value={coDriverItem.driver_details?.id}
                    key={coDriverItem.id}
                  >
                    {coDriverItem.name || `ID #${coDriverItem.id}`}
                  </option>
                ))
              ) : (
                <option value={''} disabled>
                  No drivers available
                </option>
              )}
            </FormSelect>
            <div className="form-field-or">Or</div>
            <FormSelect
              placeholder="WaterFront Driver"
              placeholderOptionText="Please select a driver"
              controlId="validateIcDriver"
              onChange={e => formik.setFieldValue('ic_driver', e.target.value)}
              errorMessage={formik.errors.ic_driver}
              value={formik.values.ic_driver}
              isInvalid={!!formik.errors.ic_driver}
              showLabel={true}
            >
              {!!icDriverList && icDriverList.length > 0 ? (
                icDriverList.map((icDriverItem: UserResponse) => (
                  <option
                    value={icDriverItem?.driver_details?.id}
                    key={icDriverItem.id}
                  >
                    {icDriverItem.name || `ID #${icDriverItem.id}`}
                  </option>
                ))
              ) : (
                <option value={''} disabled>
                  No drivers available
                </option>
              )}
            </FormSelect>
          </Form>
        </AppStyledModal>
      </FormikProvider>
    </>
  )
}
export default JobDetailTrackingReAssign
