import { FormikProvider, useFormik } from "formik"
import { Col, Form } from "react-bootstrap"
import { SendFeedbackPayload } from "../../../../api/types/Feedbacks"
import FormControl from "../../../../components/formControl/FormControl"
import CardWithHeader from "../../../../components/cardWithHeader/CardWithHeader"
import PrimaryButton from "../../../../components/primaryButton/PrimaryButton"
import { toast } from "react-toastify"
import { useSendFeedbackMutation } from "../../../../api/network/feedbackApiService"
import { feedbackReplySchema } from "../../../../utils/Validations"
import { useEffect } from "react"
import { ISettings } from "../../../settings/types"

const PageSendFeedback = (props: ISettings) => {
  const initialValues: SendFeedbackPayload = {
    text: ""
  }
  const [sendFeedbackAPITrigger, { isLoading }] = useSendFeedbackMutation()

  const { setLoading } = props
  useEffect(() => {
    setLoading(isLoading)
  }, [isLoading, setLoading])

  const formik = useFormik({
    initialValues,
    validationSchema: feedbackReplySchema,
    onSubmit: (values: SendFeedbackPayload) => {
      sendFeedbackAPITrigger(values)
        .unwrap()
        .then(response => {
          formik.resetForm()
          toast.success("Feedback send successfully")
        })
        .catch(e => {})
    }
  })

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Col md={8}>
        <CardWithHeader title={"Support & Feedback"}>
          <FormControl
            placeholder="Please type in your response here..."
            controlId="feedback"
            className="form-control"
            as="textarea"
            errorMessage={formik.errors.text}
            value={formik.values.text}
            onChange={formik.handleChange("text")}
            isInvalid={!!formik.errors.text}
            style={{ height: '120px' }}
          />
          <div className="d-flex justify-content-end">
            <PrimaryButton title="Submit" className="mt-4" type="submit" />
          </div>
        </CardWithHeader>
        </Col>
      </Form>
    </FormikProvider>
  )
}

export default PageSendFeedback
