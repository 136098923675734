import { Form, FormikProvider, useFormik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { addTruckSchema } from '../../utils/Validations'
import { Col, Container, Row } from 'react-bootstrap'
import FormControl from '../../components/formControl/FormControl'
import DetailHeader from '../jobs/jobDetail/header/header'
import CardWithHeader from '../../components/cardWithHeader/CardWithHeader'
import { fetchUserData, fetchUserType } from '../../utils/storage'
import PrimaryButton from '../../components/primaryButton/PrimaryButton'
import PDF from '../../../src/assets/images/png/PDF.png'
import { saveAs } from 'file-saver'

import { userTypes, localStorageKeys } from '../../utils/constants'
import {
  useAllDocumentsTypeQuery,
  usePostTruckMutation
} from '../../api/network/truckApiServices'
import { Chip } from '@mui/material'
import DocUpload from '../../assets/images/svg/DocUpload'
import { AddTruckPayload } from '../../api/types/Trucks'
import DeleteIcon from '../../assets/images/svg/DeleteIcon'
import {
  API_TRUCK_URL_SUFFIX,
  Add_Truck,
  BASE_URL
} from '../../api/network/ServerUrl'
import { toast } from 'react-toastify'
import { DateFormat, changeDateFormat } from '../../utils/DateUtils'
import Loader from '../../components/loader/Loader'
import { set } from 'lodash'

const AddTruckPage = () => {
  const [image, setImage] = useState<
    {
      is_required: boolean
      id: any
      category: any
      img: string
      imagePrev: string
    }[]
  >([])
  const [expiryDate, setExpiryDate] = useState<
    {
      required: any
      id: any
      category: any
      expiry_date: any
    }[]
  >([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  // const fileInputRef = useRef(null)
  const userData = fetchUserData()
  console.log('image', image, image.length)
  // const [postTruckApiTrigger, { isLoading }] = usePostTruckMutation()
  console.log('loading', loading)
  const allData = useAllDocumentsTypeQuery({})
  const { data } = allData || []
  const docs =
    Array.isArray(data) &&
    data?.filter((el: any) => el?.category == 1 && el?.is_required == true)

  console.log('docs', docs)
  const docLength: any = Object.keys(docs).length
  const requiredDate =
    Array.isArray(data) &&
    data?.filter(
      (el: any) => el?.category == 1 && el?.is_expiry_date_required == true
    )
  console.log('requiredDate', requiredDate)

  const requiredDateLength: any = Object.keys(requiredDate).length
  console.log('data', requiredDateLength)

  const columns = [
    {
      Header: 'Document Type',
      accessor: 'document_type'
    },
    {
      Header: 'Expiry Date',
      accessor: 'date'
    },

    {
      Header: 'Thumbnail',
      accessor: 'thumbnail'
    },
    {
      Header: 'Action',
      id: 'action'
    }
  ]

  const initialValues: AddTruckPayload = {
    make_model: '',
    truck_number: '',
    license_plate: '',
    truck_documents: [
      {
        document: {
          document_type: 0,
          expiry_date: '',
          document_files: [{ file: '' }]
        }
      }
    ]
  }

  const formik = useFormik({
    initialValues,
    validationSchema: addTruckSchema,
    onSubmit: async (values: AddTruckPayload) => {
      console.log('image', image)
      console.log('expiryDate', expiryDate)

      //Documents Validation
      console.log(
        'formik.values.truck_documents',
        formik.values.truck_documents
      )
      console.log('docLength', docLength > image.length)
      if (docLength > image.length) {
        toast.error('Please upload all required documents!')
        return
      } else {
        image.forEach(item => {
          console.log('item', item)
          if (item.is_required == true) {
            if (item.img == '') {
              toast.error('Please upload all required documents!')
              return
            }
          }
        })
      }

      console.log('requiredDateLength', requiredDateLength, expiryDate.length)
      //Expiry Date Validation
      if (requiredDateLength > expiryDate.length) {
        toast.error('Please enter the expiry date for required documents!')
        return
      } else {
        for (const item of Object.values(requiredDate)) {
          const expiryDateItem: any = expiryDate?.find(
            dateItem => dateItem?.id == item?.id
          )
          if (expiryDateItem == undefined || expiryDateItem.expiry_date == '') {
            toast.error('Please enter the expiry date for required documents!')
            return
          }
        }
      }

      console.log('formik', formik.values.truck_documents, docLength)
      // if(formik.values.truck_documents.length<docLength)
      image?.map(item => {
        if (item.category == 1) {
          const expiryDateItem: any = expiryDate?.find(
            dateItem => dateItem?.id == item.id
          )
          const expiryDateValue = expiryDateItem?.expiry_date || '' // Provide a default value of ''

          if (formik.values.truck_documents.length <= docLength) {
            // if (
            //   formik.values.truck_documents?.find(
            //     item => item.document?.document_type == 0
            //   ) == undefined
            // ) {
            formik.values.truck_documents.push({
              document: {
                document_type: item.id,
                expiry_date: expiryDateValue,
                document_files: [{ file: item.img }]
              }
            })
          } else {
            formik.values.truck_documents.forEach((obj, index) => {
              if (obj.document.document_type == item.id) {
                obj.document.document_files[0].file = item.img
                obj.document.expiry_date = expiryDateValue
              }
            })
          }
        }
      })
      console.log('values', values)
      const documents = formik.values.truck_documents?.filter(
        item => item.document.document_type != 0
      )
      console.log('documents', documents)
      // formik.values.truck_documents.shift()
      console.log('values', formik.values.truck_documents.length)

      const newArray = formik.values.truck_documents.filter(
        item => item.document.document_type != 0
      )
      const formData = new FormData()
      formData.append('make_model', values.make_model)
      formData.append('truck_number', values.truck_number)
      formData.append('license_plate', values.license_plate)
      console.log('values', formik.values.truck_documents)
      // if (
      //   formik.values.truck_documents.length > 0 &&
      //   formik.values.truck_documents.length <= docLength
      // ) {
      newArray.forEach((obj, index) => {
        formData.append(
          `truck_documents[${index}]document.document_files[0]file`,
          obj.document.document_files[0].file
        )
        formData.append(
          `truck_documents[${index}]document.document_type`,
          obj.document.document_type.toString() // Convert number to string
        )
        formData.append(
          `truck_documents[${index}]document.expiry_date`,
          obj.document.expiry_date.toString() // Convert number to string
        )
      })
      // }
      console.log('values', formData)
      try {
        console.log('values-error', error)
        setLoading(true)

        const response = await fetch(`${BASE_URL}${Add_Truck}`, {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Token ${userData.token}`
          }
        })
        const data = await response.json()
        const key_data = Object.keys(data)[0]

        // Accessing the value using the key
        const error_value = data[key_data][0]

        // Now 'value' contains "truck with this license plate already exists."
        console.log('error Value', error_value)

        console.log('error data', data?.error?.message?.[0])
        if (response.ok) {
          console.log('response', response)
          toast.success('Truck Added Successfully')
          formik.resetForm()
          setImage([])
          setExpiryDate([])
          setLoading(false)
          setError('')
          // window.location.reload()
        } else {
          setLoading(false)
          console.log('response', response.status)

          toast.error(error_value ? error_value : 'Something went wrong')
          // formik.resetForm()
          // setImage([])
          // setExpiryDate([])
          // window.location.reload()
          // setError('')
        }
      } catch (e) {
        console.log(e)
        formik.resetForm()
        setImage([])
        setExpiryDate([])
        setLoading(false)
        window.location.reload()
      }
    }
  })

  const handleImageChange = (event: any, category: any, required: any) => {
    // event.preventDefault()

    console.log('event', event.target.files[0])
    console.log('category', category)
    // const file = event.target.files[0]
    console.log('id', event.target.id)
    // if (file) {
    //   const reader: any = new FileReader()
    //   console.log('reader', reader)
    //   reader.onload = () => {
    //     if (event.target.id == '1') {
    //       setImage(prevImage => [
    //         ...prevImage,
    //         {
    //           id: event.target.id,
    //           category: category,
    //           img: file,
    //           imagePrev: reader.result
    //         }
    //       ])
    //     } else if (event.target.id == '2') {
    //       setImage(prevImage => [
    //         ...prevImage,
    //         {
    //           id: event.target.id,
    //           category: category,
    //           img: file,
    //           imagePrev: reader.result
    //         }
    //       ])
    //     } else if (event.target.id == '3') {
    //       setImage(prevImage => [
    //         ...prevImage,
    //         {
    //           id: event.target.id,
    //           category: category,
    //           img: file,
    //           imagePrev: reader.result
    //         }
    //       ])
    //     } else if (event.target.id == '4') {
    //       setImage(prevImage => [
    //         ...prevImage,
    //         {
    //           id: event.target.id,
    //           category: category,
    //           img: file,
    //           imagePrev: reader.result
    //         }
    //       ])
    //     }
    //   }
    //   console.log('image', image)
    //   reader.readAsDataURL(file)
    // } else {
    //   // setImage(null)
    // }
    const available = image.find(item => item.id == event.target.id)
    if (available) {
      const file = event.target.files[0]
      const reader: any = new FileReader()
      reader.onload = () => {
        const updatedData: any = image.map(item =>
          item.id == event.target.id
            ? {
                ...item,
                img: file,
                imagePrev: reader.result,
                is_required: required
              }
            : item
        )
        setImage(updatedData)
      }
      event.target.value = ''
      console.log('image', image)
      reader.readAsDataURL(file)
    } else {
      const file = event.target.files[0]
      const reader: any = new FileReader()
      reader.onload = () => {
        setImage(prevImage => [
          ...prevImage,
          {
            id: event.target.id,
            category: category,
            img: file,
            imagePrev: reader.result,
            is_required: required
          }
        ])
      }
      console.log('image', image)
      reader.readAsDataURL(file)
      event.target.value = ''
    }
  }

  const handleDateChange = (e: any, id: any, category: any, required: any) => {
    console.log(
      'date',
      e.target.value,
      'id',
      e.target.id,
      'category',
      category,
      'required',
      required
    )
    console.log("e.target.value == ''", e.target.value)
    const available = expiryDate.find(item => item.id == id)
    console.log('available', available)
    // if (e.target.value) {
    if (available) {
      const updatedData: any = expiryDate.map(item =>
        item.id == id ? { ...item, expiry_date: e.target.value } : item
      )
      setExpiryDate(updatedData)
    } else {
      setExpiryDate(prevDate => [
        ...prevDate,
        {
          id: id,
          category: category,
          expiry_date: e.target.value,
          required: required
        }
      ])
    }
    console.log('expiryDate', expiryDate)
  }
  const handleDeleteDate = (id: any) => {
    console.log('id', id)
    const updatedDate = expiryDate.filter(item => item.id != id)
    setExpiryDate(updatedDate)
    console.log('updatedDate', updatedDate)
  }
  const handleDocDelete = (e: any, id: any) => {
    // e.preventDefault()
    console.log('id', id)
    // fileInputRef.current = null
    const updatedDocs = image.filter(item => item.id != id)
    setImage(updatedDocs)
    console.log('image', image)
    handleDeleteDate(id)
    console.log('expiryDate', expiryDate)
  }
  const removeClearButton = (event: any) => {
    // Find the clear button by class name and hide it
    const clearButton = event?.currentTarget?.nextSibling
    console.log('clearButton', clearButton)
    // clearButton.style.display = 'none'
  }

  useEffect(() => {
    console.log('expiryDate', expiryDate)

    console.log('image', image)

    console.log('model', formik.values.make_model)
    formik.values.make_model = ''
    formik.values.truck_number = ''
    formik.values.license_plate = ''
  }, [])
  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        {loading && (
          <div style={{ marginLeft: '-20%' }}>
            <Loader />
          </div>
        )}

        <Container fluid className="containerWidth">
          <Row>
            <Col>
              <DetailHeader
                title="Add Truck"
                className={
                  fetchUserType() === userTypes.ADMIN ? 'mb-0' : 'mb-2'
                }
                {...(fetchUserType() === userTypes.COMPANY ||
                fetchUserType() === userTypes.ADMIN
                  ? {
                      CtaComponent: <PrimaryButton title="Add" type="submit" />
                    }
                  : {})}
              />
            </Col>
            <Row className="mt-3">
              <Col md={4} className="mt-5">
                <CardWithHeader title={'Truck Details'}>
                  <div>
                    <FormControl
                      placeholder="Truck Make and Modal *"
                      controlId="ModalName"
                      onChange={e =>
                        formik.setFieldValue('make_model', e.target.value)
                      }
                      errorMessage={formik.errors.make_model}
                      value={formik.values.make_model}
                      showLabel={false}
                    />
                    <FormControl
                      type="text"
                      placeholder="Truck Number *"
                      controlId="TruckNumber"
                      onChange={e =>
                        formik.setFieldValue('truck_number', e.target.value)
                      }
                      errorMessage={formik.errors.truck_number}
                      value={formik.values.truck_number}
                      showLabel={false}
                    />
                    <FormControl
                      type="text"
                      placeholder="License Plate *"
                      controlId="LicensePlate"
                      onChange={e =>
                        formik.setFieldValue('license_plate', e.target.value)
                      }
                      errorMessage={formik.errors.license_plate}
                      value={formik.values.license_plate}
                      showLabel={false}
                    />
                  </div>
                </CardWithHeader>
              </Col>
              <Col md={8} style={{ marginBottom: '30px' }}>
                <span
                  style={{
                    color: '#123051',
                    fontWeight: '600',
                    fontSize: '20px'
                  }}
                >
                  Documents
                </span>

                <table className="mt-3">
                  <thead className="table-head" style={{ height: '50px' }}>
                    <tr>
                      {columns.map((column: any) => (
                        <th key={column.Header} className="px-2">
                          {column.Header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(data) &&
                      data?.map(
                        doc =>
                          doc?.category == 1 && (
                            <tr key={doc.name}>
                              <td>
                                <span style={{ fontWeight: 'bold' }}>
                                  {`${doc.is_required ? '*' : ''} ${doc.name}`}
                                </span>
                              </td>
                              <td className="d-flex">
                                <div
                                  style={{
                                    fontWeight: 'bold',
                                    marginTop: '20px'
                                  }}
                                >
                                  {`${doc.is_expiry_date_required ? '*' : ''}`}
                                </div>
                                {doc.is_expiry_date_required && (
                                  <div>
                                    <FormControl
                                      type="date"
                                      placeholder="Valid Till"
                                      // controlId="validateValidTill"
                                      className="no-clear-button"
                                      style={{ WebkitAppearance: 'none' }}
                                      // onFocus={removeClearButton}
                                      // className="mt-0 "
                                      onChange={e =>
                                        handleDateChange(
                                          e,
                                          doc.id,
                                          doc.category,
                                          doc.is_expiry_date_required
                                        )
                                      }
                                      value={
                                        expiryDate.find(el => el.id === doc.id)
                                          ?.expiry_date || ''
                                      }
                                      // onReset={() => handleDeleteDate(doc.id)}
                                      // isInvalid={!!formik.errors.valid_till}
                                      min={
                                        new Date().toISOString().split('T')[0]
                                      }
                                      // showLabel={false}
                                    />
                                  </div>
                                )}
                              </td>

                              <td className="px-5">
                                {image.map(item =>
                                  item.id == doc.id &&
                                  item.imagePrev &&
                                  item.imagePrev.indexOf('application/pdf') !==
                                    -1 ? (
                                    <img
                                      src={PDF}
                                      alt="img"
                                      style={{
                                        width: '35px',
                                        height: '35px',
                                        marginTop: '15px'
                                      }}
                                      onClick={() => {
                                        saveAs(item.imagePrev)
                                      }}
                                    />
                                  ) : (
                                    item.id == doc.id &&
                                    item.imagePrev && (
                                      <img
                                        src={item.imagePrev}
                                        alt="img"
                                        style={{
                                          width: '35px',
                                          height: '35px',
                                          marginTop: '15px'
                                        }}
                                        onClick={() => {
                                          saveAs(item.imagePrev)
                                        }}
                                      />
                                    )
                                  )
                                )}
                              </td>
                              <td>
                                <FormControl
                                  type="file"
                                  // multiple
                                  hidden
                                  id={doc.id}
                                  // label="upload"
                                  // ref={fileInputRef}
                                  onChange={e =>
                                    handleImageChange(
                                      e,
                                      doc.category,
                                      doc.is_required
                                    )
                                  }
                                />
                              </td>
                              <td>
                                {image.find(
                                  item => item.id == doc.id && item.img
                                ) ? (
                                  <div
                                    onClick={e => handleDocDelete(e, doc.id)}
                                  >
                                    <DeleteIcon />
                                  </div>
                                ) : (
                                  <label htmlFor={doc.id}>
                                    <DocUpload />{' '}
                                  </label>
                                )}
                              </td>
                            </tr>
                          )
                      )}
                  </tbody>
                </table>
              </Col>
            </Row>
          </Row>
        </Container>
      </Form>
    </FormikProvider>
  )
}

export default AddTruckPage
