import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useLazyTrackJobQuery } from '../../api/network/analyticsApiService'
import { useLazyFetchAllJobsQuery } from '../../api/network/jobsApiService'
import { IAnalyticsTrackJobResponseJob } from '../../api/types/Analytics'
import { UserResponse } from '../../api/types/Driver'
import CardWithHeader from '../../components/cardWithHeader/CardWithHeader'
import FormSelect from '../../components/formSelect/FormSelect'
import Table from '../../components/table/table'
import {
  changeDateFormat,
  DateFormat,
  minutesToHoursFormat
} from '../../utils/DateUtils'
import { formatAmount } from '../../utils/NumberUtils'
import { displayPickDropLocation } from '../../utils/RadarUtils'
import AnSecCompCompanySelector from './components/companySelector'

export interface IAnSecJobAcceptDurationProps {
  companiesList: UserResponse[]
  title?: string
  startDate: any
  endDate: any
}

const AnSecJobAcceptDuration = (props: IAnSecJobAcceptDurationProps) => {
  const { companiesList, title = 'Posted vs Accepted', startDate, endDate } = props
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>()
  // console.log(startDate)
  const [page, setPage] = useState(1)
  const [trackJobAPITrigger, { data: dataTrackJob, isLoading, isError }] =
    useLazyTrackJobQuery()
  const { next, previous, results, total_pages } = dataTrackJob || {}

  
  useEffect(() => {
    {(startDate===null||endDate===null)?
      trackJobAPITrigger({ companyId: selectedCompanyId, page }) :
      trackJobAPITrigger({ companyId: selectedCompanyId, page, from : startDate.toISOString().split("T")[0], to : endDate.toISOString().split("T")[0] }) }
  }, [page, trackJobAPITrigger, selectedCompanyId, startDate, endDate]) 


  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }

  const tableColumns = [
    {
      Header: 'Job ID',
      accessor: (cell: any) => <span className="text-break">{cell.id}</span>
    },
    {
      Header: 'Customer',
      accessor: (cell: any) => (
        <span className="text-break">{cell.customer_name}</span>
      )
    },
    {
      Header: 'Job Posted (Date/Time)',
      accessor: (cell: any) => (
        <span>
          {changeDateFormat(
            cell.job_posted,
            DateFormat.YYYYMMMDDHHMMSS,
            DateFormat.DDMMMYYYYHHMM
          )}
        </span>
      )
    },
    {
      Header: 'Job Accepted (Date/Time)',
      accessor: (cell: any) => (
        <span>
          {cell.job_accepted
            ? changeDateFormat(
                cell.job_accepted,
                DateFormat.YYYYMMMDDHHMMSS,
                DateFormat.DDMMMYYYYHHMM
              )
            : '-'}
        </span>
      )
    },
    {
      Header: 'Duration',
      accessor: (cell: any) => (
        <div className="text-break fc-blue fw-semibold text-right">
          {!!cell.duration || cell.duration === 0
            ? minutesToHoursFormat(cell.duration)
            : '--:--'}
          {' hrs'}
        </div>
      )
    }
  ]

  return (
    <CardWithHeader
      title={title}
      wrapperClassName={`ansec-job-accept-duration`}
    >
      <p className="mt-4 mb-0 m-0 fw-bold">Results are displayed are per the date range selected.</p>
      <Row className="py-3 align-items-center">
        <Col>
          <AnSecCompCompanySelector
            companiesList={companiesList}
            onChange={event => {
              console.log(event.target.value)
              setSelectedCompanyId(parseInt(event.target.value))
            }}
            selectedCompany={selectedCompanyId}
          />
        </Col>
        <Col>
          <p className="m-0 fw-bold text-right">
            Average Acceptance Duration (hrs):{' '}
            <span className="fc-blue">
              {!!dataTrackJob
                ? minutesToHoursFormat(
                    dataTrackJob?.results.average_acceptance_duration
                  )
                : '-'}
            </span>
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table
            data={results?.data || []}
            columns={tableColumns}
            page={page}
            setPage={setPage}
            onNext={onNext}
            onPrev={onPrev}
            pageCount={total_pages || 0}
            isLoading={isLoading}
            wrapperClassName={`table-wrap`}
          />
        </Col>
      </Row>
    </CardWithHeader>
  )
}
export default AnSecJobAcceptDuration
