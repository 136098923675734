import { Col, Container, Row, Form } from "react-bootstrap"
import Logo from "../../assets/images/svg/Logo"
import Backdrop from "../../assets/images/png/Backdrop.png"
import PrimaryButton from "../../components/primaryButton/PrimaryButton"
import FormControl from "../../components/formControl/FormControl"
import { Formik, FormikProps } from "formik"
import { resetPasswordFieldsSchema, setNewPasswordFieldsSchema } from "../../utils/Validations"
import Loader from "../../components/loader/Loader"
import { SetNewPasswordPayload } from "../../api/types/ForgotPasword"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { ROUTE_NAMES } from "../../utils/route/routerNames"
import { useSetNewPasswordMutation } from "../../api/network/userApiService"

const PageSetNewPassword = () => {
  const [setPasswordApiTrigger, { isLoading }] = useSetNewPasswordMutation()
  const navigate = useNavigate()

  const initialValue: SetNewPasswordPayload = {
    new_password: "",
    confirm_password: ""
  }

  const handleSubmit = (values: SetNewPasswordPayload) => {
    setPasswordApiTrigger(values)
      .unwrap()
      .then(response => {
        toast.success(response.message)
        navigate(ROUTE_NAMES.dashboardChildren.users)
      })
      .catch(e => {})
  }

  const renderSetupPasswordForm = (renderProps: FormikProps<SetNewPasswordPayload>) => {
    const { errors, values, handleChange, handleSubmit } = renderProps
    return (
      <Form noValidate onSubmit={handleSubmit} className="d-flex flex-column">
        <FormControl
          placeholder="Enter New Password"
          controlId="validatePassword"
          errorMessage={errors.new_password}
          type="password"
          value={values.new_password}
          onChange={handleChange("new_password")}
          isInvalid={!!errors.new_password}
          className="bg-white"
        />
        <FormControl
          placeholder="Re-enter New Password"
          controlId="validateConfirmPassword"
          errorMessage={errors.confirm_password}
          type="password"
          value={values.confirm_password}
          onChange={handleChange("confirm_password")}
          isInvalid={!!errors.confirm_password}
          className="bg-white"
        />
        <PrimaryButton
          title="Confirm"
          type="submit"
          className="w-75  align-self-center"
        />
      </Form>
    )
  }

  return (
    <Container fluid>
      {isLoading && <Loader />}
      <Row>
        <Col className="loginContainer p-0">
          <div>
            <Logo />
            <h2 className="header">Set Up Your New Password</h2>
            <Formik
              initialValues={initialValue}
              onSubmit={values => handleSubmit(values)}
              validationSchema={setNewPasswordFieldsSchema}
            >
              {renderSetupPasswordForm}
            </Formik>
          </div>
        </Col>
        <Col className="vh100 imageContainer p-0">
          <img src={Backdrop} className="image" alt="" />
        </Col>
      </Row>
    </Container>
  )
}

export default PageSetNewPassword
